<template>
    <div class="mb-24">
        <div class="flex items-center flex-col mt-40">
            <h1 class="text-4xl text-blue-gray-dark font-bold mb-6">Your Signal is Complete</h1>
            <p class="mb-8 w-1/3 text-center">You have successfully created your signal. Use this signal to collect valuable insight that'll take your product to the next level.</p>
            <btn color="blue-line" @click="backToSignalDashboard">Back to Signals Dashboard</btn>
        </div>
    </div>
</template>

<script>
    import {
        Btn,
    } from "@bombora/component-library";

    export default {
        name: "SignalComplete",
        components: {
            Btn,
        },
        props: ['id'],
        methods: {
            backToSignalDashboard() {
                this.$router.push({ name: 'Signals', params: { id: this.id } })
            },
        }
    }
</script>
