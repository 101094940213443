<template>
  <div :class="['mb-3 flex flex-row', isComplete ? 'cursor-pointer' : '']" @click="triggerClick">
    <div class="flex-1">
      <h5 :class="['text-xl font-bold', isActive ? 'text-blue-gray-dark' : 'text-gray-light']">{{title}}</h5>
      <p :class="[isActive ? 'text-gray' : 'text-gray-light']">{{subtitle}}</p>
    </div>
    <div v-if="isComplete" class="flex justify-content-center">
      <icon icon-type="checkmark" icon-color="gray" class="w-6 float-right" />
    </div>
  </div>
  <div>
    <ul v-if="isActive">
      <slot></slot>
    </ul>
  </div>
</template>

<script>
  import { Icon } from "@bombora/component-library";

  export default {
    name: "WizardSideNav",
    components: {
      Icon
    },
    emits: ['on-click'],
    props: {
      title: {
        type: String,
        default: ''
      },
      subtitle: {
        type: String,
        default: ''
      },
      // currently active section
      isActive: {
        type: Boolean,
        default: false
      },
      // complete flag denotes a section that has been passed and confirmed valid in the wizard flow
      isComplete: {
        type: Boolean,
        default: false
      },
    },
    methods: {
      triggerClick() {
        this.$emit('on-click')
      }
    }
  }
</script>

<style scoped>
</style>
