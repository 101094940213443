<template>
    <div>
        <div class="text-3xl text-blue-gray-dark">
            {{title}}
        </div>
        <div class="text-blue-gray-dark">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "PageHeader",
    components: { },
    props: {
        title: {
            type: String
        }
    }
};
</script>

<style lang="css" scoped>
</style>
