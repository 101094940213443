import DoublyLinkedList from '@/utils/linkedList'

export const wizardRouteNames = {
    Goals: 'Goals',
    Descriptions: 'Descriptions',
    Benefits: 'Benefits',
    Competitors: 'Competitors',
    UploadUrls: 'UploadUrls',
    UploadKeywords: 'UploadKeywords',

    Filtering: 'Filtering',
    AccountList: 'AccountList',
    AudienceSize: 'AudienceSize',
    Profitability: 'Profitability',
    Industries: 'Industries',
    Geographies: 'Geographies',

    Topics: 'Topics',
    Output: 'Output',
    Delivery: 'Delivery',

    // SignalOutput: 'SignalOutput',
    // SignalReview: 'SignalReview',
    // SignalComplete: 'SignalComplete',

}

export const wizardSectionMap = {
    Definition: [
        wizardRouteNames.Goals,
        wizardRouteNames.Descriptions,
        wizardRouteNames.Benefits,
        wizardRouteNames.Competitors,
        wizardRouteNames.UploadUrls,
        wizardRouteNames.UploadKeywords,
    ],
    Segmentation: [
        wizardRouteNames.Filtering,
        wizardRouteNames.AccountList,
        wizardRouteNames.AudienceSize,
        wizardRouteNames.Profitability,
        wizardRouteNames.Industries,
        wizardRouteNames.Geographies,
    ],
    Review: [
        wizardRouteNames.Topics,
        wizardRouteNames.Delivery,
        wizardRouteNames.Output,
    ]
}

let routingOrder = new DoublyLinkedList();
routingOrder.insertLast(wizardRouteNames.Goals)
routingOrder.insertLast(wizardRouteNames.Descriptions)
routingOrder.insertLast(wizardRouteNames.Benefits)
routingOrder.insertLast(wizardRouteNames.Competitors)
routingOrder.insertLast(wizardRouteNames.UploadUrls)
routingOrder.insertLast(wizardRouteNames.UploadKeywords)

routingOrder.insertLast(wizardRouteNames.Filtering)
routingOrder.insertLast(wizardRouteNames.AccountList)
routingOrder.insertLast(wizardRouteNames.AudienceSize)
routingOrder.insertLast(wizardRouteNames.Profitability)
routingOrder.insertLast(wizardRouteNames.Industries)
routingOrder.insertLast(wizardRouteNames.Geographies)

routingOrder.insertLast(wizardRouteNames.Topics)
routingOrder.insertLast(wizardRouteNames.Delivery)
routingOrder.insertLast(wizardRouteNames.Output)

export const wizardRoutingOrder = routingOrder
