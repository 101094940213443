<template>
  <div class="mb-8">
    <div v-if="pageLoading"
         class="absolute bg-white bg-opacity-60 z-10 h-full w-full flex items-center justify-center">
      <div class="flex items-center">
        <loading-spinner />
      </div>
    </div>
    <page-header title="Segment your target accounts by revenue" class="mb-8">
      <template v-slot>
        Select revenue ranges for the accounts you want to see in this Signal
      </template>
    </page-header>
    <div class="main flex flex-col">
      <multiple-card-selector :item-list="this.companyRevenues" :signal-list="this.selectedSignal.profitability" @set-signal="updateSignal"></multiple-card-selector>
    </div>
  </div>
</template>

<script>
  import {
    Alert,
    Breadcrumbs,
    Btn,
    Card,
    Grid,
    GridRow,
    GridCell,
    GridHeaderCell,
    GridPager,
    Icon,
    LoadingSpinner,
    TextField
  } from "@bombora/component-library"
  import MultipleCardSelector from "@/components/shared/MultipleCardSelector.vue";
  import PageHeader from "@/components/shared/PageHeader.vue";
  import { useSignalsStore } from '@/stores/signalStore'
  import { mapActions, mapState, mapWritableState } from "pinia";
  import { useReferenceDataStore } from "@/stores/referenceDataStore";


  export default {
    name: "Profitability",
    components: {
      Alert,
      Breadcrumbs,
      Btn,
      Card,
      Grid,
      GridRow,
      GridCell,
      GridHeaderCell,
      GridPager,
      Icon,
      LoadingSpinner,
      MultipleCardSelector,
      TextField,
      PageHeader
    },
    data() {
      return {
        pageLoading: true,
      }
    },
    computed: {
      ...mapWritableState(useSignalsStore, ['selectedSignal', 'wizardCanNavigate']),
      ...mapState(useReferenceDataStore, ['companyRevenues']),
    },
    async mounted() {
      if (this.companyRevenues.length == 0) {
        this.pageLoading = true;
        await this.fetchCompanyRevenues();
      }
        this.pageLoading = false;
      this.wizardCanNavigate = true
    },
    methods: {
      ...mapActions(useSignalsStore, ['upsertSignal']),
      ...mapActions(useReferenceDataStore, ['fetchCompanyRevenues']),
      async updateSignal(selectedItems) {
        this.selectedSignal.profitability = selectedItems
        await this.upsertSignal(this.selectedSignal)
      }
    },
  }



</script>

<style scoped>
</style>
