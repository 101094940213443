<template>
    <div class="grid grid-cols-12 gap-4 mb-10">
        <div class="col-span-3 text-gray-light">
            <slot name="leftContent"></slot>
        </div>
        <div class="col-span-9">
            <slot name="rightContent"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FieldGridRow",
        components: {
        },
    }
</script>

<style scoped>
</style>