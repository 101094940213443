import { createWebHistory, createRouter } from "vue-router"
import NotFound from "@/components/pages/meta/NotFound"
import LoginCallback from "@/components/pages/meta/LoginCallback"
import Unauthorized from "@/components/pages/meta/Unauthorized";
// Root pages
import Home from "@/components/pages/Home"
import Signals from "@/components/pages/Signals";
import SignalReview from "@/components/pages/SignalReview";
import SignalOutput from "@/components/pages/SignalOutput";
import SignalComplete from "@/components/pages/SignalComplete";
// Wizard Pages
import SignalInformation from "@/components/pages/signal-wizard/SignalInformation";
//// Definition Wizard pages
import Goals from "@/components/pages/signal-wizard/definition/Goals";
import Descriptions from "@/components/pages/signal-wizard/definition/Descriptions";
import Competitors from "@/components/pages/signal-wizard/definition/Competitors";
import Benefits from "@/components/pages/signal-wizard/definition/Benefits";
import UploadKeywords from "@/components/pages/signal-wizard/definition/UploadKeywords";
import UploadUrls from "@/components/pages/signal-wizard/definition/UploadUrls";
/// Segmentation Wizard pages
import Filtering from "@/components/pages/signal-wizard/gtm/Filtering.vue";
import AccountList from "@/components/pages/signal-wizard/gtm/AccountList";
import AudienceSize from "@/components/pages/signal-wizard/gtm/AudienceSize";
import Profitability from "@/components/pages/signal-wizard/gtm/Profitability";
import Industries from "@/components/pages/signal-wizard/gtm/Industries";
import Geographies from "@/components/pages/signal-wizard/gtm/Geographies";
//// Review Wizard pages
import Topics from "@/components/pages/signal-wizard/review/Topics"
import Output from "@/components/pages/signal-wizard/review/Output"
import Delivery from "@/components/pages/signal-wizard/review/Delivery"

import { routeGuard } from "@/services/auth";
import { wizardRouteNames } from './routerConstants';


const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        beforeEnter: [routeGuard]
    },
    {
        props: true,
        path: "/company/:id/signals",
        name: "Signals",
        component: Signals,
        beforeEnter: [routeGuard]
    },
    {
        props: true,
        path: "/company/:id/signal/:signalId",
        name: "SignalInformation",
        component: SignalInformation,
        beforeEnter: [routeGuard],
        children: [
            // Add redirect route for hitting the parent page directly
            {
                props: true,
                path: "",
                redirect: { name: wizardRouteNames.Goals, props: true },
            },
            {
                props: true,
                path: "goals",
                name: wizardRouteNames.Goals,
                component: Goals,
            },
            {
                props: true,
                path: "benefits",
                name: wizardRouteNames.Benefits,
                component: Benefits,
            },
            {
                props: true,
                path: "competitors",
                name: wizardRouteNames.Competitors,
                component: Competitors,
            },
            {
                props: true,
                path: "descriptions",
                name: wizardRouteNames.Descriptions,
                component: Descriptions,
            },
            {
                props: true,
                path: "upload-urls",
                name: wizardRouteNames.UploadUrls,
                component: UploadUrls,
            },
            {
                props: true,
                path: "upload-keywords",
                name: wizardRouteNames.UploadKeywords,
                component: UploadKeywords,
            },
            {
                props: true,
                path: "filtering",
                name: wizardRouteNames.Filtering,
                component: Filtering,
            },
            {
                props: true,
                path: "account-list",
                name: wizardRouteNames.AccountList,
                component: AccountList,
            },
            {
                props: true,
                path: "audience-size",
                name: wizardRouteNames.AudienceSize,
                component: AudienceSize,
            },
            {
                props: true,
                path: "profitability",
                name: wizardRouteNames.Profitability,
                component: Profitability,
            },
            {
                props: true,
                path: "industries",
                name: wizardRouteNames.Industries,
                component: Industries,
            },
            {
                props: true,
                path: "geographies",
                name: wizardRouteNames.Geographies,
                component: Geographies,
            },
            {
                props: true,
                path: "topics",
                name: wizardRouteNames.Topics,
                component: Topics
            },
            {
                props: true,
                path: "output",
                name: wizardRouteNames.Output,
                component: Output
            },
            {
                props: true,
                path: "delivery",
                name: wizardRouteNames.Delivery,
                component: Delivery
            }
        ]
    },
    {
        props: true,
        path: "/company/:id/signal/:signalId/review",
        name: "SignalReview",
        component: SignalReview,
        beforeEnter: [routeGuard]
    },
    {
        props: true,
        path: "/company/:id/signal/:signalId/signaloutput",
        name: "SignalOutput",
        component: SignalOutput,
        beforeEnter: [routeGuard]
    },
    {
        props: true,
        path: "/company/:id/signal/complete",
        name: "SignalComplete",
        component: SignalComplete,
        beforeEnter: [routeGuard]
    },
    {
        path: "/LoginCallback",
        name: "LoginCallback",
        component: LoginCallback,
    },
    {
        path: "/Unauthorized",
        name: "Unauthorized",
        component: Unauthorized
    },
    {
        path: "/:pathMatch(.*)",
        component: NotFound
    },
]

const router = createRouter({
    linkExactActiveClass: 'active',
    // History mode, vs Hash mode
    history: createWebHistory(),
    routes: routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        }
        if (to.hash) {
            return {}
        }
        // The vue router docs are apparently wrong, and say this should be { x: 0, y: 0 }, which doesn't work.
        // https://stackoverflow.com/questions/54535838/scroll-behaviour-vuejs-not-working-properly
        // If the route is not part of the wizard, do the normal scroll to top behavior
        if (!Object.keys(wizardRouteNames).includes(to.name)) {
            return { left: 0, top: 0 }
        } else {
            return {}
        }
    },
})

export default router


