<template>
  <div class="flex flex-col h-screen bg-blue-darkest">
    <div class="flex flex-auto"/>
    <div class="flex flex-auto flex-row justify-center">
      <h1 class="text-9xl font-bold text-orange">Coming So</h1>
      <div class="flex pt-10 pr-2 pl-2">
        <div class="graphic">
          <div class="wave-01"></div>
          <div class="wave-02"></div>
          <div class="wave-03"></div>
          <div class="sun">
          </div>
        </div>
      </div>
      <h1 class="text-9xl font-bold text-orange">n</h1>
    </div>
    
  </div>
</template>

<script>
export default {
  name: "ComingSoon"
}
</script>

<style>
  .graphic {
    width: 75px;
    height: 75px;
    position: relative;
    border-radius: 150px;
    box-shadow: 0px 0px 20px #000;
    background: linear-gradient(#f9855d,#fdd288,white);
    overflow: hidden;
  }
  
  .sun {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 10px;
    right: 20px;
    background-color: #f9cb5d;
    border-radius: 50%;
  }
  
  .wave-01 {
    position: absolute;
    width: 300%;
    height: 300%;
    left: -100%;
    top: 50%;
    background-color: rgba(0,190,255,0.4);
    border-radius: 45%;
    animation: rotate 10s linear infinite;
  }
  
  
  .wave-02 {
    position: absolute;
    width: 300%;
    height: 300%;
    left: -100%;
    top: 57%;
    background-color: rgba(0,70,110,0.4);
    border-radius: 43%;
    animation: rotate 7s linear infinite;
  }
  
  .wave-03 {
    position: absolute;
    width: 300%;
    height: 300%;
    left: -100%;
    top: 60%;
    background-color: rgba(0,90,110,0.4);
    border-radius: 40%;
    animation: rotate 5s linear infinite;
  }
  
  @keyframes rotate {
    100% { transform: rotate(360deg);}
  }
</style>