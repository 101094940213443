<template>
  <div class="mb-8">
    <page-header title="Who are your Competitors?" class="mb-8">
      <template v-slot>
        Provide a list of your competitors.
      </template>
    </page-header>
    <div>
      <textarea @blur="onBlur"
                @focusout="onBlur"
                @keyup="updateNavState"
                name="signal.competitors"
                class="border-2 rounded-md border-gray-light h-60 w-full p-2"
                placeholder="What are your competitors' names?"
                v-model="selectedSignal.competitors" />
    </div>
    <FieldErrorMessage :field="v$.selectedSignal.competitors"></FieldErrorMessage>
  </div>

</template>

<script>
  import {
    Alert,
    Breadcrumbs,
    Btn,
    Card,
    Grid,
    GridRow,
    GridCell,
    GridHeaderCell,
    GridPager,
    Icon,
    LoadingSpinner,
  } from "@bombora/component-library"
  import FieldErrorMessage from "@/components/shared/FieldErrorMessage.vue"
  import PageHeader from "@/components/shared/PageHeader.vue";

  import { useVuelidate } from '@vuelidate/core'
  import { maxLength, helpers } from '@vuelidate/validators'
  import { mapWritableState, mapActions } from 'pinia'
  import { useSignalsStore } from '@/stores/signalStore'

  export default {
    name: "DefinitionCompetitors",
    components: {
      Alert,
      Breadcrumbs,
      Btn,
      Card,
      FieldErrorMessage,
      Grid,
      GridRow,
      GridCell,
      GridHeaderCell,
      GridPager,
      Icon,
      LoadingSpinner,
      PageHeader
    },
    setup: () => ({ v$: useVuelidate() }),
    computed: {
      ...mapWritableState(useSignalsStore, ['selectedSignal', 'wizardCanNavigate']),
    },
    validations() {
      return {
        selectedSignal: {
          competitors: {
            maxLength: helpers.withMessage('Your competitors list must be under 1000 characters.', maxLength(1000)),
          },
        }
      }
    },
    async mounted() {
      this.wizardCanNavigate = true;
    },
    methods: {
      ...mapActions(useSignalsStore, ['upsertSignal']),
      async onBlur() {
        await this.updateNavState();
        await this.saveSignal();
      },
      async saveSignal() {
        let status = await this.upsertSignal(this.selectedSignal);
        document.activeElement.blur()
      },
      async updateNavState() {
        const isFormCorrect = await this.v$.$validate()
        this.wizardCanNavigate = isFormCorrect;
      }
    },
  }
</script>

<style lang="css" scoped>
</style>
