<template>
    <div class="mb-8">
        <div v-if="pageLoading"
             class="absolute bg-white bg-opacity-60 z-10 h-full w-full flex items-center justify-center">
            <div class="flex items-center">
                <loading-spinner />
            </div>
        </div>
        <page-header title="Apply segmentation filters for net new accounts" class="mb-8">
            <template v-slot>
            </template>
        </page-header>
        <titled-section class="pb-10">
            <div class="main flex flex-col">
              <titled-section>
                <template v-slot:title>
                  Company Size (Employees)
                </template>
                <template v-slot:subtitle class="pb-20">
                  Select company sizes (by employee count) for the accounts you want to see in this Signal
                </template>
              </titled-section>
                <multiple-card-selector :item-list="this.companySizes" :signal-list="this.selectedSignal.audienceSizeList" @set-signal="updateSignal"></multiple-card-selector>
            </div>
        </titled-section>
    </div>
</template>

<script>
import {
    Alert,
    Breadcrumbs,
    Btn,
    Card,
    Grid,
    GridRow,
    GridCell,
    GridHeaderCell,
    GridPager,
    Icon,
    LoadingSpinner,
    TextField
} from "@bombora/component-library"
import TitledSection from "@/components/shared/TitledSection.vue";
import MultipleCardSelector from "@/components/shared/MultipleCardSelector.vue";
import PageHeader from "@/components/shared/PageHeader.vue";
import { useSignalsStore } from '@/stores/signalStore'
import {mapActions, mapState, mapWritableState} from "pinia";
import { useReferenceDataStore} from "@/stores/referenceDataStore";

export default {
    name: "AudienceSize",
    components: {
        Alert,
        Breadcrumbs,
        Btn,
        Card,
        Grid,
        GridRow,
        GridCell,
        GridHeaderCell,
        GridPager,
        Icon,
        LoadingSpinner,
        MultipleCardSelector,
        TextField,
        TitledSection,
        PageHeader
    },
    data() {
        return {
            pageLoading : true,
        }
    },
    computed: {
        ...mapWritableState(useSignalsStore, ['selectedSignal', 'wizardCanNavigate']),
        ...mapState(useReferenceDataStore, ['companySizes']),
    },
    async mounted() {
        this.pageLoading = true;
        await this.fetchCompanySizes();

        this.pageLoading = false;
        this.wizardCanNavigate = true;
    },
    methods: {
      ...mapActions(useSignalsStore, ['upsertSignal']),
        ...mapActions(useReferenceDataStore, ['fetchCompanySizes']),

        async updateSignal(selectedItems) {
          this.selectedSignal.audienceSizeList = selectedItems
          let status = await this.upsertSignal(this.selectedSignal);        },
    },
}

</script>

<style scoped>

</style>
