<template>
  <div class="absolute z-50 left-1/4 right-1/4">
    <div class="flex flex-col text-center items-center">
      <div class="text-gray-lighter text-4xl font-semibold pb-4 pt-8">Looks like you've swum into deep waters!</div>
      <div class="text-gray-lighter text-lg pb-10">This part of the ocean is for authorized users only. Please
        contact<br>your administrator to gain access to this Bombora feature.
      </div>
      <Btn class="flex inline-block w-46 rounded items-center font-semibold" color="orange" @click="navigateAway">
        <icon class="w-6 mr-1 text-orange" icon-type="arrowleft" icon-color="orange"></icon>
        Take me back
      </Btn>
    </div>
  </div>
  <img src="@/assets/waves-bg.png" class="full-width relative w-screen left-1/2 right-1/2 -top-2 z-0" alt="waves"/>
</template>

<script>
import {Btn, Icon} from "@bombora/component-library";

export default {
  name: "Unauthorized",
  components: {Btn, Icon},

  methods: {
      async navigateAway() {
          window.location = 'http://www.bombora.com';
      }
  }
}
</script>

<style>
.full-width {
  margin-top: -1.2vw;
  margin-left: -50vw;
  max-width: 100vw;
  max-height: 48vw;
}
</style>
