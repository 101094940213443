<template>
    <div class="mb-8">
        <div v-if="pageLoading"
             class="absolute bg-white bg-opacity-60 z-10 h-full w-full flex items-center justify-center">
            <div class="flex items-center">
                <loading-spinner />
            </div>
        </div>
        <page-header title="Select your target industries" class="mb-4">
            <template v-slot>
            </template>
        </page-header>
        <div class="w-full">
            <div v-if="checkedIndustries.length > 0" class="border-r border-l border-t border-gray-light p-1 rounded-tl rounded-tr">
              <div v-for="industry in checkedIndustries" :key="industry" class="inline-block">
                    <div class="items-center flex">
                        <div class="inline-block border bg-blue text-white px-2 py-1 mt-1 mx-2 text-sm rounded">
                            <div style="float:left">{{industry}}</div>
                            <div data-id="remove-industry-action" @click="removeIndustries(industry)" class="pl-4 cursor-pointer" style="float:left">x</div>
                        </div>
                    </div>
              </div>
            </div>
            <div v-else class="pb-12">

            </div>
            <div :class="checkedIndustries.length > 0 ? 'flex min-w-input border border-gray-light h-10' : 'flex min-w-input border rounded-tl rounded-tr border-gray-light h-10'">
                  <span class="inline-flex items-center pl-3 pr-3 text-sm text-gray border-r border-blue-gray"><icon icon-type="spyglass" class="w-3 h-3"></icon></span>
                  <input name="industry-search" class="flex-1 w-full pl-3" type="text" v-model="searchValue"  placeholder="Search by Industry Name">
            </div>

            <grid class="pb-2">
                <template #rows>
                    <grid-row
                    data-id="select-industry-action"
                    v-for="industry in paginatedLists"
                    :class = "checkedIndustries.includes(industry) ? 'bg-blue-light text-blue' : 'bg-white text-blue-gray-dark'"
                    @click="toggleIndustry(industry)">
                        <grid-cell>
                            <input @change="setIndustries($event)" type="checkbox" v-model="checkedIndustries" :value="industry" class="mr-5">
                            {{industry}}
                        </grid-cell>
                    </grid-row>
                </template>
                <template #footer>
                    <grid-pager data-id="industry-pager" v-if="numPages > 1" :total-pages="numPages" :current-page="currentPage" @paged="pagerClicked"></grid-pager>
                </template>
            </grid>
        </div>

    </div>
</template>

<script>
import {
    Alert,
    Breadcrumbs,
    Btn,
    Card,
    Grid,
    GridRow,
    GridCell,
    GridHeaderCell,
    GridPager,
    Icon,
    LoadingSpinner,
    TextField
} from "@bombora/component-library"
import FileDropZone from '@/components/shared/FileDropZone.vue';
import TitledSection from "@/components/shared/TitledSection.vue";
import PageHeader from "@/components/shared/PageHeader.vue";
import { useSignalsStore } from '@/stores/signalStore'
import {mapActions, mapWritableState, mapState} from "pinia";
import { useReferenceDataStore} from "@/stores/referenceDataStore";

export default {
    name: "Industries",
    components: {
        Alert,
        Breadcrumbs,
        Btn,
        Card,
        FileDropZone,
        Grid,
        GridRow,
        GridCell,
        GridHeaderCell,
        GridPager,
        Icon,
        LoadingSpinner,
        TextField,
        TitledSection,
        PageHeader
    },
    data() {
        return {
            checkedIndustries:[],
            searchValue: null,
            pageSize: 10,
            currentPage: 1,
            pageLoading : true,
        }
    },
    computed: {
        ...mapWritableState(useSignalsStore, ['selectedSignal', 'wizardCanNavigate']),
        ...mapState(useReferenceDataStore, ['industries']),

        searchList() {
            if (!this.searchValue) return [];
            var filtered = this.industries.filter(x => x.toLowerCase().includes(this.searchValue.toLowerCase()))
            return filtered
        },
        numPages() {
            if (!this.searchValue) return Math.ceil(this.industries.length / this.pageSize);
            var filtered = this.industries.filter(x => x.toLowerCase().includes(this.searchValue.toLowerCase()))
            return Math.ceil(filtered.length / this.pageSize)
        },
        paginatedLists() {
            if (!this.searchValue) return this.industries.slice((this.currentPage - 1) * this.pageSize, ((this.currentPage - 1) * this.pageSize) + this.pageSize);
            var filtered = this.industries.filter(x => x.toLowerCase().includes(this.searchValue.toLowerCase()))
            return filtered.slice((this.currentPage - 1) * this.pageSize, ((this.currentPage - 1) * this.pageSize) + this.pageSize)
        }
    },


    async mounted() {
        this.pageLoading = true;
        await this.fetchIndustries();

        if(this.selectedSignal.industry) {
          this.checkedIndustries = this.selectedSignal.industry.slice(0)
        }
        this.pageLoading = false
        this.wizardCanNavigate = true;

    },
    methods: {
      ...mapActions(useSignalsStore, ['upsertSignal']),
        ...mapActions(useReferenceDataStore, ['fetchIndustries']),

        async setIndustries(event) {
            //if we select a parent industry, select the sub industries as well
            let industryIsParent = !event.target.value.includes(" > ");
            let industryIsChecked = event.target.checked;
            if (industryIsParent && industryIsChecked) {
                let allsubindustries = this.industries.filter(x => x.startsWith(event.target.value))
                let newsubindustries = allsubindustries.filter(x => !this.checkedIndustries.includes(x))
                newsubindustries.forEach(i => this.checkedIndustries.push(i))
            }
            this.selectedSignal.industry = this.checkedIndustries;
            let status = await this.upsertSignal(this.selectedSignal);
            },

        async removeIndustries(industry) {
            const index = this.checkedIndustries.indexOf(industry);
            this.checkedIndustries.splice(index,1);
            this.selectedSignal.industry = this.checkedIndustries;
            let status = await this.upsertSignal(this.selectedSignal);
            },
        closeSearch() {
            this.searchValue = null;
        },
        pagerClicked(pageNum) {
            this.currentPage = pageNum;
        },
        async toggleIndustry(item) {
          if(this.selectedSignal.industry == null) {
              this.selectedSignal.industry = []
          }
            let industryIsParent = !item.includes(" > ");
            let industryIsChecked = !this.selectedSignal.industry.includes(item);
            // if it is the industry parent and we are checking the industry to include it
            if (industryIsParent && industryIsChecked) {
                let allsubindustries = this.industries.filter(x => x.startsWith(item))
                let newsubindustries = allsubindustries.filter(x => !this.checkedIndustries.includes(x))
                newsubindustries.forEach(i => this.checkedIndustries.push(i))
            }
            // if it is not the parent industry and we are  checking to include it
            else if (!industryIsParent && industryIsChecked) {
                this.checkedIndustries.push(item)
            }
            // if we are unchecking the industry
            else {
                let index = this.checkedIndustries.indexOf(item)
                this.checkedIndustries.splice(index, 1)
            }
            this.selectedSignal.industry = this.checkedIndustries;
            await this.upsertSignal(this.selectedSignal);
            //TODO UI Error
        },

    },
}

</script>

<style scoped>

</style>
