<template>
  <header class="max-w-screen-xl container mx-auto px-12 flex justify-between items-center">
    <router-link class="w-36" to="/">
      <img class="w-28 pb-1.5 pt-1.5" src="@/assets/bombora-logo.png" alt="Bombora" />
    </router-link>

    <div class="flex justify-content-right">
      <div class="px-5">
        <p class="text-blue-gray-dark">{{headerCrumb}}</p>
      </div>
      <div v-if="!$auth.loading.value" class="text-right">
        <button v-if="$auth.isAuthenticated.value" @click="logout" class="text-blue-dark focus:outline-none">
          Logout
        </button>
      </div>
    </div>
  </header>
  <hr class="border-gray-lightest mb-10">
</template>

<script>
  import { mapState } from 'pinia'
  import { useRootStore } from '@/stores/rootStore'

  export default {
    name: "AppHeader",
    components: {},
    computed: {
      ...mapState(useRootStore, ['headerCrumb']),
    },
    methods: {
      login() {
        this.$auth.loginWithRedirect()
      },
      logout() {
        this.$auth.logout({
          returnTo: window.location.origin,
        })
      },
    }
  }
</script>

<style scoped></style>
