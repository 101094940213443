export const NavStateEnum = {
  Definition: "Definition",
  Segmentation: "Segmentation",
  Review: "Review",
  Complete: "Complete"
}

export const ErrorMessages = {
  unauthorizedAction: 'You are not authorized to perform this action.',
  signalCurrentlyProcessing: 'This signal is still processing topics and cannot be deleted now.',
  unknownSignalIssue: 'Unable to delete signal.',
  unableToCreateEmptySignal: 'Could not create empty signal for navigation.',
  signalLoadError: 'Signal was unable to load.',
  signalCreateError: 'Unable to create signal.',
  navigationError: 'Please check that you have completed the step in its entirety.',
  keywordNavigationError: 'Signals must have at least one of the following: PDF, URL, or keywords.',
  signalMissingName: 'You must provide a name for this signal.',
  signalDuplicateName: 'There is an existing signal with that name. Please choose a unique name.',
  noTopicsReturned: 'The data you provided did not result in any topics. Please adjust your inputs.',
  signalCompleteError: 'Your signal could not be completed. Please try again later.'
};
