<template>
    <div class="mb-8">
        <div v-if="uploading" class="absolute bg-white bg-opacity-60 z-10 h-full w-full flex items-center justify-center">
            <div class="flex items-center">
                <loading-spinner />
            </div>
        </div>
        <page-header class="mb-8" title="Choose your own named account list or use Bombora's database">
            <template v-slot>
                Use a list of target accounts by uploading the file or find accounts by using Bombora's database of accounts.
            </template>
        </page-header>
        <div class="main flex flex-column pb-10">
            <div data-id="segmentation-filtering-yes-string"
                 class="flex px-8 py-5 rounded-sm border-gray-light border mb-3 cursor-pointer hover:bg-blue hover:text-white flex-child:1 w-2/4 mr-2.5	"
                 :class="selectedSignal.useBomboraFilters === 'no' ? 'bg-blue text-white' : 'bg-blue-light text-blue-gray-dark'"
                 @click="setSegmentFilter('no')">
                <div class="left">
                    <div class="font-semibold text-med">I have my own target account list</div>
                </div>
            </div>
            <div data-id="segmentation-filtering-no-string"
                 class="flex px-8 py-5 rounded-sm border-gray-light border mb-3 cursor-pointer hover:bg-blue hover:text-white flex-child:1 w-2/4 ml-2.5	"
                 :class="selectedSignal.useBomboraFilters === 'yes' ? 'bg-blue text-white' : 'bg-blue-light text-blue-gray-dark'"
                 @click="setSegmentFilter('yes')">
                <div class="right">
                    <div class="font-semibold text-med">I want to use Bombora's database</div>
                </div>
            </div>
        </div>
        <transition name="fade" mode="out-in">
            <div v-if="selectedSignal.useBomboraFilters === 'no'" key="no">
                <titled-section class="pb-4">
                    <template v-slot:title>
                        Please select an existing target account list or upload a new one
                    </template>
                    <template v-slot:subtitle>
                    </template>
                </titled-section>
                <p>Uploaded files must follow the following template: <a class="text-blue-gray-dark font-medium hover:underline" href="https://brand-assets.bombora.com/signal-creator/Account_List_Upload_Template.csv" target="_blank">Download Account List Template</a>.</p>
                <titled-section class="pb-2">
                    <file-drop-zone data-id="csv-upload-action" @updated="onFileUploaded" :accept="'.csv'">
                        <div class="w-full h-full grid place-content-center cursor-pointer">
                            <div class="w-8 h-8 place-self-center">
                                <img height="27" width="24" src="@/assets/arrow-up.svg" alt="Upload" />
                            </div>
                            <p class="text-dark-gray">Click to upload or drag and drop your CSV or XLSX files</p>
                        </div>
                    </file-drop-zone>
                    <alert-box v-model:message="uploadFailureAlertMessage"></alert-box>
                    <alert-box v-model:message="uploadSuccessAlertMessage" :type="'success'"></alert-box>
                </titled-section>
                <titled-section class="pb-10">
                    <template v-slot:title class="pb-2">
                        Select a Target Account List
                    </template>
                    <div v-if="accountListLoading">
                        <div class="flex items-center">
                            <loading-spinner />
                        </div>
                    </div>
                    <grid class="pb-2">
                        <template #rows>
                            <grid-row v-for="list in paginatedLists"
                                      :key="list"
                                      :class="selectedSignal.accountList != null && selectedSignal.accountList.includes(list.id) ? 'bg-blue-light text-blue' : 'bg-white text-blue-gray-dark'">

                                <grid-cell @click="toggleAccountList(list.id)">
                                    <input data-id="select-account-list-action" type="checkbox" :value="list.id" v-model="selectedSignal.accountList" class="mr-5" />
                                    {{ list.name }}
                                </grid-cell>
                            </grid-row>
                        </template>
                        <template #footer>
                            <grid-pager data-id="account-list-pager" v-if="numPages > 1" :total-pages="numPages" :current-page="currentPage" @paged="pagerClicked"></grid-pager>
                        </template>
                    </grid>
                </titled-section>
            </div>
            <div v-else-if="this.selectedSignal.useBomboraFilters === 'yes'" key="yes">
                <titled-section>
                    <template v-slot:title>
                        Company Size (Employees)
                    </template>
                    <template v-slot:subtitle class="pb-20">
                        Select company sizes (by employee count) for the accounts you want to see in this Signal
                    </template>
                </titled-section>
                <multiple-card-selector :item-list="this.companySizes" :signal-list="this.selectedSignal.audienceSizeList" @set-signal="updateSignal"></multiple-card-selector>
            </div>
        </transition>
    </div>
</template>

<script>
    import {
        Alert,
        Breadcrumbs,
        Btn,
        Card,
        Icon,
        LoadingSpinner,
        TextField,
        Grid,
        GridRow,
        GridCell,
        GridPager
    } from "@bombora/component-library"
    import TitledSection from "@/components/shared/TitledSection.vue";
    import { useVuelidate } from "@vuelidate/core";
    import FileDropZone from "@/components/shared/FileDropZone.vue";
    import AlertBox from "@/components/shared/AlertBox.vue";
    import MultipleCardSelector from "@/components/shared/MultipleCardSelector.vue";
    import PageHeader from "@/components/shared/PageHeader.vue";
    import { mapState, mapWritableState, mapActions } from 'pinia'
    import { useSignalsStore } from '@/stores/signalStore'
    import { useRootStore } from '@/stores/rootStore'
    import { useReferenceDataStore } from "@/stores/referenceDataStore";

    export default {
        name: "AccountList",
        components: {
            AlertBox,
            FileDropZone,
            Alert,
            Breadcrumbs,
            Btn,
            Card,
            Icon,
            LoadingSpinner,
            TextField,
            TitledSection,
            Grid,
            GridRow,
            GridCell,
            GridPager,
            MultipleCardSelector,
            PageHeader
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                uploading: false,
                files: [],
                pageSize: 10,
                currentPage: 1,
                uploadSuccessAlertMessage: null,
                uploadFailureAlertMessage: null,
                accountListLoading: false
            }
        },
        computed: {
            ...mapWritableState(useSignalsStore, ['selectedSignal', 'wizardCanNavigate']),
            ...mapState(useRootStore, ['company', 'entityId', 'accountLists']),
            ...mapState(useReferenceDataStore, ['companySizes']),
            numPages() {
                return Math.ceil(this.accountLists.length / this.pageSize);
            },
            paginatedLists() {
                return this.accountLists.slice((this.currentPage - 1) * this.pageSize, ((this.currentPage - 1) * this.pageSize) + this.pageSize)
            }
        },
        async mounted() {
            this.wizardCanNavigate = false

            if (this.selectedSignal.accountList === null) {
                this.selectedSignal.accountList = [];
            }

            if (this.selectedSignal.audienceSizeList === null) {
                this.selectedSignal.audienceSizeList = [];
            }

            await this.fetchCompanySizes();

            await this.getAccountLists();
            if (this.selectedSignal.useBomboraFilters) {
                if (this.selectedSignal.useBomboraFilters === 'yes' || this.selectedSignal.accountList.length !== 0) {
                    this.wizardCanNavigate = true
                }
            }
        },
        methods: {
            ...mapActions(useSignalsStore, ['upsertSignal']),
            ...mapActions(useRootStore, ['fetchAccountLists', 'uploadAccountList']),
            ...mapActions(useReferenceDataStore, ['fetchCompanySizes']),
            setSegmentFilter(useBomboraFilters) {
                this.selectedSignal.useBomboraFilters = useBomboraFilters
                if (this.selectedSignal.useBomboraFilters === 'yes') {
                    // set account list to empty string
                    this.selectedSignal.accountList = [];
                    this.wizardCanNavigate = true
                }
                else if (this.selectedSignal.useBomboraFilters === 'no') {
                    this.wizardCanNavigate = this.selectedSignal.accountList !== null && this.selectedSignal.accountList.length !== 0;
                }
                else {
                    this.wizardCanNavigate = false
                }
            },
            async toggleAccountList(listId) {
                // Only allow one selection at a time
                this.selectedSignal.accountList = [listId];
                let status = await this.upsertSignal(this.selectedSignal);

                this.wizardCanNavigate = this.selectedSignal.accountList.length !== 0
            },
            async updateNavState() {
                const isFormCorrect = await this.v$.$validate()
                if (!isFormCorrect) {
                    this.wizardCanNavigate = false
                } else {
                    this.wizardCanNavigate = true
                }
            },
            async onFileUploaded(files) {
                this.uploading = true;

                const formData = new FormData();
                files.forEach(csv => {
                    formData.append("files", csv, csv.name)
                })

                let userEmail = this.$auth.user.value.email;
                formData.append("userEmail", userEmail)

                // TODO: Use the company entity id instead of hardcoding the id
                let resp = await this.uploadAccountList(this.entityId, formData);
                this.uploading = false;
                if (!resp.success) {
                    this.uploadFailureAlertMessage = resp.message;
                } else {
                    this.uploadSuccessAlertMessage = 'Upload successful';
                }
            },
            async getAccountLists() {
                this.accountListLoading = true;
                let resp = await this.fetchAccountLists(this.entityId);
                if (!resp.success || resp.success) {
                    this.accountListLoading = false;
                }
            },

            pagerClicked(pageNum) {
                this.currentPage = pageNum;
            },
            updateSignal(selectedItems) {
                this.selectedSignal.audienceSizeList = selectedItems
                this.$emit('signal-updated');

            },
        },
    }

</script>

<style scoped>
</style>
