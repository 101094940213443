<template>
    <div class="mb-8">
        <div v-if="!loadingTopics">
            <div v-if="pageLoading"
                 class="absolute bg-white bg-opacity-60 z-10 h-full w-full flex items-center justify-center">
                <div class="flex items-center">
                    <loading-spinner />
                </div>
            </div>
            <div v-if="this.topics.length > 0">
                <page-header title="Review the Intent topics for this Signal" class="mb-8">
                    <template v-slot>
                        Based on the URLs, PDFs, and keywords that you uploaded, Bombora suggests the following Intent topics for this particular Signal. The relevance indicates the rank of the topic in respect to the proprietary calculations for relevance made by Bombora's data science algorithms.
                    </template>
                </page-header>

                <grid class="pb-12">
                    <template #gridHeaderCells>
                        <grid-header-cell column-key="name" :sortable="false">
                            Name
                        </grid-header-cell>
                        <grid-header-cell column-key="rank" :sortable="false">
                            Rank
                        </grid-header-cell>
                    </template>
                    <template #rows>
                        <template v-for="(topic, index) in paginatedTopics">
                            <grid-row :class="['cursor-pointer', this.selectedTopics.find((t)=>t.id === topic.id) ? 'selected-topic' : '']"
                                      @click="toggleSelectedTopic(topic)">
                                <grid-cell>
                                    <input type="checkbox"
                                           :value="topic"
                                           @change="saveTopics"
                                           v-model="selectedTopics"
                                           name="signal-topic-select" />
                                    <span class="pl-6 text-blue">{{ topic.name }}</span>
                                </grid-cell>
                                <grid-cell>
                                    <span class="text-blue-darkest">
                                        {{++index + this.topicPageSize * (this.currentTopicPage - 1)}}
                                    </span>
                                </grid-cell>
                            </grid-row>
                        </template>
                    </template>
                    <template #footer>
                        <grid-pager v-if="numTopicPages > 1"
                                    :page-size="topicPageSize"
                                    :total-pages="numTopicPages"
                                    :current-page="currentTopicPage"
                                    @paged="topicPagerClicked">
                        </grid-pager>
                    </template>
                </grid>
                <titled-section class="pb-5" v-if="this.suggestedTopics.length > 0">
                    <template v-slot:title>
                        <div>Your suggested topics</div>
                    </template>
                    <template v-slot:subtitle>
                    </template>
                    <grid class="pb-12">
                        <template #gridHeaderCells>
                            <grid-header-cell column-key="name" :sortable="false">
                                Name
                            </grid-header-cell>
                        </template>
                        <template #rows>
                            <template v-for="(topic) in paginatedSuggestedTopics">
                                <grid-row :class="['cursor-pointer', this.selectedTopics.find((t)=>t.id === topic.id) ? 'selected-topic' : '']"
                                          @click="toggleSelectedTopic(topic)">
                                    <grid-cell>
                                        <input type="checkbox"
                                               :value="topic"
                                               @change="saveTopics"
                                               v-model="selectedTopics"
                                               name="signal-suggested-topic-select" />
                                        <span class="pl-6 text-blue">{{ topic.name }}</span>
                                    </grid-cell>
                                </grid-row>
                            </template>
                        </template>
                        <template #footer>
                            <grid-pager v-if="numSuggestedTopicPages > 1"
                                        :page-size="suggestedTopicPageSize"
                                        :total-pages="numSuggestedTopicPages"
                                        :current-page="currentSuggestedTopicPage"
                                        @paged="suggestedTopicPagerClicked">
                            </grid-pager>
                        </template>
                    </grid>
                </titled-section>
                <titled-section>
                    <template v-slot:title>
                        <div>Provide your own topics to add to the Signal</div>
                    </template>
                    <template v-slot:subtitle>
                        <div>Enter each topic on a new line.</div>
                    </template>
                    <textarea name="user-added-topics-input" class="border border-gray-light rounded-sm w-full h-64 resize-none text-sm p-2"
                              placeholder="Enter your topics here"
                              v-model="this.selectedSignal.userAddedTopics"
                              @blur="saveUserAddedTopics"
                              @focusout="saveUserAddedTopics" />
                </titled-section>
            </div>
            <alert v-if="noTopicsAlertMessage" styleType="warning">
                {{noTopicsAlertMessage}}
            </alert>
        </div>
        <div v-else>
            <div class="pb-5">
                <h2 class="text-3xl text-blue-gray-dark">
                    We're preparing your signal definition
                </h2>
                <p class="text-blue-gray-dark">
                    Thank you for completing your signal definition and go-to-market
                    settings. Your signal definition takes a few minutes to be defined.
                </p>
            </div>
            <div class="flex flex-col items-center pt-8">
                <div class="loader"></div>
                <div class="py-12">
                    Please check back later to finalize your signal definition.
                </div>
                <btn color="blue-line" @click="navigateToDashboard">Back to signals dashboard</btn>
            </div>
        </div>
        <alert-box v-model:message="topicsAlertMessage"></alert-box>
    </div>
</template>

<script>
    import {
        Alert,
        Grid,
        GridHeaderCell,
        GridRow,
        GridCell,
        GridPager,
        LoadingSpinner,
        Btn,
    } from "@bombora/component-library"
    import FieldErrorMessage from "@/components/shared/FieldErrorMessage.vue"
    import to from "@bombora/component-library/dist/DatePicker"
    import TitledSection from "@/components/shared/TitledSection.vue"
    import PageHeader from "@/components/shared/PageHeader.vue";

    import { ErrorMessages } from '@/constants';

    import { useVuelidate } from "@vuelidate/core"
    import { mapActions, mapState, mapWritableState } from "pinia";
    import { useRootStore } from '@/stores/rootStore'
    import { useReferenceDataStore } from "@/stores/referenceDataStore";
    import { useSignalsStore } from '@/stores/signalStore'

    export default {
        name: "Topics",
        components: {
            Alert,
            FieldErrorMessage,
            Grid,
            GridHeaderCell,
            GridRow,
            GridCell,
            GridPager,
            LoadingSpinner,
            Btn,
            TitledSection,
            PageHeader
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                topicPageSize: 10,
                currentTopicPage: 1,
                suggestedTopicPageSize: 10,
                currentSuggestedTopicPage: 1,
                readonly: false,
                selectedTopics: [],
                polling: 0,
                loadingTopics: true,
                pageLoading: true,
                allTopics: [],
                previouslyUserAddedTopics: [],
                topicsAlertMessage: null,
                noTopicsAlertMessage: null
            }
        },
        computed: {
            ...mapState(useRootStore, ['company']),
            ...mapState(useSignalsStore, ['selectedSignal', 'topics']),
            ...mapWritableState(useReferenceDataStore, ['suggestedTopics', 'validatedTopics']),
            ...mapWritableState(useSignalsStore, ['selectedSignal', 'wizardCanNavigate']),

            userAddedTopicsList() {
                if (this.selectedSignal.userAddedTopics === null) {
                    return []
                } else {
                    return this.selectedSignal.userAddedTopics.split("\n")
                }
            },
            to() {
                // TODO WTF is this
                return to
            },
            numTopicPages() {
                if (this.topics !== undefined && this.topics !== null) {
                    return Math.ceil(this.topics.length / this.topicPageSize)
                }
            },
            paginatedTopics() {
                if (this.topics !== undefined && this.topics !== null) {
                    return this.topics.slice(
                        (this.currentTopicPage - 1) * this.topicPageSize,
                        (this.currentTopicPage - 1) * this.topicPageSize + this.topicPageSize
                    )
                }
            },
            numSuggestedTopicPages() {
                if (this.suggestedTopics !== undefined && this.suggestedTopics !== null) {
                    return Math.ceil(this.suggestedTopics.length / this.suggestedTopicPageSize)
                }
            },
            paginatedSuggestedTopics() {
                if (this.suggestedTopics !== undefined && this.suggestedTopics !== null) {
                    return this.suggestedTopics.slice(
                        (this.currentSuggestedTopicPage - 1) * this.suggestedTopicPageSize,
                        (this.currentSuggestedTopicPage - 1) * this.suggestedTopicPageSize + this.suggestedTopicPageSize
                    )
                }
            }
        },
        async created() {
            this.wizardCanNavigate = false
            await this.getSignalTopics()
            this.previouslyUserAddedTopics = this.userAddedTopicsList

            this.pageLoading = false
        },
        beforeUnmount() {
            clearInterval(this.polling)
        },
        methods: {
            ...mapActions(useSignalsStore, ['upsertSignal', 'fetchSignalDefResponse', 'updateSignalDef', 'deleteTempSignalDef']),
            ...mapActions(useReferenceDataStore, ['fetchSuggestedTopics', 'validateTopics']),
            async getSignalTopics() {
                // Always clear the interval first.
                clearInterval(this.polling)

                let resp = await this.fetchSignalDefResponse(this.selectedSignal.id);
                if (resp.success) {
                    if (this.topics.length == 0) {
                        this.noTopicsAlertMessage = ErrorMessages.noTopicsReturned
                    }

                    // Set the signal topics and selected topics to the same list if signal topics are null
                    if (!this.selectedSignal.topics) {
                        // If the signal topics are null, then we have not yet received and saved the list from the definition service.
                        // We default to selecting all topics returned, so set the lists equal.
                        // This is saving generated topics on the Signal, not selected topics.
                        this.selectedSignal.topics = this.topics;
                        let status = await this.upsertSignal(this.selectedSignal);
                    }
                    // TODO Implemented in SURGE-5731 (https://bombora.atlassian.net/browse/SURGE-5731)
                    // TODO   If this signal has a temporary definition ID, the topics loaded here are from that signal
                    // TODO   definition. Here we overwrite the original signal definition immediately and discard the
                    // TODO   temporary one. These changes should be reverted once the Signal Definition API changes
                    if (this.selectedSignal.tempDefId) {
                        // Send the update now
                        let resp = await this.updateSignalDef(this.selectedSignal.id, this.topics);
                        if (resp.success) {
                            await this.deleteTempSignalDef(this.selectedSignal.id);
                        }
                    }
                    // TODO end SURGE-5731 changes

                    // Set selected topics to the definition topics returns from definition service.
                    this.selectedTopics = this.topics

                    // Get suggested topics
                    if (this.topics.length > 0) {
                        await this.getSuggestedTopics();
                    }

                    this.loadingTopics = false

                    // Only enable nav state once topics return
                    this.wizardCanNavigate = true
                } else {
                    this.setSignalPolling()
                }
            },
            setSignalPolling() {
                this.loadingTopics = true
                this.polling = setInterval(this.getSignalTopics, 5000)
                // Stop polling after 10 minutes
                setTimeout(() => {
                    clearInterval(this.polling)
                }, 600000)
            },
            topicPagerClicked(pageNum) {
                this.currentTopicPage = pageNum
            },
            toggleSelectedTopic(topic) {
                // Used for the row click
                if (this.selectedTopics.filter(t => t.id == topic.id).length > 0) {
                    this.selectedTopics = this.selectedTopics.filter(t => t.id !== topic.id)
                } else {
                    this.selectedTopics.push(topic)
                }
            },
            suggestedTopicPagerClicked(pageNum) {
                this.currentSuggestedTopicPage = pageNum
            },
            async saveTopics() {
                await this.updateSignalDef(this.selectedSignal.id, this.selectedTopics)
            },
            async getSuggestedTopics() {
                // 25 is the maximum number of suggested topics to return
                await this.fetchSuggestedTopics(this.topics.map(t => t.id), 25);
                // filter suggested topics to remove any that already exist in the generated topics
                this.suggestedTopics = this.suggestedTopics.filter(t => !this.topics.find(st => st.id === t.id))
            },
            navigateToDashboard() {
                this.$router.push({ name: "Signals", params: { id: this.company.id } })
            },
            async saveUserAddedTopics() {
                var addedTopics = this.userAddedTopicsList.filter(t => t !== '');
                this.wizardCanNavigate = false

                // filter out previously added user added topics
                this.selectedTopics = this.selectedTopics.filter(st => !this.previouslyUserAddedTopics.find(t => t.toLowerCase() === st.name.toLowerCase()))

                // filter out topics that already exist in the selected topics
                addedTopics = addedTopics.filter(t => !this.selectedTopics.find(st => st.name.toLowerCase() === t.toLowerCase()))

                // clean list of user added topics removing trailing whitespace and duplicates
                addedTopics = addedTopics.map(function (topic) {
                    return topic.trim();
                })
                let uniqueAddedTopics = []
                addedTopics.forEach((topic) => {
                    if (!uniqueAddedTopics.includes(topic)) {
                        uniqueAddedTopics.push(topic)
                    }
                })
                addedTopics = uniqueAddedTopics

                // get list of validated topics if added topics is not empty or null
                if (addedTopics && addedTopics.length > 0) {
                    let resp = await this.validateTopics(addedTopics);
                    if (resp.success) {
                        for (let item in this.validatedTopics) {
                            this.selectedTopics.push(this.validatedTopics[item])
                        }

                        //save the topics to the signal definition
                        await this.saveTopics()

                        await this.upsertSignal(this.selectedSignal);
                    } else {
                        this.topicsAlertMessage = 'Unable to save topics.'
                        console.error('Could not save topics')
                    }
                } else {
                    await this.saveTopics()
                    await this.upsertSignal(this.selectedSignal);
                }


                // set matched topics equal to the new matched topics list
                this.previouslyUserAddedTopics = this.userAddedTopicsList

                this.wizardCanNavigate = true

            },
        }
    }
</script>
<style lang="css" scoped>
    /* Overwrite the existing hover color */
    .selected-topic {
        @apply bg-blue-gray bg-opacity-25;
    }

    .loader {
        border: 8px solid #f3f3f3; /* Light grey */
        border-top: 8px solid #3498db; /* Blue */
        border-radius: 50%;
        width: 60px;
        height: 60px;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
</style>
