const validDomainRegex = /^(?:[a-zA-Z\d][a-zA-Z\d-]{0,61}[a-zA-Z\d]\.|[a-zA-Z\d]\.)+[a-zA-Z][a-zA-Z\d-]{0,61}[a-zA-Z\d].*$/

const cleanDomain = function (domain) {
    let cleanDomain = domain
        .replace("https://", "")
        .replace("http://", "")
        .replace("http:", "")
        .replace("https:", "");

    if (cleanDomain.startsWith("www.")) {
        cleanDomain = cleanDomain.substring(4);
    }

    return cleanDomain;
}

export { validDomainRegex, cleanDomain }
