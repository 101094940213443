<template>
    <div class="mb-8">
        <page-header title="Schedule Delivery" class="mb-8">
            <template v-slot>
                This feature no longer exists. Emails are no longer being sent by Signal Creator.
            </template>
        </page-header>
        <div>
            <textarea @blur="saveDeliveryEmails"
                      @focusout="saveDeliveryEmails"
                      name="emails-input"
                      class="border-2 rounded-md border-gray-light h-96 w-full p-2"
                      placeholder="Emails" v-model="this.emailString"
                      disabled/>
        </div>
    </div>
</template>

<script>
    import {
        ColumnGroup,
        Grid,
        GridHeaderCell,
        GridRow,
        GridCell,
        GridPager,
        LoadingSpinner,
        Icon,
        Btn,
    } from "@bombora/component-library"
    import { useVuelidate } from "@vuelidate/core"
    import FieldGridRow from "@/components/shared/FieldGridRow";
    import PageHeader from "@/components/shared/PageHeader.vue";

    import { useSignalsStore } from '@/stores/signalStore.js';
    import { mapWritableState, mapActions } from "pinia";

    export default {
        name: "ScheduleDelivery",
        components: {
            ColumnGroup,
            FieldGridRow,
            Grid,
            GridHeaderCell,
            GridRow,
            GridCell,
            GridPager,
            LoadingSpinner,
            Icon,
            Btn,
            PageHeader
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                pageLoading: true,
                emailString: ''
            }
        },
        computed: {
            ...mapWritableState(useSignalsStore, ['selectedSignal', 'wizardCanNavigate']),
            emailList() {
                return this.emailString ? this.emailString.split("\n").filter(t => t !== "") : [];
            },
        },
        async mounted() {
            this.wizardCanNavigate = true;
            this.pageLoading = true
            this.emailString = this.selectedSignal ? this.selectedSignal.deliveryEmails?.join("\n") : '';


            this.pageLoading = false
        },
        methods: {
            ...mapActions(useSignalsStore, ['upsertSignal']),
            async saveDeliveryEmails() {
                this.selectedSignal.deliveryEmails = this.emailList;
                let status = await this.upsertSignal(this.selectedSignal);
            }
        },
    }
</script>
<style lang="css" scoped>
</style>
