class DoublyLinkedList {
    constructor() {
        this.nodes = [];
    }

    get size() {
        return this.nodes.length;
    }

    get head() {
        return this.size ? this.nodes[0] : null;
    }

    get tail() {
        return this.size ? this.nodes[this.size - 1] : null;
    }

    insertAt(index, element) {
        const previousNode = this.nodes[index - 1] || null;
        const nextNode = this.nodes[index] || null;
        const node = { element, next: nextNode, previous: previousNode };

        if (previousNode) previousNode.next = node;
        if (nextNode) nextNode.previous = node;
        this.nodes.splice(index, 0, node);
    }

    insertFirst(element) {
        this.insertAt(0, element);
    }

    insertLast(element) {
        this.insertAt(this.size, element);
    }

    getAt(index) {
        return this.nodes[index];
    }

    retrieve(element) {
        for (var i = 0; i < this.nodes.length; i++) {
            if (this.nodes[i].element === element) {
                return this.nodes[i];
            }
        }
    }

    removeAt(index) {
        const previousNode = this.nodes[index - 1] || null;
        const nextNode = this.nodes[index + 1] || null;

        if (previousNode) previousNode.next = nextNode;
        if (nextNode) nextNode.previous = previousNode;

        return this.nodes.splice(index, 1);
    }

    clear() {
        this.nodes = [];
    }

    reverse() {
        this.nodes = this.nodes.reduce((acc, { element }) => {
            const nextNode = acc[0] || null;
            const node = { element, next: nextNode, previous: null };
            if (nextNode) nextNode.previous = node;
            return [node, ...acc];
        }, []);
    }

    *[Symbol.iterator]() {
        yield* this.nodes;
    }
}

export default DoublyLinkedList