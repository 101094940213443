<template>
  <div class="inline-block w-full h-40 border-dashed border-2 border-bluer-gray rounded-md mb-6 relative">
    <input ref="upload" type="file" :accept="this.accept" @change="handleNewFiles" hidden multiple>
    <div @click="() => this.$refs.upload.click()"
         @dragleave.stop.prevent="onDragLeave"
         @dragover.stop.prevent="onDragOver"
         @drop.stop.prevent="handleDroppedFile"
         :class="['upload',
                  draggedOver ? 'shadow-2xl' : '']">

      <slot />
    </div>
  </div>
</template>

<script>

  export default {
    name: "FileDropZone",
    emits: ['updated'],
    props: ["accept"],
    data() {
      return {
        draggedOver: false,
        files: []
      }
    },
    methods: {
      async handleNewFiles(event) {
        if (event.target.files.length === 0) return;

        this.files = [];
        event.target.files.forEach(file => {
          this.files.push(file);
        });
        //reset file upload
        this.$refs.upload.value = null;

        this.$emit('updated', this.files);

      },
      async handleDroppedFile(event) {
        this.draggedOver = false
        if (event.dataTransfer.files.length === 0) return;

        this.files = [];
        event.dataTransfer.files.forEach(file => {
          this.files.push(file);
        });

        this.$emit('updated', this.files);
      },
      onDragOver() {
        this.draggedOver = true;
      },
      onDragLeave() {
        this.draggedOver = false
      }
    }
  };
</script>

<style scoped>
.upload {
  @apply flex items-center justify-center h-full w-full
}
</style>
