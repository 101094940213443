import { createApp } from "vue"
import { createPinia } from 'pinia'
import App from "./App.vue"
import router from "./router"
import { setupAuth } from "./services/auth"
import axios from "axios"
import DropZone from 'dropzone-vue';
import { datadogRum } from '@datadog/browser-rum'

import "./assets/base.css"
import { LDPlugin } from "launchdarkly-vue-client-sdk";
import { clickOutside } from "@/directives/clickOutside";

function callbackRedirect(appState) {
  return router.push(appState && appState.targetUrl ? appState.targetUrl : "/")
}

const main = async () => {
  const ddogConfigRes = await axios.get("/config/ddog")
  if (!ddogConfigRes.data.token || !ddogConfigRes.data.applicationId || !ddogConfigRes.data.environment) {
    console.error("Could not initialize DDOG RUM. Missing data.")
  } else {
	  // Keep this version updated with the Makefile
      datadogRum.init({
          applicationId: ddogConfigRes.data.applicationId,
          clientToken: ddogConfigRes.data.token,
          env: ddogConfigRes.data.environment,
          site: ddogConfigRes.data.site || 'datadoghq.com',
          service: ddogConfigRes.data.service || 'signal-creator',
          version: '2.7.9',
          trackResources: true,
          sampleRate: 100,
          trackLongTasks: true,
          trackInteractions: true,
      })
  }

  const pinia = createPinia()

  let app = createApp(App)
    .use(router);

  app.use(pinia);

  app.use(DropZone);

  app.directive("clickOutside", clickOutside);

  const authConfigRes = await axios.get("/config/auth")
  if (!authConfigRes.data.domain || !authConfigRes.data.clientId || !authConfigRes.data.audience) {
    console.error(
      "Could not initialize authentication. Missing data.",
      authConfigRes.data
    )
  }
  app.use(await setupAuth(
    {
      domain: authConfigRes.data.domain,
      client_id: authConfigRes.data.clientId,
      redirect_uri: `${window.location.origin}/LoginCallback`,
      audience: authConfigRes.data.audience,
    },
    callbackRedirect
  ));

  const ldConfig = (await axios.get("/config/launchdarkly")).data;
  if (!ldConfig || !ldConfig.clientId) {
    console.error("Could not load LaunchDarkly");
  } else {
    app.use(LDPlugin, { clientSideID: ldConfig.clientId });
  }

  return app.mount("#app");
}

// Execute async function
/* eslint-disable-next-line no-unused-vars */
main().then(() => {
  console.debug("App Mounted");
});
