import { defineStore } from 'pinia'
import { apiClient } from "../services/api"
import { unwrapServerResponse } from '@/helpers'

export const useReferenceDataStore = defineStore('referenceData', {
  state: () => ({
    companySizes: [],
    companyRevenues: [],
    industries: [],
    geographies: [],
    regions: [],
    suggestedTopics: [],
    validatedTopics: []
  }),
  actions: {
    async fetchCompanySizes() {
      let response = await apiClient().get({ url: `/referencedata/companysize` })
      let unwrappedResponse = unwrapServerResponse(response)
      if (unwrappedResponse.success) {
        this.companySizes = response.data;
          this.companySizes.sort(this.sortCompanySizes)
      }
      return unwrappedResponse;
    },
    async fetchCompanyRevenues() {
      let response = await apiClient().get({ url: `/referencedata/companyrevenue` })
      let unwrappedResponse = unwrapServerResponse(response)
      if (unwrappedResponse.success) {
        this.companyRevenues = response.data;
        this.companyRevenues.sort(this.sortCompanyRevenues)
      }
      return unwrapServerResponse(response);
    },
    async fetchIndustries() {
      let response = await apiClient().get({ url: `/referencedata/industry` })
      let unwrappedResponse = unwrapServerResponse(response)
      if (unwrappedResponse.success) {
        this.industries = response.data;
        this.industries.sort()
      }
      return unwrappedResponse
    },
    async fetchGeographies() {
      let response = await apiClient().get({ url: `/referencedata/geography` })
      let unwrappedResponse = unwrapServerResponse(response)
      if (unwrappedResponse.success) {
        this.geographies = response.data;
      }
      return unwrappedResponse;
    },

    async fetchRegions() {
      let response = await apiClient().get({ url: `/referencedata/regions` })
      let unwrappedResponse = unwrapServerResponse(response)
      if (unwrappedResponse.success) {
        this.regions = response.data;
      }
      return unwrappedResponse;
    },
    async fetchSuggestedTopics(topicIds, maxTopics) {
      let response = await apiClient().get({url: `/referencedata/topics`, options: { params: { topicIds: topicIds.join(","), maxTopics: maxTopics } }})
      let unwrappedResponse = unwrapServerResponse(response)
      if (unwrappedResponse.success) {
        this.suggestedTopics = response.data.map(t => {
          return {
            id: t.id,
            name: t.name,
            weighting: 1,
          }
        });
      }
      return unwrappedResponse;
    },
    async validateTopics(topics) {
      let response = await apiClient().get({ url: `/referencedata/validatetopics`, options: { params: { topics: topics.join("\n") } } });
      let unwrappedResponse = unwrapServerResponse(response)
      if (unwrappedResponse.success) {
        this.validatedTopics = response.data.map(t => {
          return {
            id: t.id,
            name: t.name,
            weighting: 1,
          }
        });
      }
      return unwrappedResponse;
    },
      sortCompanySizes(a, b) {
          // Sorts the company sizes from least to greatest
          a = Number(a.split(" ")[1].slice(1).replaceAll(",", "").replaceAll("+", ""))
          b = Number(b.split(" ")[1].slice(1).replaceAll(",", "").replaceAll("+", ""))
          if (a < b) {
              return -1
          } else if (a > b) {
              return 1
          }
          return 0
      },
      sortCompanyRevenues(a, b) {
          // Sorts the company revenues from least to greatest
          var indexA
          var additionalsA
          var indexB
          var additionalsB
          var stringA = a.split(" ")[1].slice(1)
          if (stringA.indexOf("M")<0) {
              indexA = stringA.indexOf("B")
              additionalsA = "000000000"
          }
          else {
              indexA = stringA.indexOf("M")
              additionalsA = "000000"
          }
          var numA = Number(stringA.slice(0,indexA).replaceAll("<","-").replaceAll("$","") + additionalsA)
          var stringB = b.split(" ")[1].slice(1)
          if (stringB.indexOf("M")<0) {
              indexB = stringB.indexOf("B")
              additionalsB = "000000000"
          }
          else {
              indexB = stringB.indexOf("M")
              additionalsB = "000000"
          }
          var numB = Number(stringB.slice(0,indexB).replaceAll("<","-").replaceAll("$","") + additionalsB)
          if (numA < numB) {
              return -1
          } else if (numA > numB) {
              return 1
          }
          return 0
      }
  }
  });
