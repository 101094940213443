<template>
  <div class="mb-8 mx-8">
    <div class="pb-5 mb-8 border-b-2 border-gray-light">
      <h1 class="pb-8 text-4xl text-blue-gray-dark">{{headingText}}</h1>
      <column-group>
        <div class="relative">

          <div v-if="loading" class="absolute bg-white bg-opacity-60 z-10 h-full flex items-center justify-center">
            <div class="flex items-center">
              <div class="loader-dots block relative w-20 h-5 mt-2">
                <div class="absolute top-0 mt-1 w-2 h-2 rounded-full bg-gray-light"></div>
                <div class="absolute top-0 mt-1 w-2 h-2 rounded-full bg-gray-light"></div>
                <div class="absolute top-0 mt-1 w-2 h-2 rounded-full bg-gray-light"></div>
                <div class="absolute top-0 mt-1 w-2 h-2 rounded-full bg-gray-light"></div>
              </div>
            </div>
          </div>
          <div v-if="!loading">
            <input @blur="onNameEdit"
                   v-if="nameEditing"
                   name="signal.name"
                   class="text-2xl font-bold text-blue-gray-dark appearance-none bg-transparent border-b border-blue-gray focus:outline-none w-2/3"
                   placeholder="Name your signal" v-model="selectedSignal.name" />
            <h3 v-if="!nameEditing" class="pb-5 text-2xl font-bold text-blue-gray-dark">
              {{selectedSignal.name}}
              <icon data-id="signal-name-edit-action" icon-type="edit" icon-color="#455465" class="w-4 ml-2 cursor-pointer" v-if="displayNameEdit" @click="() => nameEditing = true" />
            </h3>
            <p class="text-sm text-red" v-if="nameErrorMessage != ''">
              {{nameErrorMessage}}
            </p>
          </div>
        </div>
        <div>
          <div v-if="!loading" class="float-right">
            <btn data-id="wizard-top-save-action" color="orange" v-if="displaySave" @click="saveSignal">Save</btn>
            <btn data-id="wizard-top-forward-navigate" :color="wizardCanNavigate ? 'blue': 'gray'" @click="navigateForward">{{continueText}}</btn>
          </div>
        </div>
      </column-group>
    </div>
    <div class="grid grid-cols-12">
      <div class="col-span-3 pr-10 border-r-2 border-gray-light">
        <wizard-navigator :selected-signal="this.selectedSignal" @on-navigation-request="navigateTo"></wizard-navigator>
      </div>
      <div class="col-span-9 pl-10 relative">
        <div v-if="loading || stateLoading" class="absolute bg-white bg-opacity-60 z-10 h-full w-full flex items-center justify-center">
          <div class="flex items-center">
            <loading-spinner />
          </div>
        </div>

        <div v-if="!loading">
          <div class="mb-8">
            <router-view v-if="!loading" v-slot="{ Component }">
              <transition name="fade" mode="out-in">
                <component :is="Component" />
              </transition>
            </router-view>

            <alert-box v-model:message="navigationAlertMessage"></alert-box>
            <alert-box v-model:message="apiResponseAlertMessage"></alert-box>
            <alert-box v-model:message="signalSaveAlertMessage" :type="'success'"></alert-box>
            <alert-box v-model:message="signalLoadAlertMessage"></alert-box>
            <alert-box v-model:message="createSignalAlertMessage"></alert-box>
            <alert-box v-model:message="unauthorizedAlertMessage"></alert-box>
            <alert-box v-model:message="completeFailedAlertMessage"></alert-box>
          </div>

          <column-group>
            <div>
              <btn data-id="wizard-backwards-navigate" color="gray" @click="navigateBackwards" v-if="displayBackButton">{{backText}}</btn>
            </div>
            <div>
              <div class="float-right flex">
                <btn data-id="wizard-skip-filters-navigate" color="blue-line" v-if="displaySkipFiltering" @click="navigateSkipFilters">Skip Filtering</btn>
                <btn data-id="wizard-bottom-save-action" color="orange" v-if="displaySave" @click="saveSignal">Save</btn>
                <btn data-id="wizard-bottom-forward-navigate" :color="wizardCanNavigate ? 'blue': 'gray'" @click="navigateForward">{{continueText}}</btn>
              </div>
            </div>
          </column-group>
        </div>
      </div>
    </div>
  </div>
  <confirm-dialog v-if="submitting" @close="closeSubmissionDialog" @confirm="confirmDefinitionSubmission" title="Confirm Submissions"
                  message='Please confirm your Signal Definition settings. Once you submit, you will not be able to return to this section and edit until the Signal is classified as "active" on the dashboard.' />

  <message-dialog v-if="unauthorized && !loading" @close="unauthorized = false" title="Unauthorized" :dismissable="false"
                  message="You are not authorized to view this signal." />

  <submit-dialog v-if="signalSubmitting" @close="closeSignalSubmissionDialog" @confirm="confirmComplete" title="Confirm Submission"
                 acceptText="Complete" icon="alert"
                 message="Are you sure you are finished building your signal? This cannot be undone." />
</template>
<script>
  import {
    Alert,
    Breadcrumbs,
    Btn,
    Card,
    ColumnGroup,
    Grid,
    GridRow,
    GridCell,
    GridHeaderCell,
    GridPager,
    Icon,
    LoadingSpinner,
  } from "@bombora/component-library"
  import { wizardRoutingOrder, wizardRouteNames, wizardSectionMap } from '@/router/routerConstants';
  import { NavStateEnum, ErrorMessages } from '@/constants';
  import ConfirmDialog from "@/components/shared/ConfirmDialog.vue"
  import MessageDialog from "@/components/shared/MessageDialog.vue"
  import SubmitDialog from "@/components/shared/SubmitDialog.vue"
  import AlertBox from "@/components/shared/AlertBox.vue"
  import WizardNavigator from "@/components/pages/fragments/WizardNavigator.vue"

  import { useRootStore } from '@/stores/rootStore.js';
  import { useSignalsStore } from '@/stores/signalStore.js';
  import { mapState, mapWritableState, mapActions } from 'pinia'

  export default {
    name: "SignalInformation",
    components: {
      Alert,
      AlertBox,
      Breadcrumbs,
      Btn,
      ConfirmDialog,
      Card,
      ColumnGroup,
      Grid,
      GridRow,
      GridCell,
      GridHeaderCell,
      GridPager,
      SubmitDialog,
      Icon,
      MessageDialog,
      LoadingSpinner,
      WizardNavigator
    },
    props: ["id", "signalId"],
    data() {
      return {
        existingSignalNames: [],
        headingtext: '',
        // Loading flag for the initial load of the page data
        loading: true,
        // Loading flag for the on page spinner
        stateLoading: false,
        nameEditing: false,
        submitting: false,
        signalSubmitting: false,
        displaySave: true,
        nameErrorMessage: null,
        navigationAlertMessage: null,
        signalSaveAlertMessage: null,
        signalLoadAlertMessage: null,
        createSignalAlertMessage: null,
        unauthorizedAlertMessage: null,
        apiResponseAlertMessage: null,
        completeFailedAlertMessage: null
      }
    },
    computed: {
      ...mapWritableState(useSignalsStore, ['companySignals', 'selectedSignal', 'wizardCanNavigate']),
      ...mapState(useRootStore, ['company', 'unauthorized']),
      currentRouteName() {
        return this.$router.currentRoute.value.name
      },
      routeParams() {
        return { id: this.id, signalId: this.signalId }
      },
      backText() {
        // If this is the first page on the wizard tell the user they will be going back to the dashboard
        let firstPageInWizard = this.currentRouteName == wizardRoutingOrder.head.element
        return firstPageInWizard ? 'Back to Dashboard' : 'Back'
      },
      continueText() {
        // If this is the first page on the wizard tell the user they will be going back to the dashboard
        let lastPageInWizard = this.currentRouteName == wizardRoutingOrder.tail.element
        return lastPageInWizard ? 'Complete' : 'Save & Continue'
      },
      displaySave() {
        return this.currentRouteName !== wizardRouteNames.Output
      },
      displayBackButton() {
        return this.currentRouteName !== wizardRouteNames.Filtering
      },
      displayNameEdit() {
        // Only allow name edits if the signal has not been submitted for data yet.
        return this.selectedSignal.navigationState == NavStateEnum.Definition
      },
      displaySkipFiltering() {
        return this.selectedSignal.netNewAccounts === 'yes' && this.currentRouteName === 'AudienceSize'
      }
    },
    async created() {
      if (this.unauthorized) {
        this.unauthorizedAlertMessage = ErrorMessages.unauthorizedAction;
        this.loading = false;
        return;
      }

      if (this.companySignals.length == 0) {
        // Load all existing signal names to ensure uniqueness on name edit.
        // Filter the current signal to prevent false positive.
        let response = await this.fetchSignals(this.id, this.signalId);
        if (response.success) {
          // Set the signal from the fill list so we dont have to roundtrip to the Backend
          this.existingSignalNames = this.companySignals.filter(x => x.id != this.signalId).map(x => { return x.name });
        } else {
          this.signalLoadAlertMessage = ErrorMessages.signalLoadError
          console.error('Could not load signal by id')
        }
      }

      if (this.selectedSignal == null) {
      // TODO why isnt this working.
          this.selectedSignal = this.companySignals.filter(x => x.id == this.signalId)[0];
      }

      // Set header text by section
      const sections = Object.entries(wizardSectionMap);
      for (var i = 0; i < sections.length; i++) {
        var sectionItems = sections[i][1]
        if (sectionItems.includes(this.currentRouteName)) {
          switch (sections[i][0]) {
            case 'Definition':
              this.headerText = "Let's create your signal definitions";
              break;
            case 'Segmentation':
              this.headerText = "Set up your go-to-market settings";
              break;
            case 'Review':
              this.headerText = "Review your signal output";
              break;
          }
        }
      }

      this.loading = false;
    },
    methods: {
      ...mapActions(useSignalsStore, ['fetchSignals', 'createSignalDef', 'lockSignal', 'upsertSignal', 'completeSignal']),
      async navigateTo(route) {
        if (this.wizardCanNavigate) {
          // This will disallow navigations backwards on new signals
          // Clicking the Section Heading will route to Goals and clicking the Back button will route to UploadKeywords
          let routeTrigger = (route == wizardRouteNames.Goals || route == wizardRouteNames.UploadKeywords)
          let stateTrigger = this.selectedSignal.navigationState != NavStateEnum.Definition
          let existingSignalTrigger = (this.selectedSignal?.completedPages && this.selectedSignal.completedPages.length === Object.keys(wizardRouteNames).length)
          // Only route and state matter for new signals, if this is an existing signal; allow navigation.
          if (routeTrigger && stateTrigger && !existingSignalTrigger) {
            // The signal is in a locked state, do not allow navigations to the Definition section
            return
          }

          //Do not allow user to enter Account List component if Net New Accounts is 'yes'
          if (this.selectedSignal.netNewAccounts === 'yes' && route == wizardRouteNames.AccountList) {
              return
          }

          await this.saveSignalSilently()
          document.activeElement.blur()
          this.$router.push({ name: route, params: this.routeParams })
          return
        }

        // Default to failed navigation
        // Pop alert with navigation hint
        document.activeElement.blur()
        this.navigationAlertMessage = this.currentRouteName == wizardRouteNames.UploadKeywords ? ErrorMessages.keywordNavigationError : ErrorMessages.navigationError;
      },
      async navigateSkipFilters() {
        //push the remaining segmentation pages as completed
        let pagesToPush = [wizardRouteNames.AudienceSize, wizardRouteNames.Profitability, wizardRouteNames.Industries, wizardRouteNames.Geographies]
        pagesToPush.forEach(x => {
            if (!this.selectedSignal.completedPages?.includes(x)) {
                this.selectedSignal.completedPages.push(x)
            }
        })
        //set navigation state to Review
        this.selectedSignal.navigationState = NavStateEnum.Review
        this.$router.push({ name: wizardRouteNames.Topics, params: this.routeParams })
      },
      async navigateForward() {
        // Do not await this call, it slows navigation and we dont need the result
        this.saveSignalSilently()

        // TODO this is significantly cleaner than it was, but it is still really messy. There has got to be a better pattern here.
        // If the wizard is clear to navigate, do it.
        if (this.wizardCanNavigate) {
          let currentNavItem = wizardRoutingOrder.retrieve(this.currentRouteName)
          let nextNavRoute = ""
          if (this.currentRouteName !== wizardRoutingOrder.tail.element) {
              nextNavRoute = currentNavItem.next.element
          }
          //if we are able to navigate, push the name of the route name to the completed pages array
          if(this.selectedSignal.completedPages == null) {
              this.selectedSignal.completedPages = []
          }
          let pagesToPush = [this.currentRouteName]
          if(this.currentRouteName === 'Filtering'  && this.selectedSignal.netNewAccounts === 'yes') {
              pagesToPush.push(wizardRouteNames.AccountList)
          } else if (this.currentRouteName === 'AccountList' && this.selectedSignal.useBomboraFilters === 'yes') {
              pagesToPush.push(wizardRouteNames.AudienceSize)
          }
          pagesToPush.forEach(x => {
            if (!this.selectedSignal.completedPages?.includes(x)) {
              this.selectedSignal.completedPages.push(x)
            }
          })
          // If we are on the keywords page or the last page, we need to tell the user we are submitting
          // Set the correct state based on what submit this is, and set the appropriate route
          // TODO this is much better than what it was before, but this is still mixing concerns and smells bad.
          // TODO We shouldn't be setting dialog state and navigation state in the same switch.
          switch (this.currentRouteName) {
            case wizardRouteNames.UploadKeywords:
              this.submitting = true;
              return;
            case wizardRoutingOrder.tail.element:
              this.signalSubmitting = true;
              return;
            // Check if page is Net New Accounts Filtering page. If we are filtering on net new, go straight to audience size page, else go to use target account page
            case wizardRouteNames.Filtering:
              if (this.selectedSignal.netNewAccounts === 'yes') {
                nextNavRoute = wizardRouteNames.AudienceSize;
              }
              break;
            // Check if page is Account List page. If so, continue to profitability and skip audience size
            case wizardRouteNames.AccountList:
              if(this.selectedSignal.useBomboraFilters === 'yes') {
                  nextNavRoute = wizardRouteNames.Profitability
              } else {
                  //push remaining segmentation pages as complete
                  let pagesToPush = [wizardRouteNames.AudienceSize, wizardRouteNames.Profitability, wizardRouteNames.Industries, wizardRouteNames.Geographies]
                  pagesToPush.forEach(x => {
                      if (!this.selectedSignal.completedPages?.includes(x)) {
                          this.selectedSignal.completedPages.push(x)
                      }
                  })
                  //set navigation state to Review
                  this.selectedSignal.navigationState = NavStateEnum.Review
                  nextNavRoute = wizardRouteNames.Topics
              }
              break;
              case wizardRouteNames.Geographies:
                  this.selectedSignal.navigationState = NavStateEnum.Review
          }

          // Remove the alert for navigation
          // Do the navigate and get outta here!
          await this.navigateTo(nextNavRoute);
          return;
        }
        // If we are not clear to navigate, pick the right error message.
        // Check if this is a signal definition submission action
        if (this.currentRouteName === wizardRouteNames.UploadKeywords) {
          this.navigationAlertMessage = ErrorMessages.keywordNavigationError;
        } else {
            this.navigationAlertMessage = ErrorMessages.navigationError;
        }
      },
      navigateBackwards() {
        let item = wizardRoutingOrder.retrieve(this.currentRouteName)
        // If this is the first page on the wizard, navigate to the dashboard
        if (item.previous == null) {
          this.navigateTo('Signals')
          return
        }

        let navigationTarget = item.previous.element;
          if (this.currentRouteName == wizardRouteNames.AudienceSize) {
              navigationTarget = wizardRouteNames.Filtering
          }
          else if (this.currentRouteName == wizardRouteNames.Profitability) {
              navigationTarget = this.selectedSignal.netNewAccounts == 'yes' ? wizardRouteNames.AudienceSize : wizardRouteNames.AccountList
          }
          else if (this.currentRouteName == wizardRouteNames.Topics) {
              navigationTarget = this.selectedSignal.useBomboraFilters == 'no' ? wizardRouteNames.AccountList : item.previous.element
          }
          // Carve out the back nav on keywords to allow users to go back depite the logic in navigateTo
          else if (this.currentRouteName == wizardRouteNames.UploadKeywords) {
              this.$router.push({ name: navigationTarget, params: this.routeParams })
              return
          }
        this.navigateTo(navigationTarget)
      },
      saveSignalSilently: async function () {
        await this.upsertSignal(this.selectedSignal);
        //TODO What to do if there is an error
        document.activeElement.blur()
      },
      saveSignal: async function () {
        await this.saveSignalSilently(this.selectedSignal);
        this.signalSaveAlertMessage = 'Signal Saved'
      },
      onNameEdit() {
        this.nameErrorMessage = null;
        // If name was emptied to null, error message
        if (!this.selectedSignal.name) {
          this.nameErrorMessage = ErrorMessages.signalMissingName;
        } else if (this.existingSignalNames.includes(this.selectedSignal.name)) {
          this.nameErrorMessage = ErrorMessages.signalDuplicateName;
        } else {
          this.saveSignalSilently()
          this.nameEditing = false;
        }
      },
      // This method is used only to stop the user from leaving Definition before they confirm they want to
      async confirmDefinitionSubmission() {
        this.submitting = false;
        this.stateLoading = true;
        let response = await this.createSignalDef(this.selectedSignal.id)
        if (!response.success) {
          this.stateLoading = false;
          this.apiResponseAlertMessage = response.message;
          // Navigate to the next section
          let item = wizardRoutingOrder.retrieve(wizardRouteNames.UploadKeywords)
          setTimeout(() => this.$router.push({ name: item.next.element, params: this.routeParams }), 5000);
        } else {
          let resp = await this.lockSignal(this.company.id, this.selectedSignal);
          this.stateLoading = false;
          if (resp.success) {
              // Navigate to the next section
              let item = wizardRoutingOrder.retrieve(wizardRouteNames.UploadKeywords)
              this.$router.push({ name: item.next.element, params: this.routeParams })
          }
        }
      },
      closeSubmissionDialog() {
        this.submitting = false;
      },
      async confirmComplete() {
        this.signalSubmitting = false;
        let resp = await this.completeSignal(this.selectedSignal)
        if (resp.success) {
          this.$router.push({ name: "SignalComplete", params: this.routeParams })
        } else {
          this.completeFailedAlertMessage = ErrorMessages.signalCompleteError;
        }
      },
      closeSignalSubmissionDialog() {
        this.signalSubmitting = false;
      }
    },
  }
</script>
<style lang="css" scoped>
  *:focus {
    outline: 0 !important;
  }

  .slide-fade-enter-active {
    transition: all 0.3s ease-out;
  }

  .slide-fade-leave-active {
    transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateY(30px);
    opacity: 0;
  }

  .loader-dots div {
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

    .loader-dots div:nth-child(1) {
      left: 8px;
      animation: loader-dots1 0.6s infinite;
    }

    .loader-dots div:nth-child(2) {
      left: 8px;
      animation: loader-dots2 0.6s infinite;
    }

    .loader-dots div:nth-child(3) {
      left: 32px;
      animation: loader-dots2 0.6s infinite;
    }

    .loader-dots div:nth-child(4) {
      left: 56px;
      animation: loader-dots3 0.6s infinite;
    }

  @keyframes loader-dots1 {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes loader-dots3 {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(0);
    }
  }

  @keyframes loader-dots2 {
    0% {
      transform: translate(0, 0);
    }

    100% {
      transform: translate(24px, 0);
    }
  }
</style>
