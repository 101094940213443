<template>
  <div class="mb-24">
    <transition name="fade">
      <div class="flex items-center flex-col mt-40" v-if="error">
        <h1 class="text-4xl text-blue-gray-dark font-bold mb-6">We Couldn't Load your Company</h1>
        <p class="mb-8 w-1/3 text-center">{{errorDetails}} Please contact your Bombora representative to get this fixed.</p>
      </div>
    </transition>

    <transition name="fade">
      <div class="flex items-center flex-col mt-40" v-if="loading">
        <h1 class="text-4xl text-blue-gray-dark font-bold mb-6">Hold Tight</h1>
        <p class="mb-8 w-1/3 text-center">We are currently loading your company data.</p>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="loading" class="text-center">
        <div class="inline-block">
          <loading-spinner />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import {
    Card,
    ColumnGroup,
    Btn,
    Icon,
    LoadingSpinner
  } from "@bombora/component-library";
  import { mapState } from 'pinia'
  import { useRootStore } from '@/stores/rootStore'

  export default {
    name: "SignalCreator",
    components: {
      Card,
      ColumnGroup,
      Btn,
      Icon,
      LoadingSpinner
    },
      data() {
          return {
              error: false,
              loading: true,
              errorDetails: ''
          }
      },
      computed: {
          ...mapState(useRootStore, ['company', 'entityId', 'companyFetched']),
      },
    async mounted() {
      // Immediate redirect to the users Company
      if (this.companyFetched) {
        if (this.company != null) {
          this.$router.push({ name: 'Signals', params: { id: this.company.id } })
        } else {
          this.error = true;
          this.errorDetails = `There was no authorized company found for your entity id ${this.entityId}.`;
        }
        this.loading = false;
      } else {
        // TODO UI ERROR
      }
    },
    methods: {
    },
  }
</script>
