<template>
  <Layout>
    <router-view v-if="companyFetched" v-slot="{ Component, route }">
      <component :is="Component" :key="company.id" />
    </router-view>
  </Layout>
</template>

<script>
  import { scrollToAnchor } from "@/utils/scroll"
  import Layout from "@/components/layout/Layout";
  import ComingSoon from "@/components/pages/fragments/ComingSoon";
  import { mapState, mapWritableState, mapActions } from 'pinia'
  import { useRootStore } from '@/stores/rootStore'

  export default {
    name: "App",
    components: {
      Layout,
      ComingSoon
    },
    data() {
      return {
        // Don't display any portion of the app if we are not authenticated. It is janky otherwise.
        isAuthed: this.$auth.isAuthenticated
      }
    },
    computed: {
      ...mapState(useRootStore, ['company', 'companyFetched']),
      ...mapWritableState(useRootStore, ['userEmail']),
    },
    async created() {
      // This is only called when the SPA is initially loaded, which is a case that
      // router.scrollPosition doesn't cover.
      // It ensures that, if the url contains an anchor #, the page scrolls down to that hash.
      if (this.$route.hash) {
        setTimeout(() => scrollToAnchor(this.$route.hash), 1)
      }

      // Initialize Pendo
      let authUser = this.$auth.user.value
      let entityid = "";
      let sub = "";
      let email = "";
      if (authUser !== undefined) {
          entityid = authUser['https://bombora.com/:app_metadata'].entity_id;
          sub = authUser.sub;
          email = authUser.email;
      }
      /* eslint-disable no-undef */
      pendo.initialize({
        visitor: {
          id: sub,
          email: email
        },
        account: {
          id: entityid,
        }
      });
      this.userEmail = email

      // Set entityid app-wide
      if (entityid != null && entityid != undefined) {
        await this.fetchCompany(entityid);
      } else {
        // App has failed to bootstrap, no recovery.
        console.error(`Store entity id was empty`)
      }
    },
    methods: {
      ...mapActions(useRootStore, ['fetchCompany']),
      scrollFix: function (hashbang) {
        location.hash = hashbang
      },
    },
  }
</script>

<style scoped></style>
