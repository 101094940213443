<template>
  <Transition>
      <div v-if="display">
          <alert v-if="type==='success'" style-type="success" class="mt-4">
              {{ message ?? "Success!" }}
          </alert>
          <alert v-else style-type="warning" class="mt-4">
              {{ message ?? "There was a problem. Please try again later." }}
          </alert>
      </div>
  </Transition>
</template>

<script>
import { Alert } from "@bombora/component-library";

export default {
  name: "AlertBox",
  components: {
    Alert
  },
  emits: ['update:message'],
  props: ["message", "type"],
  data() {
    return {
        display: false,
    }
  },
  watch: {
      // watch for change to message. toggle on display if message is not null
      message: function(newVal, oldVal) {
          if (newVal !== null) {
              this.display = true;
              setTimeout(() => {
                  this.display = false;
                  this.$emit('update:message',null);
              }, 5000);
          }
      }
  },
}
</script>

<style scoped>

</style>
