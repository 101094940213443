<template>
  <card :separate-header="false">
    <template #header>
      <div class="pb-10">
        <h2 class="pb-5 text-32px font-bold text-blue-gray-dark">Bombora&reg; Signals Dashboard</h2>
        <p class="text-xl text-blue-gray-dark">
          Build a new Signal or edit an existing Signal based on your product, campaign, use case or audience.
        </p>
      </div>
      <column-group>
        <div>
          <h3 class="font-semibold text-xl text-blue-gray-dark">Your Signals</h3>
        </div>
        <div>

        </div>
        <div class="justify-self-end inline-block">
          <btn @click.once="addSignal" color="orange" v-if="!unauthorized">
            + Create a new Signal
          </btn>
        </div>
      </column-group>
    </template>

    <AlertBox v-model:message="addSignalAlertMessage"></AlertBox>
    <AlertBox v-model:message="unauthorizedAlertMessage"></AlertBox>
    <AlertBox v-model:message="signalDeleteAlertMessage"></AlertBox>

    <grid v-if="loaded && companySignals.length > 0" class="pt-2 pb-2">
      <template #gridHeaderCells>
        <grid-header-cell column-key="signalName" :sortable="true" :sort-direction="sortOrder" @table-sorted="sort">
          Signal Name
        </grid-header-cell>
        <grid-header-cell column-key="useCase" :sortable="false">Use Case</grid-header-cell>
        <grid-header-cell column-key="status" :sortable="false">Status</grid-header-cell>
        <grid-header-cell column-key="details" :sortable="false">Actions</grid-header-cell>
      </template>
      <template #rows>
        <template v-for="(signal, index) in paginatedSignals" :key="signal.id">
          <grid-row @click="navigateForward(signal, id)" class="cursor-pointer">
            <grid-cell>
              {{ signal.name }}
              <br />
              <span class="text-blue-gray-dark text-xs italic">Creation Date: {{formatDate(signal.created)}} | Last Modified: {{formatDate(signal.lastUpdated)}}</span>
            </grid-cell>
            <grid-cell>{{ transformIntentString(signal.intent) }}</grid-cell>
            <grid-cell>{{ getSignalStatus(signal) }}</grid-cell>
            <grid-cell>
              <div class="flex">
                <button class="flex items-center justify-around w-4 h-4 cursor-pointer" @click.stop="deletingSignalId = signal.id">
                    <icon icon-type="trash" icon-color="#044B65" alt="Delete Signal"></icon>
                </button>
              </div>
            </grid-cell>
          </grid-row>
        </template>
      </template>
      <template #footer>
        <grid-pager v-if="numPages > 1" :page-size="pageSize" :total-pages="numPages" :current-page="currentPage"
                    @paged="pageNumClicked" class="pb-5"></grid-pager>
      </template>
    </grid>
  </card>
  <div v-if="!loaded" class="text-center">
    <div class="inline-block">
      <loading-spinner />
    </div>
  </div>

  <confirm-dialog v-if="deletingSignalId !== 0" @close="closeDialog" @confirm="confirmDelete" :actionPending="deletePending" title="Confirm Deletion"
                  message="Are you sure you want to delete? This cannot be undone." />

  <message-dialog v-if="unauthorized && loaded" @close="unauthorized = false" title="Unauthorized" :dismissable="false"
                  message="You are not authorized to view this Company." />
</template>

<script>
  import {
    Card,
    ColumnGroup,
    Btn,
    Grid,
    GridHeaderCell,
    GridRow,
    GridCell,
    GridPager,
    Icon,
    LoadingSpinner,
  } from "@bombora/component-library";
  import ConfirmDialog from "@/components/shared/ConfirmDialog";
  import MessageDialog from "@/components/shared/MessageDialog";
  import AlertBox from "@/components/shared/AlertBox.vue";

  import { formatStringDate } from '@/helpers';
  import { useRootStore } from '@/stores/rootStore.js';
  import { wizardRouteNames } from "@/router/routerConstants";
  import { NavStateEnum, ErrorMessages } from '@/constants';

  import { mapState, mapWritableState, mapActions } from 'pinia'
  import { useSignalsStore } from '@/stores/signalStore'

  export default {
    name: "Signals",
    components: {
      Card,
      ColumnGroup,
      ConfirmDialog,
      Btn,
      Grid,
      GridHeaderCell,
      GridRow,
      GridCell,
      GridPager,
      Icon,
      LoadingSpinner,
      MessageDialog,
      AlertBox,
    },
    props: ['id'],
    data() {
      return {
        loaded: false,
        pageSize: 10,
        currentPage: 1,
        sortOrder: "",
        deletingSignalId: 0,
        deletePending: false,
        addSignalAlertMessage: null,
        unauthorizedAlertMessage: null,
        signalDeleteAlertMessage: null
      }
    },
    computed: {
      ...mapWritableState(useSignalsStore, ['companySignals', 'selectedSignal']),
      ...mapState(useRootStore, ['unauthorized', 'company']),
      numPages() {
        return Math.ceil(this.companySignals.length / this.pageSize);
      },
      paginatedSignals() {
        return this.companySignals.slice((this.currentPage - 1) * this.pageSize, ((this.currentPage - 1) * this.pageSize) + this.pageSize)
      }
    },
    async created() {
      if (!this.unauthorized) {
        await this.refreshSignals();
      }
      this.selectedSignal = null;
      this.loaded = true;
    },
    methods: {
      ...mapActions(useSignalsStore, ['fetchSignals', 'deleteSignalDef', 'upsertSignal']),
      formatDate(date) {
        return formatStringDate(date);
      },
      transformIntentString(intent) {
        switch (intent) {
          case 'marketing':
            return 'Marketing';
          case 'sales':
            return 'Sales';
          case 'both':
            return 'Sales + Marketing';
        }
      },
      pageNumClicked(pageNum) {
        this.currentPage = pageNum;
      },
      sort() {
        // change sort order when column is clicked
        this.sortOrder = this.sortOrder === "" ? "desc" : this.sortOrder === "asc" ? "desc" : "asc";

        if (this.sortOrder === "asc") {
          this.sortByAsc()
        } else if (this.sortOrder === "desc") {
          this.sortByDesc()
        }
      },
      sortByDesc() {
        this.companySignals.sort((a, b) => {
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0
        })
      },
      sortByAsc() {
        this.companySignals.sort((a, b) => {
          return a.name.toLowerCase() < b.name.toLowerCase() ? 1 : a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 0
        })
      },
      closeDialog() {
        this.deletingSignalId = 0;
      },
      async confirmDelete() {
        this.deletePending = true;
        let response = await this.deleteSignalDef(this.deletingSignalId);
        this.deletePending = false;
        this.closeDialog()
        if (!response.success) {
          // Set the error message to the state store value.
          this.signalDeleteAlertMessage = response;
        } else {
          await this.refreshSignals()
        }
      },
      async refreshSignals() {
        this.loaded = false;

        let response = await this.fetchSignals(this.id);
        if (response.success) {
          this.loaded = true;
        } else {
          // TODO UI error
        }
      },
      async addSignal() {
        // Create and save an empty signal to populate the ID for the route.
        let count = this.companySignals.length;
        // All of this is required for the edge case that a user has an existing unnamed signal,
        // deletes one signal from the entire list, and then goes to create a new one. That will
        // result in a duplicate name.
        let currentIndex = count + 1
        let newName = `${this.company.name} Signal ${currentIndex}`
        while (this.companySignals.filter(x => x.name == newName).length > 0) {
          currentIndex += 1
          newName = `${this.company.name} Signal ${currentIndex}`
        }
        let signal = { id: null, name: newName, navigationState: NavStateEnum.Definition };

        let response = await this.upsertSignal(signal)
        if (!response.success) {
          this.addSignalAlertMessage = ErrorMessages.signalCreateError;
          console.error(response.response)
        } else {
          let createdSignal = this.companySignals.filter(x => x.name == signal.name)[0];
          this.$router.push({ name: wizardRouteNames.Goals, params: { id: this.company.id, signalId: createdSignal.id } })
        }
      },
      async navigateForward(signal, id) {
        this.selectedSignal = signal;
        switch (signal.navigationState) {
          case NavStateEnum.Segmentation:
            this.$router.push({ name: wizardRouteNames.Filtering, params: { id: id, signalId: signal.id } })
            break;
          case NavStateEnum.Review:
            this.$router.push({ name: wizardRouteNames.Topics, params: { id: id, signalId: signal.id } });
            break;
          case NavStateEnum.Complete:
            this.$router.push({ name: "SignalReview", params: { id: id, signalId: signal.id } });
            break;
          case NavStateEnum.Definition:
          default:
            this.$router.push({ name: wizardRouteNames.Goals, params: { id: id, signalId: signal.id } })
        }
      },
      getSignalStatus(signal) {
        return signal.navigationState !== NavStateEnum.Complete ? "Pending" : "Active"
      }
    }
  }
</script>
