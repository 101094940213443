<template>
  <div class="mb-8">
    <page-header title="Summary of the product, service or solution in this Signal" class="mb-8">
      <template v-slot>
        Provide a description for the product, service or solution that you will be marketing or selling to the accounts returned by this Signal. (This field is required)
      </template>
    </page-header>
    <div>
      <textarea @blur="onBlur"
                @focusout="onBlur"
                @keyup="updateNavState"
                name="signal.description"
                class="border-2 rounded-md border-gray-light h-60 w-full p-2"
                placeholder="What does this product do?" v-model="selectedSignal.description" />
    </div>
    <FieldErrorMessage :field="v$.selectedSignal.description"></FieldErrorMessage>
  </div>
</template>

<script>
  import {
    Alert,
    Breadcrumbs,
    Btn,
    Card,
    Grid,
    GridRow,
    GridCell,
    GridHeaderCell,
    GridPager,
    Icon,
    LoadingSpinner,
  } from "@bombora/component-library"
  import FieldErrorMessage from "@/components/shared/FieldErrorMessage.vue"
  import PageHeader from "@/components/shared/PageHeader.vue";

  import { useVuelidate } from '@vuelidate/core'
  import { required, helpers, maxLength } from '@vuelidate/validators'
  import { validText } from "@/utils/validText";

  import { mapWritableState, mapActions } from 'pinia'
  import { useSignalsStore } from '@/stores/signalStore'

  export default {
    name: "DefinitionDescriptions",
    components: {
      Alert,
      Breadcrumbs,
      Btn,
      Card,
      FieldErrorMessage,
      Grid,
      GridRow,
      GridCell,
      GridHeaderCell,
      GridPager,
      Icon,
      LoadingSpinner,
      PageHeader
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        selectedSignal: {
          description: {
            required: helpers.withMessage('You must enter a description.', required),
            maxLength: helpers.withMessage('The description must be less than 1000 characters.', maxLength(1000)),
            validText: helpers.withMessage('The description must only contain letters, numbers, spaces, or punctuation.', helpers.regex(validText)),
          },
        }
      }
    },
    async created() {
      this.wizardCanNavigate = false;
    },
    async mounted() {
      if (this.selectedSignal.description && this.selectedSignal.description.length > 0) {
        this.updateNavState();
      }
    },
    computed: {
      ...mapWritableState(useSignalsStore, ['selectedSignal', 'wizardCanNavigate']),
    },
    methods: {
      ...mapActions(useSignalsStore, ['upsertSignal']),
      async onBlur() {
        await this.updateNavState();
        await this.saveSignal();
      },
      async saveSignal() {
        if (this.selectedSignal.description && this.selectedSignal.description.length > 0)
          this.selectedSignal.description = this.selectedSignal.description.trim();
        let status = await this.upsertSignal(this.selectedSignal);
        document.activeElement.blur()
      },
      async updateNavState() {
        const isFormCorrect = await this.v$.$validate()
        this.wizardCanNavigate = isFormCorrect;
      },
    },
  }
</script>

<style lang="css" scoped>
</style>
