export function findInsensitive(object, key) {
  // Running the tolowercase here will prevent the find loop from executing this code for every key.
  let lowerKey = key.toLowerCase();
  return object[
    Object.keys(object).find((key) => key.toLowerCase() === lowerKey)
  ];
}

export function debounce(func, wait, immediate) {
  let timeout
  return function (...args) {
    return new Promise((resolve) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        timeout = null
        if (!immediate) {
          Promise.resolve(func.apply(this, [...args])).then(resolve)
        }
      }, wait)
      if (immediate && !timeout) {
        Promise.resolve(func.apply(this, [...args])).then(resolve)
      }
    })
  }
}

export function tryGetDateString(possibleDate) {
    let val = Date.parse(possibleDate)
    if (isNaN(val)) return null;
    let dt = new Date(val);
    return `${dt.getMonth() + 1}/${dt.getDate()}/${dt.getFullYear().toString().substring(2, 4)}`;
}

export function unwrapServerResponse(response, customMessage='') {
    if (response.status >= 200 && response.status < 300) { // 2xx status codes
        return {
            success: true,
            response: response
        }
    } else { // Any other status code
        let responseMessage = response.message ?? response.data?.message
        return {
            success: false,
            response: response,
            message: customMessage ? `${customMessage} ${responseMessage}` : responseMessage
        }
    }
}

export function formatStringDate(date) {
    return new Date(date).toLocaleDateString('en-us', { year: "numeric", month: "numeric", day: "numeric" })
}
