<template>
    <div class="mb-8">
        <div v-if="pageLoading"
             class="absolute bg-white bg-opacity-60 z-10 h-full w-full flex items-center justify-center">
            <div class="flex items-center">
                <loading-spinner />
            </div>
        </div>
        <div class="pb-5">
            <column-group>
                <page-header title=" Review your Signal Output">
                </page-header>
                <div>
                    <btn data-id="download-csv-action" color="blue-line" class="float-right" @click="downloadCsv()" :disabled="showDownloadSpinner">
                        <spinner-icon v-if="showDownloadSpinner" width="16" height="16" class="mr-2" />Download CSV</btn>
                </div>
            </column-group>
        </div>
        <div>
            <div class="mb-6 text-l font-semibold text-blue-gray-dark">
                This is the first 50 results of your Signal. To see the full list, download the CSV.
            </div>
            <div class="border border-gray-light rounded mb-4">
                <div class="border-l-4 border-blue px-5 py-3 rounded">
                    <column-group>
                        <div class="font-semibold text-lg text-blue-gray-dark">Your Signal Definition Information</div>
                        <div>
                            <div class="float-right py-1 px-2 text-gray-light cursor-pointer" @click="() => isCollapsed = !isCollapsed">
                                <div class="w-4 h-4 flex">
                                    <icon class="float-right" :icon-type="isCollapsed ? 'plus' : 'minus'" />
                                </div>
                            </div>
                        </div>
                    </column-group>
                    <div v-if="!isCollapsed" class="mt-8">
                        <field-grid-row>
                            <template #leftContent>
                                Signal Name
                            </template>
                            <template #rightContent>
                                {{selectedSignal.name}}
                            </template>
                        </field-grid-row>

                        <field-grid-row>
                            <template #leftContent>
                                Signal Goal
                            </template>
                            <template #rightContent>
                                {{selectedSignal.intent}}
                            </template>
                        </field-grid-row>

                        <field-grid-row>
                            <template #leftContent>
                                Product Description
                            </template>
                            <template #rightContent>
                                {{selectedSignal.description}}
                            </template>
                        </field-grid-row>

                        <field-grid-row>
                            <template #leftContent>
                                Product Benefits
                            </template>
                            <template #rightContent>
                                {{selectedSignal.benefits}}
                            </template>
                        </field-grid-row>

                        <field-grid-row>
                            <template #leftContent>
                                Competitors
                            </template>
                            <template #rightContent>
                                {{selectedSignal.competitors}}
                            </template>
                        </field-grid-row>

                        <field-grid-row>
                            <template #leftContent>
                                Relevant URLs
                            </template>
                            <template #rightContent>
                                <div v-for="url in selectedSignal.urls" :key="url.id" class="custom-margin">
                                    <p>{{url.domain}}</p>
                                    <p class="text-gray">{{url.type}}</p>
                                </div>
                            </template>
                        </field-grid-row>

                        <field-grid-row>
                            <template #leftContent>
                                SEM Keywords
                            </template>
                            <template #rightContent>
                                {{selectedSignal.keywordsText?.join(', ') ?? ''}}
                            </template>
                        </field-grid-row>

                        <field-grid-row>
                            <template #leftContent>
                                Relevant PDFs
                            </template>
                            <template #rightContent>
                                <div v-for="pdf in selectedSignal.pdfs" :key="pdf.id">
                                    <span class="text-sm font-sans font-semibold inline-block float-left rounded-full py-2 px-3 mr-1 text-blue-darkest bg-gray-lightest">
                                        {{pdf.storageLocation.split('/').pop()}}
                                    </span>
                                </div>
                            </template>
                        </field-grid-row>

                        <field-grid-row>
                            <template #leftContent>
                                SEM Keyword CSVs
                            </template>
                            <template #rightContent>
                                <div v-for="keywords in selectedSignal.keywordFiles" :key="keywords.id">
                                    <span class="text-sm font-sans font-semibold inline-block float-left rounded-full py-2 px-3 mr-1 text-blue-darkest bg-gray-lightest">
                                        {{keywords}}
                                    </span>
                                </div>
                            </template>
                        </field-grid-row>
                    </div>
                </div>
            </div>
            <div v-if="!isCollapsed">
                <btn data-id="download-csv-action" color="blue-line" class="float-right mb-4" @click="downloadCsv" :disabled="showDownloadSpinner">
                    <spinner-icon v-if="showDownloadSpinner" width="16" height="16" class="mr-2" />Download CSV</btn>
            </div>
        </div>
        <grid class="pt-4 overflow-x-scroll">
            <template #gridHeaderCells>
                <grid-header-cell column-key="domain" :sortable="false">
                    Domain
                </grid-header-cell>
                <grid-header-cell column-key="companyname" :sortable="false">
                    Company Name
                </grid-header-cell>
                <grid-header-cell column-key="score" :sortable="false">
                    Score
                </grid-header-cell>
                <grid-header-cell column-key="score-label" :sortable="false">
                    Signal Strength
                </grid-header-cell>
                <grid-header-cell column-key="areasofinterest" :sortable="false">
                    Areas of Interest
                </grid-header-cell>
                <grid-header-cell column-key="industry" :sortable="false">
                    Industry
                </grid-header-cell>
                <grid-header-cell column-key="revenue" :sortable="false">
                    Revenue
                </grid-header-cell>
                <grid-header-cell column-key="employeecount" :sortable="false">
                    Employee Count
                </grid-header-cell>
                <grid-header-cell column-key="hqlocation" :sortable="false">
                    HQ Location
                </grid-header-cell>
            </template>
            <template #rows>
                <template v-for="(item, index) in paginated">
                    <grid-row>
                        <grid-cell>
                            {{ item.domain }}
                        </grid-cell>
                        <grid-cell>
                            {{ item.company.name }}
                        </grid-cell>
                        <grid-cell>
                            {{ item.score }}
                        </grid-cell>
                        <grid-cell>
                            {{ item.scoreLabel}}
                        </grid-cell>
                        <grid-cell>
                            {{ item.areasOfInterest.toString() }}
                        </grid-cell>
                        <grid-cell>
                            {{ item.company.industry }}
                        </grid-cell>
                        <grid-cell>
                            {{ item.company.revenue }}
                        </grid-cell>
                        <grid-cell>
                            {{ item.company.size }}
                        </grid-cell>
                        <grid-cell>
                            {{ location(item) }}
                        </grid-cell>
                    </grid-row>
                </template>
            </template>
            <template #footer>
                <grid-pager v-if="numPages > 1"
                            :page-size="pageSize"
                            :total-pages="numPages"
                            :current-page="currentPage"
                            @paged="pagerClicked">
                </grid-pager>
            </template>
        </grid>

        <transition name="fade">
            <message-dialog v-if="showDownloadProgress" @close="closeModal" acceptText="OK" title="Download In Progress" message="We are collecting your Signal Data. It will download shortly.">
            </message-dialog>
        </transition>
        <AlertBox v-model:message="signalAlertMessage"></AlertBox>
    </div>
</template>

<script>
  import {
    ColumnGroup,
    Grid,
    GridHeaderCell,
    GridRow,
    GridCell,
    GridPager,
    LoadingSpinner,
    Icon,
    Btn,
    Modal,
    SpinnerIcon,
  } from "@bombora/component-library"
  import { useVuelidate } from "@vuelidate/core"
  import FieldGridRow from "@/components/shared/FieldGridRow";
  import MessageDialog from "@/components/shared/MessageDialog";
  import PageHeader from "@/components/shared/PageHeader.vue";
  import AlertBox from "@/components/shared/AlertBox.vue";
  import { useSignalsStore } from '@/stores/signalStore'
  import {mapActions, mapWritableState} from "pinia";

  export default {
    name: "Output",
    components: {
      ColumnGroup,
      FieldGridRow,
      Grid,
      GridHeaderCell,
      GridRow,
      GridCell,
      GridPager,
      LoadingSpinner,
      Icon,
      Btn,
      Modal,
      MessageDialog,
      PageHeader,
      AlertBox,
      SpinnerIcon
    },
    setup: () => ({ v$: useVuelidate() }),
    data() {
      return {
        pageSize: 50,
        currentPage: 1,
        pageLoading: true,
        isCollapsed: true,
        showDownloadProgress: false,
        signalAlertMessage: null,
        showDownloadSpinner: false,
      }
    },
    computed: {
      ...mapWritableState(useSignalsStore, ['selectedSignal', 'signalData', 'wizardCanNavigate']),
      numPages() {
        if (this.signalData.length > 0) {
          return Math.ceil(this.signalData.length / this.pageSize)
        }
      },
      paginated() {
        if (this.signalData.length > 0) {
          return this.signalData.slice(
            (this.currentPage - 1) * this.pageSize,
            (this.currentPage - 1) * this.pageSize + this.pageSize
          )
        }
      },
    },
    async mounted() {
      this.wizardCanNavigate = false;
      this.pageLoading = true

      await this.getSignalResults();

      this.pageLoading = false
      this.wizardCanNavigate = true;

    },
    methods: {
      ...mapActions(useSignalsStore, ['upsertSignal', 'fetchSignalResults', 'downloadSignalData']),
      async getSignalResults() {
        let resp = await this.fetchSignalResults(this.selectedSignal.id);
        if (resp.success) {
          this.pageLoading = false
          this.wizardCanNavigate = true;
        } else {
          this.signalAlertMessage = 'Could not retrieve signal data. Please contact support if this issue persists.';
          console.debug(resp.response)
        }
      },
      pagerClicked(pageNum) {
        this.currentPage = pageNum
      },
      async downloadCsv() {
        this.showDownloadProgress = true;
        this.showDownloadSpinner = true;
        let resp = await this.downloadSignalData(this.selectedSignal.id);
        if (resp.success) {
          this.showDownloadProgress = false;
        } else {
          console.error(resp.response);
          this.showDownloadProgress = false;
        }
        this.showDownloadSpinner = false;

      },
      closeModal() {
        this.showDownloadProgress = false;
      },
      location(item) {
        let city = item.company.hq?.city || "";
        let state = item.company.hq?.state || "";
        let country = item.company.hq?.country || "";

        let locationString = [city, state, country].filter(Boolean).join(", ");
        return locationString
      },
    },
  }
</script>
<style lang="css" scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
