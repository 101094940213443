import { defineStore } from 'pinia'
import { apiClient } from "../services/api"
import { NavStateEnum } from "@/constants";
import { unwrapServerResponse } from '@/helpers'


const errorMessages = {
  unauthorizedAction: 'You are not authorized to perform this action.',
  signalCurrentlyProcessing: 'This signal is still processing topics and cannot be deleted now.',
  unknownIssue: 'Unable to delete signal',
  unableToCreateSignal: 'Could not create empty signal for navigation'
};

// TODO REMOVE THIS ONCE MERGE WITH REPO REORG IS DONE
import { useRootStore } from './rootStore'

export const useSignalsStore = defineStore('signals', {
  state: () => ({
    selectedSignal: null,
    selectedSignalData: null,
    companySignals: [],
    signalData: [],
    // TODO rename this to selectedSignalTopics
    topics: [],
    wizardCanNavigate: false
  }),
  actions: {
    async fetchSignal(signalId) {
      let response = await apiClient().get({ url: `/signals/${signalId}` })
      let parsed = unwrapServerResponse(response);
      if (parsed.success) {
        this.selectedSignal = response.data;
      } else {
        console.debug(parsed);
      }
      return parsed;
    },
    // This is for locking the signal object when the definition section is completed and the SignalDef has been created.
    async lockSignal(companyId, signal) {
      // If this call fails, we need to rollback data updates to make sure that the UI is in a consistent state
      let cahcedTopics = signal.topics;
      let cachedNavState = signal.navigationState;
      signal.topics = null;
      // We have completed the Definition phase. Routing should now default to Segmentation
      signal.navigationState = NavStateEnum.Segmentation;
      let resp = await this.upsertSignal(signal);
      // Roll back the updates
      if (!resp.success) {
        signal.topics = cahcedTopics
        signal.navigationState = cachedNavState
      }
      return resp;
    },
    // This is for completing the signal at the end of the wizard.
    async completeSignal(signal) {
      // If this call fails, we need to rollback data updates to make sure that the UI is in a consistent state
      let cachedTempId = signal.tempDefId;
      let cachedNavState = signal.navigationState;
      signal.navigationState = NavStateEnum.Complete;
      // TODO Made in SURGE-5731 Make sure the temporary definition ID is gone
      signal.tempDefId = null;
      let resp = await this.upsertSignal(signal);
      // Roll back the updates
      if (!resp.success) {
        signal.tempDefId = cachedTempId
        signal.navigationState = cachedNavState
      }
      return resp;
    },
    async fetchSignals(companyId, signalId) {
      let response = await apiClient().get({ url: `/${companyId}/signals` });
      let unwrappedResponse = unwrapServerResponse(response)
      if (unwrappedResponse.success) {
        this.companySignals = response.data;
        this.companySignals.sort(function (a, b) {
          return new Date(b.lastUpdated) - new Date(a.lastUpdated)
        })
        // TODO remove this once we figure out why the set on SignalINformation isnt working
        if (signalId) {
          this.selectedSignal = this.companySignals.filter(x => x.id == signalId)[0]
        }
      } else {
        this.companySignals = [];
        console.debug(unwrapServerResponse(response, 'Failed to load your signals.'));
      }
      return unwrappedResponse;
    },
    async upsertSignal(signal) {
      // TODO REMOVE THIS ONCE THE REPO REORG IS MERGED
      const rootStore = useRootStore();
      let response = await apiClient().post({ url: `/${rootStore.company.id}/signals`, data: { signals: [signal] } });
      let unwrappedResponse = unwrapServerResponse(response)
      if (unwrappedResponse.success) {
        this.companySignals = response.data
        this.selectedSignal = response.data.filter(x => x.id == signal.id)[0];
      }
      else if (response.status === 403) {
        return unwrapServerResponse(response, errorMessages.unauthorizedAction);
      } else {
        console.debug(response)
        return unwrapServerResponse(response, errorMessages.unableToCreateSignal);
      }
      return unwrappedResponse;
    },
    async deleteSignal(signalId) {
      let response = await apiClient().delete({ url: `/signals/${signalId}` });
      // Set the error message for the UI alert
      if (response.status === 403) {
        return unwrapServerResponse(response, errorMessages.unauthorizedAction);
      } else if (response.status === 409) {
        return unwrapServerResponse(response, errorMessages.signalCurrentlyProcessing);
      } else {
        return unwrapServerResponse(response);
      }
    },
    async createSignalDef(signalId) {
      var response = await apiClient().post({ url: `/signalTopics/${signalId}` })
      if (response.status === 200) {
        // TODO Implemented in SURGE-5731 (https://bombora.atlassian.net/browse/SURGE-5731)
        // TODO   Due to the current limitations of the definition API, a new signal must be created
        // TODO   to generate topics. If this is the first time topics have been generated for this signal,
        // TODO   it becomes the source of truth. If topics are being RE-generated, this signal is temporary
        if (this.selectedSignal.signalDefId) {
          // The source of truth already exists; this signal is temporary
          this.selectedSignal.tempDefId = response.data.signalDefinitionId;
        } else {
          // This the first time topics have been generated. This becomes the source of truth
          this.selectedSignal.signalDefId = response.data.signalDefinitionId;
          this.selectedSignal.lastSignalUpdate = response.data.updatedTimestamp;
        }
      } else if (response.status === 403) {
        return unwrapServerResponse(response, errorMessages.unauthorizedAction);
      } else {
        console.debug(response);
      }
      return unwrapServerResponse(response);
    },
    async deleteSignalDef(signalId) {
      // TODO SURGE-5731 remove tempOnly flag once definition api changes
      let response = await apiClient().delete({ url: `/signalTopics/${signalId}?tempOnly=false` })
      if (response.status === 200) {
        // Call a secondary mutation
        await this.deleteSignal(signalId);
        return unwrapServerResponse(response);
      } else {
        console.debug(response)
        // Set the error message for the UI alert
        if (response.status === 403) {
          return unwrapServerResponse(response, errorMessages.unauthorizedAction);
        } else if (response.status === 409) {
          return unwrapServerResponse(response, errorMessages.signalCurrentlyProcessing);
        } else {
          return unwrapServerResponse(response, errorMessages.unknownIssue);
        }
      }
    },
    async uploadKeywords(signalId, values) {
      let response = await apiClient().postForm({ url: `/files/${signalId}/keywords`, data: values })
      let unwrappedResponse = unwrapServerResponse(response)
      if (!unwrappedResponse.success) {
        console.debug(response)
      }
      return unwrappedResponse;

    },
    async deleteKeywords(signalId, fileName) {
      let response = await apiClient().delete({ url: `/files/${signalId}/keywords/${fileName}` })
      let unwrappedResponse = unwrapServerResponse(response)
      if (!unwrappedResponse.success) {
        console.debug(response)
      }
      return unwrappedResponse;
    },
    async uploadPdfs(signalId, values) {
      let response = await apiClient().postForm({ url: `/files/${signalId}/pdfs`, data: values })
      let unwrappedResponse = unwrapServerResponse(response)
      if (unwrappedResponse.success) {
        if (this.selectedSignal.pdfs == null) {
          this.selectedSignal.pdfs = [];
        }
        response.data.forEach(f => {
          this.selectedSignal.pdfs.push(f);
        })
      }
      return unwrappedResponse;
    },
    async deletePdf(signalId, fileId) {
      let response = await apiClient().delete({ url: `/files/${signalId}/pdfs/${fileId}` })
      let unwrappedResponse = unwrapServerResponse(response)
      if (!unwrappedResponse.success) {
        console.debug(response)
        }
        return unwrappedResponse;
    },
    async getSignalData(signalId) {
      let response = await apiClient().get({ url: `/signalData/${signalId}` })
      if (response.status === 200) {
        this.selectedSignalData = response.data
      } else {
        console.debug(response)
        return response
      }
      return unwrappedResponse;
    },
    async fetchSignalResults(signalId) {
      let response = await apiClient().get({ url: `/signalData/${signalId}` })
      let unwrappedResponse = unwrapServerResponse(response)
      if (unwrappedResponse.success) {
        this.signalData = response.data.data
      }
      return unwrappedResponse;
    },
    async downloadSignalData(signalId) {
      let response = await apiClient().get({ url: `/signalData/${signalId}/download`, responseType: 'arraybuffer' });
      let unwrappedResponse = unwrapServerResponse(response);
      if (unwrappedResponse.success) {
        const blob = new Blob([response.data], { type: 'text/csv' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `SignalOutput-${new Date().toISOString().split('T')[0]}.csv`
        link.click()
        URL.revokeObjectURL(link.href)
      }
      return unwrappedResponse
    },
    async fetchSignalDefResponse(signalId) {
      let response = await apiClient().get({ url: `/signalTopics/${signalId}` })
      let unwrappedResponse = unwrapServerResponse(response)
      if (unwrappedResponse.success) {
        this.topics = response.data.topics;
      }
      return unwrappedResponse;
    },
    async updateSignalDef(signalId, values) {
      let response = await apiClient().put({ url: `/signalTopics/${signalId}`, data: values });
      return unwrapServerResponse(response);
    },
    async deleteTempSignalDef(signalId) {
      let resp = await apiClient().delete({ url: `/signalTopics/${signalId}?tempOnly=true` })
      if (resp.status === 200) {
        this.selectedSignal.tempDefId = null;
        await this.upsertSignal(this.selectedSignal)
      }
    }
  },
})
