<template>
    <div class="mb-8">
        <div v-if="uploading || loading" class="absolute bg-white bg-opacity-60 z-10 h-full w-full flex items-center justify-center">
            <div class="flex items-center">
                <loading-spinner />
            </div>
        </div>
        <div v-if="!loading">
            <page-header title="Exclude accounts from this Signal" class="mb-8">
                <template v-slot>
                    In the Signal output, let us know if you'd like to exclude or suppress certain accounts from being returned.
                    Examples: current customers, accounts already in pipeline, partner accounts, etc.
                </template>
            </page-header>
            <div class="main flex flex-column pb-10">
                <div data-id="segmentation-filtering-yes-string"
                     class="flex px-8 py-5 rounded-sm border-gray-light border mb-3 cursor-pointer hover:bg-blue hover:text-white flex-child:1 w-2/4 mr-2.5	"
                     :class="selectedSignal.netNewAccounts === 'yes' ? 'bg-blue text-white' : 'bg-blue-light text-blue-gray-dark'"
                     @click="setSegmentFilter('yes')">
                    <div class="left">
                        <div class="font-semibold text-med">Suppress accounts</div>
                    </div>
                </div>
                <div data-id="segmentation-filtering-no-string"
                     class="flex px-8 py-5 rounded-sm border-gray-light border mb-3 cursor-pointer hover:bg-blue hover:text-white flex-child:1 w-2/4 ml-2.5	"
                     :class="selectedSignal.netNewAccounts === 'no' ? 'bg-blue text-white' : 'bg-blue-light text-blue-gray-dark'"
                     @click="setSegmentFilter('no')">
                    <div class="right">
                        <div class="font-semibold text-med">Skip (do not exclude accounts)</div>
                    </div>
                </div>
            </div>
            <transition name="fade">
                <div v-if="selectedSignal.netNewAccounts === 'yes'">
                    <titled-section class="pb-4">
                        <template v-slot:title>
                            Please select an existing account list or upload a new one
                        </template>
                        <template v-slot:subtitle>
                        </template>
                    </titled-section>
                    <p class="pb-4">Uploaded files must follow the following template: <a class="text-blue-gray-dark font-medium hover:underline" href="https://brand-assets.bombora.com/signal-creator/Account_List_Upload_Template.csv" target="_blank">Download Account List Template</a>.</p>
                    <titled-section class="pb-2">
                        <file-drop-zone data-id="csv-upload-action" @updated="onFileUploaded" :accept="'.csv'">
                            <div class="w-full h-full grid place-content-center">
                                <div class="w-8 h-8 place-self-center">
                                    <img height="27" width="24" src="@/assets/arrow-up.svg" alt="Upload" />
                                </div>
                                <p class="text-dark-gray">Click to upload or drag and drop your CSV or XLSX files</p>
                            </div>
                        </file-drop-zone>
                        <alert-box v-model:message="uploadFailureAlertMessage"></alert-box>
                        <alert-box v-model:message="uploadSuccessAlertMessage" :type="'success'"></alert-box>
                    </titled-section>
                    <titled-section class="pb-12">
                        <template v-slot:title>
                            Select an Account List
                        </template>
                        <div v-if="accountListLoading">
                            <div class="flex items-center">
                                <loading-spinner />
                            </div>
                        </div>
                        <grid class="pb-2">
                            <template #rows>
                                <grid-row v-for="list in paginatedLists"
                                          :key="list"
                                          :class="['cursor-pointer', selectedSignal.accountList != null && selectedSignal.accountList.includes(list.id) ? 'bg-blue-light text-blue' : 'bg-white text-blue-gray-dark']">
                                    <grid-cell @click="toggleAccountList(list.id)">
                                        <input data-id="select-account-list-action" type="checkbox" :value="list.id" v-model="selectedSignal.accountList" class="mr-5" />
                                        {{ list.name }}
                                    </grid-cell>
                                </grid-row>
                            </template>
                            <template #footer>
                                <grid-pager data-id="account-list-pager" v-if="numPages > 1" :total-pages="numPages" :current-page="currentPage" @paged="pagerClicked"></grid-pager>
                            </template>
                        </grid>
                    </titled-section>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>

    import { Grid, GridCell, GridPager, GridRow, LoadingSpinner } from "@bombora/component-library";
    import AlertBox from "@/components/shared/AlertBox.vue";
    import TitledSection from "@/components/shared/TitledSection.vue";
    import FileDropZone from "@/components/shared/FileDropZone.vue";
    import PageHeader from "@/components/shared/PageHeader.vue";
    import { useSignalsStore } from '@/stores/signalStore'
    import { useRootStore } from '@/stores/rootStore'

    import { useVuelidate } from "@vuelidate/core";
    import { mapState, mapWritableState, mapActions } from 'pinia'

    export default {
        name: "Filtering",
        components: {
            LoadingSpinner,
            Grid,
            GridCell,
            GridPager,
            GridRow,
            AlertBox,
            TitledSection,
            FileDropZone,
            PageHeader
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                uploadSuccessAlertMessage: null,
                uploadFailureAlertMessage: null,
                currentPage: 1,
                pageSize: 10,
                paginatedLists: [],
                uploading: false,
                accountListLoading: false,
            }
        },
        computed: {
            ...mapWritableState(useSignalsStore, ['selectedSignal', 'wizardCanNavigate']),
            ...mapState(useRootStore, ['company', 'entityId', 'userEmail', 'accountLists']),
            numPages() {
                return Math.ceil(this.accountLists.length / this.pageSize);
            },
            paginatedLists() {
                return this.accountLists.slice((this.currentPage - 1) * this.pageSize, ((this.currentPage - 1) * this.pageSize) + this.pageSize)
            },
        },
        async created() {
            this.wizardCanNavigate = false

            if (this.selectedSignal.accountList === null) {
                this.selectedSignal.accountList = [];
            }

            if (this.selectedSignal.netNewAccounts) {
                if (this.selectedSignal.netNewAccounts === 'no' || this.selectedSignal.accountList.length !== 0) {
                    this.wizardCanNavigate = true
                }
            }

            await this.getAccountLists();
        },
        methods: {
            ...mapActions(useSignalsStore, ['upsertSignal']),
            ...mapActions(useRootStore, ['fetchAccountLists', 'uploadAccountList']),
            async getAccountLists() {
                this.accountListLoading = true;
                let resp = await this.fetchAccountLists(this.entityId);
                if (!resp.success) {
                    // TODO UI ERROR
                }
                this.accountListLoading = false;
            },
            async setSegmentFilter(value) {
                this.selectedSignal.netNewAccounts = value;
                //if you select to choose net new accounts, this clears out your selection from the AccountList page
                if (this.selectedSignal.netNewAccounts === 'yes') {
                    this.selectedSignal.useBomboraFilters = ''
                    this.wizardCanNavigate = this.selectedSignal.accountList.length > 0;
                }
                //if we are not choosing net new accounts, then set the account list = null
                if (this.selectedSignal.netNewAccounts === 'no') {
                    this.selectedSignal.accountList = [];
                    this.wizardCanNavigate = true
                }

                await this.saveSignal()
            },
            async toggleAccountList(listId) {
                // Only allow one selection at a time
                this.selectedSignal.accountList = [listId];
                this.wizardCanNavigate = this.selectedSignal.accountList.length !== 0
            },
            async saveSignal() {
                let status = await this.upsertSignal(this.selectedSignal);
                // TODO UI ERROR (and maybe stop navigation)
            },
            async onFileUploaded(files) {
                this.uploading = true;

                const formData = new FormData();
                files.forEach(csv => {
                    formData.append("files", csv, csv.name)
                })

                let userEmail = this.userEmail ?? this.$auth.user.value.email;
                formData.append("userEmail", userEmail)

                let resp = await this.uploadAccountList(this.entityId, formData);
                this.uploading = false;
                if (!resp.success) {
                    this.uploadFailureAlertMessage = resp.message;
                } else {
                    this.uploadSuccessAlertMessage = 'Upload successful';
                }
            },
            pagerClicked(pageNum) {
                this.currentPage = pageNum;
            }
        },
    }


</script>


<style scoped>
</style>
