<template>
  <div>
    <div v-if="pageLoading"
         class="absolute bg-white bg-opacity-60 z-10 h-full w-full flex items-center justify-center">
      <div class="flex items-center">
        <loading-spinner />
      </div>
    </div>
    <div>
      <page-header title="Provide keywords to curate Intent topics" class="mb-8">
        <template v-slot>
          Upload your search engine keywords and the Signal Creator will translate them into a condensed list of Intent topics.
          Note: Intent topics are not keywords, so you will not receive the same number of topics as keywords you provide.
          Tip: If this Signal is for a specific product or audience, only upload the keywords that reflect that product or audience.
        </template>
      </page-header>
      <titled-section class="pb-10">
        <template v-slot:title>
          Upload Keyword List
        </template>
        <template v-slot:subtitle>
          .CSV files must have keywords in the first column
        </template>

        <file-drop-zone data-id="keyword-upload-action" @updated="onFileUploaded" :accept="'.csv'">
          <div class="w-full h-full grid place-content-center">
            <div class="w-8 h-8 place-self-center">
              <img height="27" width="24" src="@/assets/arrow-up.svg" alt="Upload" />
            </div>
            <p class="text-dark-gray">Click to upload or drag and drop your CSV files</p>
          </div>
        </file-drop-zone>

        <template v-for="(file, idx) in files" :key="file.name">
          <div class="flex items-center mb-3">
            <text-field v-model="file.name" :is-disabled="true" disabled class="mr-2 flex-1" />
            <div name="file-delete" @click="deleteFile(file.name)" class="flex flex-0 items-center justify-around w-4 h-4 cursor-pointer">
                <icon icon-type="trash" icon-color="#044B65" alt="Delete Keyword File"></icon>
            </div>
          </div>
        </template>
      </titled-section>

      <titled-section>
        <template v-slot:title>
          Enter keywords
        </template>
        <template v-slot:subtitle>
          Enter each keyword on a new line.
        </template>

        <textarea @blur="onBlur"
                  @focusout="onBlur"
                  @keyup="updateNavState"
                  class="border border-gray-light rounded-sm w-full h-64 resize-none text-sm p-2"
                  placeholder="Enter your keywords here"
                  v-model="keywordList" />
      </titled-section>
    </div>
    <AlertBox v-model:message="saveCsvAlertMessage"></AlertBox>
    <AlertBox v-model:message="deleteCsvAlertMessage"></AlertBox>
  </div>
</template>

<script>
  import { ColumnGroup, Dropdown, TextField, Btn, Icon, LoadingSpinner } from "@bombora/component-library";
  import TitledSection from "@/components/shared/TitledSection.vue";
  import FileDropZone from '@/components/shared/FileDropZone.vue';
  import AlertBox from "@/components/shared/AlertBox.vue";
  import PageHeader from "@/components/shared/PageHeader.vue";

  import { mapWritableState, mapActions } from 'pinia'
  import { useSignalsStore } from '@/stores/signalStore'

  export default {
    name: "DefinitionUploadKeywords",
    components: {
      AlertBox,
      ColumnGroup,
      Dropdown,
      FileDropZone,
      TextField,
      Btn,
      Icon,
      TitledSection,
      LoadingSpinner,
      PageHeader
    },
    data() {
      return {
        pageLoading: false,
        files: [],
        keywords: '',
        saveCsvAlertMessage: null,
        deleteCsvAlertMessage: null,
      }
    },
    computed: {
      ...mapWritableState(useSignalsStore, ['selectedSignal', 'wizardCanNavigate']),
      keywordList: {
        get() {
          return this.selectedSignal.keywordsText?.join('\n') ?? '';
        },
        set(newValue) {
          this.selectedSignal.keywordsText = newValue.split('\n')
        }
      }
    },
    async created() {
      // No required inputs
      this.wizardCanNavigate = false;
      // Populate submission data
      if (this.selectedSignal.keywordFiles) {
        this.files = this.selectedSignal.keywordFiles.map(x => ({ name: x, existing: true }));
      }
      await this.updateNavState();
    },
    methods: {
      ...mapActions(useSignalsStore, ['uploadKeywords', 'deleteKeywords', 'upsertSignal']),
      async saveSignal() {
        let status = await this.upsertSignal(this.selectedSignal);
        document.activeElement.blur()
      },
      async onFileUploaded(files) {
        // only push new files to uploadkeywords.
        // if same file is pushed to uploadkeywords it results in an error
        let newFiles = [];
        files.forEach(csv => {
          if (!csv.existing) {
            if (this.files.filter(x => x.name === csv.name).length === 0) {
              this.files.push({ name: csv.name, existing: true })
              newFiles.push(csv)
            }
          }
        })
        if (newFiles.length > 0) {
          await this.saveKeywords(newFiles);
        }
        await this.updateNavState();
        await this.saveSignal();
      },
      async saveKeywords(files) {
        const formData = new FormData();
        files.forEach(csv => {
          if (!csv.existing) {
            formData.append("files", csv, csv.name)
          }
        })

        this.pageLoading = true;

        let response = await this.uploadKeywords(this.selectedSignal.id, formData);
        if (!response.success) {
          // remove file from files object
          // TODO this is not ideal, we are managing our own state outside the store in this component
          files.forEach(csv => {
            this.files = this.files.filter(x => x.name != csv.name)
          })
          this.saveCsvAlertMessage = 'Csv was unable to save.';
        }
        this.pageLoading = false;
      },
      async deleteFile(fileName) {
        this.pageLoading = true;
        let response = await this.deleteKeywords(this.selectedSignal.id, fileName)
        if (!response.success) {
          this.deleteCsvAlertMessage = 'Csv was unable to be deleted.';
          console.error(response.response)
        } else {
          // remove item from array
          // TODO this is not ideal, we are managing our own state outside the store in this component
          this.files = this.files.filter(x => x.name != fileName);
        }
        this.pageLoading = false;
        await this.updateNavState();
        await this.saveSignal();
      },
      async updateNavState() {
        var text = this.selectedSignal?.keywordsText?.map(function (keyword) {
          return keyword.trim();
        })
        var keywordsExist = this.files.length > 0 || text.join("").length > 0;
        var pdfsExist = this.selectedSignal?.pdfs?.length > 0;
        var urlsExist = this.selectedSignal?.urls?.length > 0;
        this.wizardCanNavigate = (keywordsExist || pdfsExist || urlsExist);
      },
      async onBlur() {
        await this.updateNavState();
        await this.saveSignal();
      },
    }
  }
</script>

<style>
  .b-dropzone {
    cursor: pointer;
  }

  .b-dropMessage {
    @apply w-full h-full;
  }
</style>
