<template>
    <div class="mb-8">
        <page-header title="Benefits, pain points and needs that your product(s) supports" class="mb-8">
          <template v-slot>
            Provide a list of benefits or other attributes of this product, service, or solution that are unique to this audience.
          </template>
        </page-header>
        <div>
            <textarea @blur="onBlur"
                      @focusout="onBlur"
                      @keyup="updateNavState"
                      name="signal.benefits"
                      class="border-2 rounded-md border-gray-light h-60 w-full p-2"
                      placeholder="How does this product benefit the end user and the market ecosystem?"
                      v-model="selectedSignal.benefits" />
        </div>
        <FieldErrorMessage :field="v$.selectedSignal.benefits"></FieldErrorMessage>
  </div>

</template>

<script>
  import {
    Alert,
    Breadcrumbs,
    Btn,
    Card,
    Grid,
    GridRow,
    GridCell,
    GridHeaderCell,
    GridPager,
    Icon,
    LoadingSpinner,
  } from "@bombora/component-library"
  import ConfirmDialog from "@/components/shared/ConfirmDialog.vue"
  import FieldErrorMessage from "@/components/shared/FieldErrorMessage.vue"
  import PageHeader from "@/components/shared/PageHeader.vue";

  import { useVuelidate } from '@vuelidate/core'
  import { maxLength, helpers } from '@vuelidate/validators'
  import { validText } from "@/utils/validText";
  import { mapWritableState, mapActions } from 'pinia'
  import { useSignalsStore } from '@/stores/signalStore'

  export default {
    name: "DefinitionBenefits",
    components: {
      Alert,
      Breadcrumbs,
      Btn,
      ConfirmDialog,
      Card,
      FieldErrorMessage,
      Grid,
      GridRow,
      GridCell,
      GridHeaderCell,
      GridPager,
      Icon,
      LoadingSpinner,
      PageHeader
    },
    setup: () => ({ v$: useVuelidate() }),
    computed: {
      ...mapWritableState(useSignalsStore, ['selectedSignal', 'wizardCanNavigate']),
    },
    validations() {
      return {
        selectedSignal: {
          benefits: {
            maxLength: helpers.withMessage('Your benefits must be less than 1000 characters.', maxLength(1000)),
            validText: helpers.withMessage('The benefits must only contain letters, numbers, spaces, and punctuation.', helpers.regex(validText)),
          },
        }
      }
    },
    async mounted() {
      this.wizardCanNavigate = true;
      if (this.selectedSignal.benefits && this.selectedSignal.benefits.length > 0) {
        this.updateNavState();
      }
    },
    methods: {
      ...mapActions(useSignalsStore, ['upsertSignal']),
      async onBlur() {
        await this.updateNavState();
        await this.saveSignal();
      },
      async saveSignal() {
        if (this.selectedSignal.benefits && this.selectedSignal.benefits.length > 0)
          this.selectedSignal.benefits = this.selectedSignal.benefits.trim();
        let status = await this.upsertSignal(this.selectedSignal);
        document.activeElement.blur()
      },
      async updateNavState() {
        const isFormCorrect = await this.v$.$validate()
        this.wizardCanNavigate = isFormCorrect;
      }
    },
  }
</script>

<style lang="css" scoped>
</style>
