<template>
  <div>
    <div class="text-xl font-medium mb-2 text-blue-gray-dark">
      <slot name="title"></slot>
    </div>
    <div class="text-sm mb-4 text-blue-gray-dark">
      <slot name="subtitle"></slot>
    </div>
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: "TitledSection",
    components: { },
  };
</script>

<style lang="css" scoped>
</style>
