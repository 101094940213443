import { defineStore } from 'pinia'
import { apiClient } from "../services/api"
import { unwrapServerResponse } from '@/helpers'

export const useRootStore = defineStore('root', {
  state: () => ({
    headerCrumb: null,
    userEmail: null,
    company: null,
    // TODO rethink this name, it is the provided entityid not the expected company id
    entityId: null,
    unauthorized: true,
    companyFetched: false,
    accountLists: [],
  }),
  actions: {
    async getCompany(id) {
      // TODO refactor this, but want to keep the idea around
      if (this.company?.id == id) {
        return;
      }
      let response = await apiClient().get({ url: `/companyinfo/${id}` });
      let unwrappedResponse = unwrapServerResponse(response);
      if (unwrappedResponse.success) {
        // Check permissions for this user
        if (response.data.entityId == this.entityId) {
          this.company = response.data;
          this.unauthorized = false;
        } else {
          this.company = null;
        }
        // Update the header
        this.headerCrumb = this.company.name;
      } else {
        this.company = null;
        console.debug(unwrapServerResponse(response, `Could not load company based on entity id ${this.entityId}`));
      }
      return unwrappedResponse;
    },
    async fetchCompany(entityid) {
      this.entityId = entityid;
      this.companyFetched = false;
        if(!entityid)
        {
            // This user has no entity id - allow the app to load and this store to initialize, but don't load data
            this.entityid = 0;
            this.company = { id: 0 };
            this.unauthorized = true;
            this.companyFetched = true;
            return true;
        }
      let response = await apiClient().get({ url: `/companyinfo/entity/${this.entityId}` });
      let unwrappedResponse = unwrapServerResponse(response);
      if (unwrappedResponse.success) {
        if (response.data.entityId == this.entityId) {
          this.company = response.data;
          this.unauthorized = false;
        } else {
          this.company = null;
        }
      } else {
        console.debug(unwrapServerResponse(response, `Could not load company based on entity id ${this.entityId}`));
        this.company = null;
      }
      this.companyFetched = true;
      return unwrappedResponse;
    },
    async fetchAccountLists(entityId) {
      let response = await apiClient().get({ url: `/accountlists/${entityId}` })
      let unwrappedResponse = unwrapServerResponse(response);
      if (unwrappedResponse.success) {
        this.accountLists = response.data;
      } else {
        console.debug(unwrappedResponse);
      }
      return unwrappedResponse;
    },
    async uploadAccountList(entityId, formData) {
      let response = await apiClient().post({ url: `/accountlists/${entityId}`, data: formData });
      let unwrappedResponse = unwrapServerResponse(response);
      if (unwrappedResponse.success) {
        await this.fetchAccountLists(entityId);
      }
      return unwrappedResponse;
    }
  }
});
