<template>
  <div>
    <div class="mb-8">
      <wizard-side-nav title="1. Signal Definitions"
                       subtitle="Your products and services"
                       :is-active="isSectionActive('Definition')"
                       :is-complete="isSectionComplete('Definition')"
                       @on-click="navigationRequest(wizardRouteNames.Goals)">
        <wizard-side-nav-item data-id="wizard-section-1-step-1-navigate"
                              title="Purpose of the Signal"
                              :is-active="isItemActive(wizardRouteNames.Goals)"
                              :is-complete="isItemComplete(wizardRouteNames.Goals)"
                              @on-click="navigationRequest(wizardRouteNames.Goals)"></wizard-side-nav-item>
        <wizard-side-nav-item data-id="wizard-section-1-step-2-navigate"
                              title="Product / Service Descriptions"
                              :is-active="isItemActive(wizardRouteNames.Descriptions)"
                              :is-complete="isItemComplete(wizardRouteNames.Descriptions)"
                              @on-click="navigationRequest(wizardRouteNames.Descriptions)"></wizard-side-nav-item>
        <wizard-side-nav-item data-id="wizard-section-1-step-3-navigate"
                              title="Product / Service Benefits"
                              :is-active="isItemActive(wizardRouteNames.Benefits)"
                              :is-complete="isItemComplete(wizardRouteNames.Benefits)"
                              @on-click="navigationRequest(wizardRouteNames.Benefits)"></wizard-side-nav-item>
        <wizard-side-nav-item data-id="wizard-section-1-step-4-navigate"
                              title="Competitors"
                              :is-active="isItemActive(wizardRouteNames.Competitors)"
                              :is-complete="isItemComplete(wizardRouteNames.Competitors)"
                              @on-click="navigationRequest(wizardRouteNames.Competitors)"></wizard-side-nav-item>
        <wizard-side-nav-item data-id="wizard-section-1-step-5-navigate"
                              title="URLs & Documents"
                              :is-active="isItemActive(wizardRouteNames.UploadUrls)"
                              :is-complete="isItemComplete(wizardRouteNames.UploadUrls)"
                              @on-click="navigationRequest(wizardRouteNames.UploadUrls)"></wizard-side-nav-item>
        <wizard-side-nav-item data-id="wizard-section-1-step-6-navigate"
                              title="Keywords"
                              :is-active="isItemActive(wizardRouteNames.UploadKeywords)"
                              :is-complete="isItemComplete(wizardRouteNames.UploadKeywords)"
                              @on-click="navigationRequest(wizardRouteNames.UploadKeywords)"></wizard-side-nav-item>
      </wizard-side-nav>
    </div>

    <div class="mb-8">
      <wizard-side-nav title="2. Segmentation Data"
                       subtitle="Upload a list or choose parameters for the target audience for this Signal"
                       :is-active="isSectionActive('Segmentation')"
                       :is-complete="isSectionComplete('Segmentation')"
                       @on-click="navigationRequest(wizardRouteNames.Filtering)">
        <wizard-side-nav-item data-id="wizard-section-2-step-1-navigate" title="Exclude Accounts"
                              :is-active="isItemActive(wizardRouteNames.Filtering)"
                              :is-complete="isItemComplete(wizardRouteNames.Filtering)"
                              @on-click="navigationRequest(wizardRouteNames.Filtering)"></wizard-side-nav-item>
        <wizard-side-nav-item data-id="wizard-section-2-step-2-navigate" title="Target Accounts"
                              :is-active="isItemActive(wizardRouteNames.AccountList)"
                              :is-complete="isItemComplete(wizardRouteNames.AccountList)"
                              @on-click="navigationRequest(wizardRouteNames.AccountList)"></wizard-side-nav-item>
        <wizard-side-nav-item data-id="wizard-section-2-step-3-navigate" title="Company Size"
                              :is-active="isItemActive(wizardRouteNames.AudienceSize)"
                              :is-complete="isItemComplete(wizardRouteNames.AudienceSize)"
                              @on-click="navigationRequest(wizardRouteNames.AudienceSize)"></wizard-side-nav-item>
        <wizard-side-nav-item data-id="wizard-section-2-step-4-navigate" title="Company Revenue"
                              :is-active="isItemActive(wizardRouteNames.Profitability)"
                              :is-complete="isItemComplete(wizardRouteNames.Profitability)"
                              @on-click="navigationRequest(wizardRouteNames.Profitability)"></wizard-side-nav-item>
        <wizard-side-nav-item data-id="wizard-section-2-step-5-navigate" title="Target Industries"
                              :is-active="isItemActive(wizardRouteNames.Industries)"
                              :is-complete="isItemComplete(wizardRouteNames.Industries)"
                              @on-click="navigationRequest(wizardRouteNames.Industries)"></wizard-side-nav-item>
        <wizard-side-nav-item data-id="wizard-section-2-step-6-navigate" title="Target Geographies"
                              :is-active="isItemActive(wizardRouteNames.Geographies)"
                              :is-complete="isItemComplete(wizardRouteNames.Geographies)"
                              @on-click="navigationRequest(wizardRouteNames.Geographies)"></wizard-side-nav-item>
      </wizard-side-nav>
    </div>

    <div class="mb-8">
      <wizard-side-nav title="3. Review Signal Output" subtitle="Review your topics and view your Signal"
                       :is-active="isSectionActive('Review')" :is-complete="isSectionComplete('Review')"
                       @on-click="navigationRequest(wizardRouteNames.Topics)">
        <wizard-side-nav-item data-id="wizard-section-3-step-1-navigate" title="Review the Intent Topics"
                              :is-active="isItemActive(wizardRouteNames.Topics)"
                              :is-complete="isItemComplete(wizardRouteNames.Topics)"
                              @on-click="navigationRequest(wizardRouteNames.Topics)"></wizard-side-nav-item>
        <wizard-side-nav-item data-id="wizard-section-3-step-2-navigate" title="Schedule Delivery"
                              :is-active="isItemActive(wizardRouteNames.Delivery)"
                              :is-complete="isItemComplete(wizardRouteNames.Delivery)"
                              @on-click="navigationRequest(wizardRouteNames.Delivery)"></wizard-side-nav-item>
        <wizard-side-nav-item data-id="wizard-section-3-step-3-navigate" title="Review Signal Output"
                              :is-active="isItemActive(wizardRouteNames.Output)"
                              :is-complete="isItemComplete(wizardRouteNames.Output)"
                              @on-click="navigationRequest(wizardRouteNames.Output)"></wizard-side-nav-item>
      </wizard-side-nav>
    </div>
  </div>
</template>

<script>
  import WizardSideNav from "@/components/shared/WizardSideNav.vue"
  import WizardSideNavItem from "@/components/shared/WizardSideNavItem.vue"
  import { wizardRouteNames, wizardSectionMap } from '@/router/routerConstants';
  import { NavStateEnum } from '@/constants';

  export default {
    name: "WizardNavigator",
    components: {
      WizardSideNav,
      WizardSideNavItem
    },
    emits: ['on-navigation-request'],
    props: ['selectedSignal'],
    data() {
      return {
        wizardRouteNames: wizardRouteNames
      }
    },
    computed: {
        currentRouteName() {
        return this.$router.currentRoute.value.name
      },
    },
    methods: {
      navigationRequest(routeName) {
        if (this.isItemComplete(routeName)) {
          this.$emit('on-navigation-request', routeName);
        }
      },
      isSectionActive(sectionKey) {
        const sectionIndexes = Object.keys(wizardSectionMap);
        // Get the section index for the current route
        for (let i = 0; i < sectionIndexes.length; i++) {
          let sectionName = sectionIndexes[i];
          let section = wizardSectionMap[sectionName]
          if (sectionName == sectionKey && section.includes(this.currentRouteName)) {
            // We are in this section
            return true;
          }
        }
        return false
      },
      isSectionComplete(sectionKey) {
        // If the use has already gone through the wizard once and all sections should be complete.
        // The nav state complete will only exist on an Active signal, and completed pages will only have items
        // if the user has navigated the wizard once before. Therefore, any nav state other than Complete in
        // combination with completed pages having all the pages in it denotes a signal in edit mode.
        if (this.selectedSignal?.navigationState !== NavStateEnum.Complete
          && this.selectedSignal?.completedPages
          && this.selectedSignal.completedPages?.length === Object.keys(this.wizardRouteNames).length) {
          return true;
        }
          const sectionIndexes = Object.keys(wizardSectionMap);
        // Get the section index for the currently rendering nav item
        let renderingPosition = sectionIndexes.indexOf(sectionKey);
        let currentPosition = 0;
        // Get the section index for the current route
        for (let i = 0; i < sectionIndexes.length; i++) {
          let section = wizardSectionMap[sectionIndexes[i]]
          if (section.includes(this.currentRouteName)) {
            // We are in this section
            currentPosition = i
          }
        }
        // If the step we are rendering is after (greater than) the current position, we have a pending step
        return renderingPosition < currentPosition;
      },
      isItemActive(routeName) {
        // If the current route name is the one we are testing, then this is the active page
        return routeName == this.currentRouteName;
      },
      isItemComplete(routeName) {
        if (this.selectedSignal?.completedPages?.includes(routeName)) {
            return true
        }
        const routeIndexes = Object.keys(wizardRouteNames);
        let pastRoutePosition = routeIndexes.indexOf(routeName) < routeIndexes.indexOf(this.currentRouteName)
        // The route is considered complete if we have existing data for it, or it is a route previous to the current one in the ordering.
        // Some steps don't have required data
        let dataExists = false
        let considerDataExistance = false
        switch (routeName) {
          case wizardRouteNames.Goals:
            dataExists = !!this.selectedSignal.intent
            considerDataExistance = true
            break;
          case wizardRouteNames.Descriptions:
            dataExists = !!this.selectedSignal.description
            considerDataExistance = true
            break;
          case wizardRouteNames.Benefits:
            dataExists = !!this.selectedSignal.benefits
            considerDataExistance = true
            break;
          case wizardRouteNames.Competitors:
            dataExists = !!this.selectedSignal.competitors
            considerDataExistance = true
            break;
          case wizardRouteNames.UploadUrls:
            dataExists = this.selectedSignal?.urls?.length > 0 || this.selectedSignal?.pdfs?.length > 0
            considerDataExistance = true
            break;
          case wizardRouteNames.UploadKeywords:
            dataExists = this.selectedSignal?.keywordFiles?.length > 0 || this.selectedSignal?.keywordsText?.length > 0
            considerDataExistance = true
            break;
          case wizardRouteNames.Filtering:
            dataExists = this.selectedSignal?.netNewAccounts?.length > 0
            considerDataExistance = true
            break;
          case wizardRouteNames.AccountList:
            dataExists = this.selectedSignal?.netNewAccounts == "yes" ? true : this.selectedSignal?.useBomboraFilters?.length > 0
            considerDataExistance = true
            break;
          case wizardRouteNames.AudienceSize:
            dataExists = this.selectedSignal?.audienceSizeList?.length > 0
            considerDataExistance = true
            break;
          case wizardRouteNames.Profitability:
            dataExists = this.selectedSignal?.profitability?.length > 0
            considerDataExistance = true
            break;
          case wizardRouteNames.Geographies:
            dataExists = this.selectedSignal?.geography?.length > 0
            considerDataExistance = true
            break;
          case wizardRouteNames.Industries:
            dataExists = this.selectedSignal?.industry?.length > 0
            considerDataExistance = true
            break;
          case wizardRouteNames.Delivery:
            dataExists = this.selectedSignal?.deliveryEmails?.length > 0
            considerDataExistance = true
            break;

        }
        return (considerDataExistance && dataExists) || pastRoutePosition;
      },
    }
  }
</script>

<style>
</style>
