<template>
    <div class="mb-8">
        <page-header title="What is your geographical focus?" class="mb-8">
            <template v-slot>
            </template>
        </page-header>
        <div class="w-full p-2">
            <div class="flex min-w-input border rounded-tl rounded-tr border-gray-light h-10" v-click-outside="closeSearch">
                <span class="inline-flex items-center pl-3 pr-3 text-sm text-gray border-r border-blue-gray"><icon icon-type="spyglass" class="w-3 h-3"></icon></span>
                <input class="flex-1 w-full pl-3" type="text" v-model="this.searchValue" placeholder="Type to add geographies">
                <aside class="absolute z-10 flex flex-col items-start w-64 bg-white border border-gray-light rounded mt-10 ml-9"
                       role="menu" aria-labelledby="menu-heading" v-if="searchList.length > 0">
                    <ul class="flex flex-col w-full">
                        <li class="px-2 py-3 space-x-2 hover:bg-blue-light focus:bg-blue-light focus:outline-none cursor-pointer"
                            v-for="(item, index) in searchList"
                            :key="index"
                            @click="selectSearchItem(item); closeSearch();">{{ item.name }}</li>
                    </ul>
                </aside>
            </div>


            <div class="border-r border-l border-b border-gray-light bg-blue-light p-2">
                <span class="pr-1">Quick Select:</span>
                <div class="inline">
                    <template v-for="quick in Object.keys(this.regions)" :key="quick">
                        <span class="quick-select text-blue pr-1 cursor-pointer" @click="selectRegion(this.regions[quick])">
                            {{quick}}
                        </span>
                    </template>
                </div>
                <btn color="gray" size="small" class="float-right" @click="clearSelection">Clear</btn>
            </div>

            <transition name="fade">
                <div v-if="selectedItems.length > 0" class="border-r border-l border-b border-gray-light p-1 mb-10">
                    <ul class="list-none">
                        <transition name="fade">
                            <li v-if="groupedSelectedItems.countries.length > 0">
                                <div class="block text-gray mx-2">Country</div>
                                <div v-for="item in groupedSelectedItems.countries" :key="item.value" class="inline-block border bg-blue text-white px-2 py-1 mt-1 mx-2 text-sm rounded">
                                    <div style="float:left">{{item.name}}</div>
                                    <div @click="removeItem(item)" class="pl-4 cursor-pointer" style="float:left">x</div>
                                </div>
                            </li>
                        </transition>
                        <transition name="fade">
                            <li v-if="groupedSelectedItems.states.length > 0">
                                <div class="block text-gray mx-2">States & Provinces</div>
                                <div v-for="item in groupedSelectedItems.states" :key="item.value" class="inline-block border bg-blue text-white px-2 py-1 mt-1 mx-2 text-sm rounded">
                                    <div style="float:left">{{item.name}}</div>
                                    <div @click="removeItem(item)" class="pl-4 cursor-pointer" style="float:left">x</div>
                                </div>
                            </li>
                        </transition>
                        <transition name="fade">
                            <li v-if="groupedSelectedItems.metros.length > 0">
                                <div class="block text-gray mx-2">Metro Areas</div>
                                <div v-for="item in groupedSelectedItems.metros" :key="item.value" class="inline-block border bg-blue text-white px-2 py-1 mt-1 mx-2 text-sm rounded">
                                    <div style="float:left">{{item.name}}</div>
                                    <div @click="removeItem(item)" class="pl-4 cursor-pointer" style="float:left">x</div>
                                </div>
                            </li>
                        </transition>
                    </ul>
                </div>
            </transition>

            <transition name="fade">
                <div v-if="selectedItems.length > 0">
                    <p>This signal will return results for all domains that match other filters and have an HQ location in any of the selected locations.</p>
                </div>
            </transition>
        </div>
        <AlertBox v-model:message="geographyApiAlertMessage"></AlertBox>
    </div>
</template>

<script>
    import {
        Alert,
        Btn,
        Card,
        Icon,
        LoadingSpinner,
        TextField
    } from "@bombora/component-library"
    import PageHeader from "@/components/shared/PageHeader.vue";
    import AlertBox from "@/components/shared/AlertBox.vue";
    import { useSignalsStore } from '@/stores/signalStore'
    import {mapActions, mapState, mapWritableState} from "pinia";
    import { useReferenceDataStore} from "@/stores/referenceDataStore";

    export default {
        name: "Geographies",
        components: {
            Alert,
            Btn,
            Card,
            Icon,
            LoadingSpinner,
            TextField,
            PageHeader,
            AlertBox
        },
        data() {
            return {
                searchValue: null,
                selectedItems: [],
                geographyApiAlertMessage: null
            }
        },
        computed: {
            ...mapState(useSignalsStore, ['selectedSignal']),
            ...mapState(useReferenceDataStore, ['regions', 'geographies']),
            ...mapWritableState(useSignalsStore, ['selectedSignal', 'wizardCanNavigate']),
            ...mapWritableState(useReferenceDataStore, ['geographies']),


            searchList() {
                if (!this.searchValue) return [];
                // remove null names
                this.geographies = this.geographies.filter(x => x.name !== null)
                var filtered = this.geographies.filter(x => x.name.toLowerCase().includes(this.searchValue.toLowerCase()) || x.value.toLowerCase().includes(this.searchValue.toLowerCase()))
                return filtered
            },
            groupedSelectedItems() {
                let grouped = {
                    countries: this.selectedItems.filter(x => x.type == 'Country'),
                    states: this.selectedItems.filter(x => x.type == 'State'),
                    metros: this.selectedItems.filter(x => x.type == 'Metro')
                }
                return grouped
            },
        },
        async mounted() {
            this.wizardCanNavigate = true

            let response = await this.fetchRegions();
            if (!response.success) {
                this.geographyApiAlertMessage = 'Could not retrieve geographies. Please contact support if this issue persists.';
                console.error(response.response)
            }
            let geoRes = await this.fetchGeographies();

            if (this.selectedSignal.geography!== null) {

                if (!geoRes.success) {
                    this.geographyApiAlertMessage = 'Could not retrieve geographies. Please contact support if this issue persists.';
                    console.error(geoRes.response)
                }

                // Set current signal items
                var selected = Object.keys(this.selectedSignal.geography).map(k  => this.selectedSignal.geography[k])
                this.selectedItems = selected.sort((a, b) => a.value > b.value)
            }
        },
        methods: {
          ...mapActions(useSignalsStore, ['upsertSignal']),
            ...mapActions(useReferenceDataStore, ['fetchRegions', 'fetchGeographies']),

            selectRegion(countries) {
                for (var i = 0; i < countries.length; i++) {
                    let country = countries[i]
                    let existing = this.selectedItems.filter(x => x.value == country)
                    // Only add items that are not in the list already
                    if (existing.length == 0) {
                        this.selectedItems.push({
                            name: country,
                            value: country,
                            type: 'Country'
                        });
                    }
                }
                this.setItems()
            },
            async setItems() {
                this.selectedItems.sort((a, b) => a.value > b.value)
                this.selectedSignal.geography = this.selectedItems
                let status = await this.upsertSignal(this.selectedSignal);
                },
            selectSearchItem(item) {
                let existing = this.selectedItems.filter(x => x.value == item)
                // Only add items that are not in the list already
                if (existing.length == 0) {
                    this.selectedItems.push(item);
                }
                this.setItems()
            },
            removeItem(item) {
                const index = this.selectedItems.indexOf(item)
                this.selectedItems.splice(index, 1)
                this.setItems()
            },
            closeSearch() {
                this.searchValue = null;
            },
            clearSelection() {
                this.selectedItems = []
                this.setItems()
            },
        },
    }
</script>

<style scoped>
    .quick-select:not(:last-child):after {
        content: ','
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }
</style>
