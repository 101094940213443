import axios from "axios"
import { authPlugin } from "../services/auth"

export const apiClient = () => {
  if (authPlugin?.getTokenSilently == null) {
    console.error(
      "Auth0 has not been set up yet. You cannot initialize the API without first setting up Auth0"
    )
  }
  // Token is initialized in each method to prevent capturing of the token variable.
  return {
    get: async ({ url, options }) => {
      try {
        const token = await authPlugin.getTokenSilently()
        return await axios.get(url, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          ...options,
        }).catch(function (error) {
          return parseErrorResponse(error)
        })
      } catch (error) {
        return parseErrorResponse(error)
      }
    },
    postForm: async ({ url, data }) => {
      try {
        const token = await authPlugin.getTokenSilently()
        return await axios.post(url, data, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data"
          }
        }).catch(function (error) {
          return parseErrorResponse(error)
        })
      } catch (error) {
        return parseErrorResponse(error)
      }
    },
    post: async ({ url, data, options }) => {
      try {
        const token = await authPlugin.getTokenSilently()
        return await axios.post(url, data, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          ...options,
        }).catch(function (error) {
          return parseErrorResponse(error)
        })
      } catch (error) {
        return parseErrorResponse(error)
      }
    },

    put: async ({ url, data, options }) => {
      try {
        const token = await authPlugin.getTokenSilently()
        return axios.put(url, data, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          ...options,
        }).catch(function (error) {
          return parseErrorResponse(error)
        })
      } catch (error) {
        return parseErrorResponse(error)
      }
    },

    patch: async ({ url, data, options }) => {
      try {
        const token = await authPlugin.getTokenSilently()
        return axios.patch(url, data, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          ...options,
        }).catch(function (error) {
          return parseErrorResponse(error)
        })
      } catch (error) {
        return parseErrorResponse(error)
      }
    },

    delete: async ({ url, options }) => {
      try {
        const token = await authPlugin.getTokenSilently()
        return axios.delete(url, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          ...options,
        }).catch(function (error) {
          return parseErrorResponse(error)
        })
      } catch (error) {
        return parseErrorResponse(error)
      }
    }
  }
}

function parseErrorResponse(error) {
  if (!error.response) {
    throw error
  }

  return {
    data: error.response.data,
    status: error.response.status,
    message: error.response.message
  }
}
