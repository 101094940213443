<template>
    <div class="dialog-wrapper flex flex-col items-center justify-center fixed top-0 left-0 w-full h-full"
         tabindex="0"
         aria-describedby="modalContent"
         :data-scrolllock-activated="scrollLockActivated">
        <div class="dialog-overlay bg-black w-full h-full opacity-25 fixed top-0 left-0" />
        <div class="dialog overflow-auto m-10 bg-white relative p-0 box-border shadow-md rounded w-128"
             role="dialog">
            <div class="p-6 flex-1 relative overflow-auto">
                <div class="modalContent">
                    <div class="text-center mb-8">
                        <icon :iconType="icon" class="w-16" iconColor="#616161"></icon>
                        <h3 class="m-4 text-xl">{{title}}</h3>
                        <hr class="w-full text-gray-lighter mb-4" />
                        <p>{{message}}</p>
                    </div>
                    <div class="flex flex-col">
                        <btn class="mb-3" @click="handleConfirmClick">{{acceptText}}</btn>
                        <btn color="blue-light-line" @click="handleCloseClick">Cancel</btn>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Icon, Btn } from "@bombora/component-library";

    export default {
        name: "SubmitDialog",
        components: { Btn, Icon },
        emits: ["close", "visible", "confirm"],
        props: {
            message: {
                type: String
            },
            title: {
                type: String
            },
            icon: {
                type: String,
                default: 'question'
            },
            acceptText: {
                type: String,
                default: 'Accept'
            },
        },
        data() {
            return {
                bodyCssHeight: null,
                bodyCssOverflowY: null,
                bodyCssMarginRight: null,
                // Always use scrollock for dialogs
                scrollLockActivated: true,
            };
        },
        methods: {
            handleCloseClick() {
                this.$emit("close");
            },
            handleConfirmClick() {
                this.$emit("confirm");
            },
            handleCloseKeyup(event) {
                event.stopPropagation();
                if (event.key === "Escape") {
                    this.$emit("close");
                }
            },
            lockScrollbar() {
                this.bodyCssHeight = document.body.style.height;
                this.bodyCssOverflowY = document.body.style.overflowY;
                this.bodyCssMarginRight = document.body.style.marginRight;

                let scrollbarWidth =
                    window.innerWidth - document.body.clientWidth;

                document.body.style.height = "100vh";
                document.body.style.overflowY = "hidden";
                if (scrollbarWidth > 0) {
                    document.body.style.marginRight = `${scrollbarWidth}px`;
                }
                this.scrollLockActivated = true;
            },
            unlockScrollbar() {
                document.body.style.height = this.bodyCssHeight;
                document.body.style.overflowY = this.bodyCssOverflowY;
                document.body.style.marginRight = this.bodyCssMarginRight;
                this.scrollLockActivated = false;
            },
        },
        mounted() {
            this.lockScrollbar();
            window.addEventListener("keyup", this.handleCloseKeyup);
            this.$emit("visible", true);
        },
        unmounted() {
            window.removeEventListener("keyup", this.handleCloseKeyup);
            this.$emit("visible", false);
            this.unlockScrollbar();
        },
    };
</script>

<style lang="css" scoped>
    .dialog-wrapper {
        --z-index: 1000;
        z-index: var(--z-index);
    }

    .dialog-overlay {
        z-index: calc(var(--z-index) + 1);
    }

    .dialog {
        z-index: calc(var(--z-index) + 2);
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.25s ease;
    }
</style>
