<template>
  <div class="mb-24">
    <div class="pb-5 mb-10 border-b-2 border-gray-light">
      <div class="flex text-blue-dark text-sm font-semibold mb-6 inline-block cursor-pointer pt-8"
           @click="backToSignalReview">
        <img src="@/assets/arrow-left-quaternary.svg" alt="Back" />
        Back to Signal Settings
      </div>

      <h1 class="pb-8 mb-8 text-4xl text-blue-gray-dark">Signal Output</h1>
      <column-group v-if="loaded">
        <div>
          <h3 class="text-2xl font-bold text-blue-gray-dark">
            {{selectedSignal.name}}
          </h3>
        </div>
        <div>
          <div class="float-right">
            <btn data-id="download-csv-action" color="blue-line" @click="downloadCsv" :disabled="outputFileDownloading">
              <spinner-icon v-if="outputFileDownloading" width="16" height="16" class="mr-2" />Download CSV
            </btn>
          </div>
        </div>
      </column-group>
    </div>

    <div v-if="!loaded" class="bg-white bg-opacity-60 z-10 h-full w-full flex items-center justify-center">
      <div class="flex items-center">
        <loading-spinner />
      </div>
    </div>

    <div class="mb-8 text-l font-semibold text-blue-gray-dark" v-if="loaded">
      This is the first 50 results of your Signal. To see the full list, download the CSV.
    </div>
    <div v-if="loaded">
      <grid>
        <template #gridHeaderCells>
          <grid-header-cell column-key="domain" :sortable="false">
            Domain
          </grid-header-cell>
          <grid-header-cell column-key="companyname" :sortable="false">
            Company Name
          </grid-header-cell>
          <grid-header-cell column-key="score" :sortable="false">
            Score
          </grid-header-cell>
          <grid-header-cell column-key="score-label" :sortable="false">
            Signal Strength
          </grid-header-cell>
          <grid-header-cell column-key="areasofinterest" :sortable="false">
            Areas of Interest
          </grid-header-cell>
          <grid-header-cell column-key="industry" :sortable="false">
            Industry
          </grid-header-cell>
          <grid-header-cell column-key="revenue" :sortable="false">
            Revenue
          </grid-header-cell>
          <grid-header-cell column-key="employeecount" :sortable="false">
            Employee Count
          </grid-header-cell>
          <grid-header-cell column-key="hqlocation" :sortable="false">
            HQ Location
          </grid-header-cell>
        </template>
        <template #rows>
          <template v-for="(item, index) in paginatedResults">
            <grid-row>
              <grid-cell>
                {{ item.domain }}
              </grid-cell>
              <grid-cell>
                {{ item.company.name }}
              </grid-cell>
              <grid-cell>
                {{ item.score }}
              </grid-cell>
              <grid-cell>
                {{ item.scoreLabel}}
              </grid-cell>
              <grid-cell>
                {{ item.areasOfInterest.join(", ") }}
              </grid-cell>
              <grid-cell>
                {{ item.company.industry }}
              </grid-cell>
              <grid-cell>
                {{ item.company.revenue }}
              </grid-cell>
              <grid-cell>
                {{ item.company.size }}
              </grid-cell>
              <grid-cell>
                {{ location(item) }}
              </grid-cell>
            </grid-row>
          </template>
        </template>
        <template #footer>
          <grid-pager v-if="numPages > 1"
                      :page-size="pageSize"
                      :total-pages="numPages"
                      :current-page="currentPage"
                      @paged="pagerClicked">
          </grid-pager>
        </template>
      </grid>
    </div>

    <transition name="fade">
      <message-dialog v-if="outputFileDownloading" @close="closeModal" acceptText="OK" title="Download In Progress" message="We are collecting your Signal Data. It will download shortly.">
      </message-dialog>
    </transition>
    <AlertBox v-model:message="signalAlertMessage"></AlertBox>

    <message-dialog v-if="unauthorized && loaded" @close="unauthorized = false" title="Unauthorized" :dismissable="false"
                    message="You are not authorized to view this signal." />
  </div>
</template>

<script>
  import {
    Card,
    ColumnGroup,
    Btn,
    Grid,
    GridHeaderCell,
    GridRow,
    GridCell,
    GridPager,
    Icon,
    LoadingSpinner,
    SpinnerIcon
  } from "@bombora/component-library";
  import MessageDialog from "@/components/shared/MessageDialog.vue";
  import AlertBox from "@/components/shared/AlertBox.vue";

  import { useSignalsStore } from '@/stores/signalStore'
  import { mapActions, mapState } from "pinia";
  import { useRootStore } from '@/stores/rootStore.js';

  export default {
    name: "SignalOutput",
    components: {
      AlertBox,
      Card,
      ColumnGroup,
      Btn,
      Grid,
      GridHeaderCell,
      GridRow,
      GridCell,
      GridPager,
      Icon,
      MessageDialog,
      LoadingSpinner,
      SpinnerIcon,
    },
    props: ['id', 'signalId'],
    data() {
      return {
        loaded: false,
        pageSize: 15,
        currentPage: 1,
        outputFileDownloading: false,
        signalAlertMessage: null,
        showDownloadSpinner: false,
      }
    },
    computed: {
      ...mapState(useSignalsStore, ['selectedSignal', 'signalData']),
      ...mapState(useRootStore, ['unauthorized']),
      numPages() {
        return Math.ceil(this.signalData.length / this.pageSize)
      },
      paginatedResults() {
        return this.signalData.slice(
          (this.currentPage - 1) * this.pageSize,
          (this.currentPage - 1) * this.pageSize + this.pageSize
        )
      },
    },
    async created() {
      this.loaded = false;
      if (!this.unauthorized) {
        if (this.selectedSignal == null) {
          let resp = await this.fetchSignal(this.signalId);
        }

        let resp = await this.fetchSignalResults(this.selectedSignal.id);
        if (!resp.success) {
          this.signalAlertMessage = 'Could not retrieve signal data. Please contact support if this issue persists.';
          console.debug(resp.response)
        }
      }
      this.loaded = true;
    },
    methods: {
      ...mapActions(useSignalsStore, ['fetchSignal', 'fetchSignalResults', 'downloadSignalData']),
      backToSignalReview() {
        this.$router.push({ name: 'SignalReview', params: { id: this.id, signalId: this.signalId } })
      },
      pagerClicked(pageNum) {
        this.currentPage = pageNum
      },
      async downloadCsv() {
        this.outputFileDownloading = true;
        let resp = await this.downloadSignalData(this.selectedSignal.id);
        if (!resp.success) {
          // TODO UI ERROR
        }
        this.showDownloadSpinner = false;
        this.outputFileDownloading = false;
      },
      location(item) {
        let city = item.company.hq?.city || "";
        let state = item.company.hq?.state || "";
        let country = item.company.hq?.country || "";

        let locationString = [city, state, country].filter(Boolean).join(", ");
        return locationString
      },
      closeModal() {
        this.outputFileDownloading = false;
      },
    }
  }
</script>
