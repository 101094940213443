<template>
  <div class="mb-8">
    <page-header title="What is the purpose of this Signal?" class="mb-8">
      <template v-slot>
        Choose what type of campaigns or funnel position that you are building this Signal for.
        This will not affect the way the Signal is built, but is for others to understand the purpose of this unique Signal.
      </template>
    </page-header>
    <div class="main flex flex-row space-between">
      <div data-id="goals-signal-intent-sales-string"
           :class="[selectedClass('sales'), 'flex-1 px-8 py-5 rounded-sm border-gray-light border mb-3 cursor-pointer hover:bg-blue hover:text-white']"
           @click="setIntent('sales')">
        <div class="text-center">
          <div class="font-semibold text-xl">Sales</div>
        </div>
        <!--<div class="right m-auto mr-0">
            <div class="icon">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                </svg>
            </div>
        </div>-->
      </div>
      <div data-id="goals-signal-intent-marketing-string"
           :class="[selectedClass('marketing'), 'flex-1 px-8 py-5 mx-6 rounded-sm border-gray-light border mb-3 cursor-pointer hover:bg-blue hover:text-white']"
           @click="setIntent('marketing')">
        <div class="text-center">
          <div class="font-semibold text-xl">Marketing</div>
        </div>
      </div>
      <div data-id="goals-signal-intent-both-string"
           :class="[selectedClass('both'), 'flex-1 px-8 py-5 rounded-sm border-gray-light border mb-3 cursor-pointer hover:bg-blue hover:text-white']"
           @click="setIntent('both')">
        <div class="text-center">
          <div class="font-semibold text-xl">Both</div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import {
    Alert,
    Breadcrumbs,
    Btn,
    Card,
    Grid,
    GridRow,
    GridCell,
    GridHeaderCell,
    GridPager,
    Icon,
    LoadingSpinner,
  } from "@bombora/component-library"
  import PageHeader from "@/components/shared/PageHeader.vue";

  import { mapWritableState , mapActions } from 'pinia'
  import { useSignalsStore } from '@/stores/signalStore'

  export default {
    name: "DefinitionGoals",
    components: {
      Alert,
      Breadcrumbs,
      Btn,
      Card,
      Grid,
      GridRow,
      GridCell,
      GridHeaderCell,
      GridPager,
      Icon,
      LoadingSpinner,
      PageHeader
    },
    computed: {
      ...mapWritableState(useSignalsStore, ['selectedSignal', 'wizardCanNavigate']),
    },
    async mounted() {
      if (!this.selectedSignal.intent) {
        this.selectedSignal.intent = 'both'
      }
      this.wizardCanNavigate = true;
    },
    watch: {
      // The signal on first load does not return in time for this pages mounted event to fire.
      // As a result, the mounted event fires and sets both, then the signal finishes loading and resets the value to null,
      // breaking the default value set. We need a watcher here to fix this race condition.
      'selectedSignal.intent'(newValue) {
        if (!newValue) {
          this.selectedSignal.intent = 'both'
        }
        this.wizardCanNavigate = true;
      }
    },
    methods: {
      ...mapActions(useSignalsStore, ['upsertSignal']),
      async setIntent(intent) {
        this.selectedSignal.intent = intent
        await this.saveSignal();
      },
      async saveSignal() {
        let status = await this.upsertSignal(this.selectedSignal);
        if (!status.success) {
          //TODO UI ERROR
        }
        document.activeElement.blur()
      },
      selectedClass(intent) {
        return this.selectedSignal.intent === intent ? 'bg-blue text-white' : 'bg-blue-light text-blue-gray-dark'
      }
    },
  }
</script>

<style lang="css" scoped>
</style>
