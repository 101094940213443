<template>
    <div v-for="item in itemList"
         class="flex px-8 py-5 rounded-sm border-gray-light border mb-6 cursor-pointer hover:bg-blue hover:text-white"
         :class="this.selectedItems.includes(item) ? 'bg-blue text-white' : 'bg-blue-gray bg-opacity-50'"
         @click="toggleItems(item)">
        <div class="flex-col">
            <div class="font-semibold">
                {{getItemName(item)}}
            </div>
            <div>
                {{getItemRange(item)}}
            </div>
        </div>
        <div class="ml-auto self-center">
            <input type="checkbox" :value="item" v-model="selectedItems" />
        </div>
    </div>
</template>

<script>
export default {
    name: "MultipleCardSelector.vue",

    props: ['itemList', 'signalList'],
    emits: ['signal-updated', 'set-navigation-state'],
    data() {
        return {
            selectedItems: [],
        }
    },

    async mounted() {
        if (this.signalList === null) {
            this.selectedItems = []
        }
        else {
            this.selectedItems = this.signalList.slice(0)
        }
    },

    methods: {
        toggleItems(item) {
            if (this.selectedItems.includes(item)) {
                let index = this.selectedItems.indexOf(item)
                this.selectedItems.splice(index, 1)
            } else {
                this.selectedItems.push(item)
            }
            this.$emit('set-signal',this.selectedItems)
            this.$emit('signal-updated');
        }
            ,
        getItemName(item) {
            return item.split(" ")[0]
        },
        getItemRange(item) {
            return item.slice(item.indexOf(" "))
        }
    },
}

</script>

<style scoped>

</style>
