<template>
    <div class="mb-24">
        <div class="pb-5 mb-16 border-b-2 border-gray-light">
            <div class="flex text-blue-dark text-sm font-semibold mb-6 inline-block cursor-pointer pt-8"
                 @click="backToSignalDashboard">
                <img src="@/assets/arrow-left-quaternary.svg" alt="Back" />
                Back to All Signals
            </div>
            <h1 class="pb-8 mb-8 text-4xl text-blue-gray-dark">Signal Settings</h1>
            <column-group v-if="loaded">
                <div>
                    <h3 class="text-2xl font-bold text-blue-gray-dark">
                        {{selectedSignal.name}}
                    </h3>
                    <p>Generated: {{formatDate(selectedSignal.lastUpdated)}}</p>
                </div>
                <div>
                    <div class="float-right">
                        <btn data-id="view-signal-output-action" class="mr-4 rounded" @click="navigateTo('SignalOutput')">View Signal Output</btn>
                        <btn data-id="download-signal-file-action" color="orange" @click="downloadSignalFile()" :disabled="downloadLoading">
                            <spinner-icon v-if="downloadLoading" width="16" height="16" class="mr-2" />Download Signal Output CSV
                        </btn>
                    </div>
                </div>
            </column-group>
        </div>
        <transition name="fade">
            <message-dialog v-if="downloadLoading" @close="closeModal" acceptText="OK" title="Download In Progress" message="We are collecting your Signal Data. It will download shortly.">
            </message-dialog>
        </transition>
        <card :separate-header="true" class="mb-20" v-if="loaded">
            <template #header>
                <div>
                    <column-group>
                        <h2 class="text-xl font-bold text-blue-gray-dark">
                            Signal Definition
                        </h2>
                        <div>
                            <div class="float-right">
                                <btn data-id="goals-navigate" color="blue-line" size="small" @click="editSignal(wizardRouteNames.Goals)">
                                    Edit
                                </btn>
                            </div>
                        </div>
                    </column-group>
                </div>
            </template>

            <field-grid-row>
                <template #leftContent>
                    Signal Name
                </template>
                <template #rightContent>
                    {{selectedSignal.name}}
                </template>
            </field-grid-row>

            <field-grid-row>
                <template #leftContent>
                    Signal Goal
                </template>
                <template #rightContent>
                    {{selectedSignal.intent}}
                </template>
            </field-grid-row>

            <field-grid-row>
                <template #leftContent>
                    Product Description
                </template>
                <template #rightContent>
                    {{selectedSignal.description}}
                </template>
            </field-grid-row>

            <field-grid-row>
                <template #leftContent>
                    Product Benefits
                </template>
                <template #rightContent>
                    {{selectedSignal.benefits}}
                </template>
            </field-grid-row>

            <field-grid-row>
                <template #leftContent>
                    Competitors
                </template>
                <template #rightContent>
                    {{selectedSignal.competitors}}
                </template>
            </field-grid-row>

            <field-grid-row>
                <template #leftContent>
                    Relevant URLs
                </template>
                <template #rightContent>
                    <div v-for="url in selectedSignal.urls" :key="url.id" class="custom-margin">
                        <p>{{url.domain}}</p>
                        <p class="text-gray">{{url.type}}</p>
                    </div>
                </template>
            </field-grid-row>

            <field-grid-row>
                <template #leftContent>
                    SEM Keywords
                </template>
                <template #rightContent>
                    {{selectedSignal.keywordsText?.join(', ') ?? ''}}
                </template>
            </field-grid-row>

            <field-grid-row>
                <template #leftContent>
                    Relevant PDFs
                </template>
                <template #rightContent>
                    <div v-for="pdf in selectedSignal.pdfs" :key="pdf.id">
                        <span class="text-sm font-sans font-semibold inline-block float-left rounded-full py-2 px-3 mr-1 text-blue-darkest bg-gray-lightest">
                            {{pdf.storageLocation.split('/').pop()}}
                        </span>
                    </div>
                </template>
            </field-grid-row>

            <field-grid-row>
                <template #leftContent>
                    SEM Keyword CSVs
                </template>
                <template #rightContent>
                    <div v-for="keywords in selectedSignal.keywordFiles" :key="keywords.id">
                        <span class="text-sm font-sans font-semibold inline-block float-left rounded-full py-2 px-3 mr-1 text-blue-darkest bg-gray-lightest">
                            {{keywords}}
                        </span>
                    </div>
                </template>
            </field-grid-row>
        </card>

        <card :separate-header="true" class="mb-20" v-if="loaded && topics != null && topics.length > 0">
            <template #header>
                <div>
                    <column-group>
                        <h2 class="text-xl font-bold text-blue-gray-dark">
                            Selected Topics Used
                        </h2>
                    </column-group>
                </div>
            </template>

            <grid class="pb-12">
                <template #gridHeaderCells>
                    <grid-header-cell column-key="name" :sortable="false">
                        Name
                    </grid-header-cell>
                    <grid-header-cell column-key="rank" :sortable="false">
                        Rank
                    </grid-header-cell>
                </template>
                <template #rows>
                    <template v-for="(topic, index) in paginatedTopics">
                        <grid-row>
                            <grid-cell>
                                <span class="pl-6 text-blue">{{ topic.name }}</span>
                            </grid-cell>
                            <grid-cell>
                                <span class="text-blue-darkest">
                                    {{++index + this.topicPageSize * (this.currentTopicPage - 1)}}
                                </span>
                            </grid-cell>
                        </grid-row>
                    </template>
                </template>
                <template #footer>
                    <grid-pager v-if="numTopicPages > 1"
                                :page-size="topicPageSize"
                                :total-pages="numTopicPages"
                                :current-page="currentTopicPage"
                                @paged="topicPagerClicked">
                    </grid-pager>
                </template>
            </grid>

        </card>

        <card :separate-header="true" class="mb-20" v-if="loaded">
            <template #header>
                <div>
                    <column-group>
                        <h2 class="text-xl font-bold text-blue-gray-dark">
                            Segmentation Data
                        </h2>
                        <div>
                            <div class="float-right">
                                <btn data-id="account-list-navigate" color="blue-line" size="small" @click="editSignal(wizardRouteNames.Filtering)">
                                    Edit
                                </btn>
                            </div>
                        </div>
                    </column-group>
                </div>
            </template>


            <field-grid-row>
                <template #leftContent>
                    Account List
                </template>
                <template #rightContent>
                    {{this.accountListNames.split(".")[0]}}
                </template>
            </field-grid-row>

            <field-grid-row>
                <template #leftContent>
                    Audience Size
                </template>
                <template #rightContent>
                    {{ selectedSignal.audienceSizeList?.join(', ') ?? ''}}
                </template>
            </field-grid-row>

            <field-grid-row>
                <template #leftContent>
                    Profitability
                </template>
                <template #rightContent>
                    {{ selectedSignal.profitability?.join(', ') ?? ''}}
                </template>
            </field-grid-row>

            <field-grid-row>
                <template #leftContent>
                    Target Industries
                </template>
                <template #rightContent>
                    {{ selectedSignal.industry?.join(', ') ?? ''}}
                </template>
            </field-grid-row>

            <field-grid-row>
                <template #leftContent>
                    Geographic Focus
                </template>
                <template #rightContent>
                    {{ this.geographyList }}
                </template>
            </field-grid-row>
        </card>


        <card :separate-header="true" v-if="loaded">
            <template #header>
                <div>
                    <column-group>
                        <h2 class="text-xl font-bold text-blue-gray-dark">
                            Signal Delivery
                        </h2>
                        <div>
                            <div class="float-right">
                                <btn data-id="topics-navigate" color="blue-line" size="small" @click="editSignal(wizardRouteNames.Delivery)">
                                    Edit
                                </btn>
                            </div>
                        </div>
                    </column-group>
                </div>
            </template>


            <!--            <field-grid-row>-->
            <!--                <template #leftContent>-->
            <!--                    Integrations Activated-->
            <!--                </template>-->
            <!--                <template #rightContent>-->
            <!--                    Coming Soon-->
            <!--                </template>-->
            <!--            </field-grid-row>-->

            <field-grid-row>
                <template #leftContent>
                    Scheduled Delivery
                </template>
                <template #rightContent>
                    {{ selectedSignal.deliveryEmails?.join(", ") ?? ''}}
                </template>
            </field-grid-row>
        </card>
        <div v-if="!loaded" class="text-center">
            <div class="inline-block">
                <loading-spinner />
            </div>
        </div>

        <message-dialog v-if="unauthorized && loaded" @close="unauthorized = false" title="Unauthorized" :dismissable="false"
                        message="You are not authorized to view this signal." />
        <AlertBox v-model:message="downloadFailAlertMessage"></AlertBox>
        <AlertBox v-model:message="unauthorizedAlertMessage"></AlertBox>


    </div>
    <confirm-dialog v-if="editing" @close="closeSubmissionDialog" @confirm="confirmEdit" title="Confirm Edit Signal"
                    message="This Signal is active. Entering edit mode will move the Signal to a pending state. You may need to re-select topics, and the signal will need to be re-submitted." />
</template>

<script>
    import {
        Badge,
        Btn,
        Card,
        ColumnGroup,
        Grid,
        GridHeaderCell,
        GridRow,
        GridCell,
        GridPager,
        Icon,
        LoadingSpinner,
        SpinnerIcon,
    } from "@bombora/component-library";

    import ConfirmDialog from "@/components/shared/ConfirmDialog";
    import FieldGridRow from "@/components/shared/FieldGridRow";
    import MessageDialog from "@/components/shared/MessageDialog.vue"
    import AlertBox from "@/components/shared/AlertBox.vue";
    import { useRootStore } from '@/stores/rootStore.js';
    import { useSignalsStore } from '@/stores/signalStore.js';
    import { mapActions, mapState } from "pinia";
    import { formatStringDate } from '@/helpers';
    import { wizardRouteNames } from '@/router/routerConstants';
    import { NavStateEnum, ErrorMessages } from "@/constants";

    export default {
        name: "SignalReview",
        components: {
            Badge,
            Btn,
            Card,
            ColumnGroup,
            ConfirmDialog,
            FieldGridRow,
            Grid,
            GridHeaderCell,
            GridRow,
            GridCell,
            GridPager,
            Icon,
            MessageDialog,
            LoadingSpinner,
            AlertBox,
            SpinnerIcon
        },
        props: ['id', 'signalId'],
        data() {
            return {
                loaded: false,
                submissions: [],
                wizardRouteNames: wizardRouteNames,
                downloadLoading: false,
                editing: false,
                route: "",
                downloadFailAlertMessage: null,
                unauthorizedAlertMessage: null,
                topicPageSize: 10,
                currentTopicPage: 1,
            }
        },
        computed: {
            ...mapState(useSignalsStore, ['selectedSignal', 'topics']),
            ...mapState(useRootStore, ['unauthorized', 'accountLists', 'company', 'entityId']),
            geographyList() {
                var geographyList = []
                for (var geography in this.selectedSignal.geography) {
                    geographyList.push((this.selectedSignal.geography[geography].name))
                }
                return geographyList.join(", ")
            },
            accountListNames() {
                //get account list name based on account list id in signal
                var accountListNames = []
                var filteredAccountLists = this.accountLists.filter(l => this.selectedSignal.accountList.includes(l.id))
                for (var list in filteredAccountLists) {
                    accountListNames.push(filteredAccountLists[list].name)
                }
                return accountListNames.join(", ")

            },
            numTopicPages() {
                if (this.topics !== undefined && this.topics !== null) {
                    return Math.ceil(this.topics.length / this.topicPageSize)
                }
            },
            paginatedTopics() {
                if (this.topics !== undefined && this.topics !== null) {
                    return this.topics.slice(
                        (this.currentTopicPage - 1) * this.topicPageSize,
                        (this.currentTopicPage - 1) * this.topicPageSize + this.topicPageSize
                    )
                }
            },
        },
        async created() {
            if (!this.unauthorized) {
                await this.fetchAccountLists(this.entityId);

                if (this.selectedSignal == null) {
                    let status = await this.fetchSignal(this.signalId);
                    if (!status.success) {
                        // TODO UI ERROR
                    }
                }

                let resp = await this.fetchSignalDefResponse(this.signalId);
                if (!resp.success) {
                    // TODO UI ERROR
                }
            } else {
                // TODO UI ERROR
            }
            this.loaded = true;
        },
        methods: {
            ...mapActions(useSignalsStore, ['fetchSignal', 'downloadSignalData', 'upsertSignal', 'fetchSignalDefResponse']),
            ...mapActions(useRootStore, ['fetchAccountLists']),
            editSignal(route) {
                this.editing = true;
                this.route = route;
            },
            topicPagerClicked(pageNum) {
                this.currentTopicPage = pageNum
            },
            navigateTo(route) {
                this.$router.push({ name: route, params: this.routeParams })
            },
            backToSignalDashboard() {
                this.$router.push({ name: 'Signals', params: { id: this.id } })
            },
            async downloadSignalFile() {
                this.downloadLoading = true;
                let resp = await this.downloadSignalData(this.selectedSignal.id);
                if (resp.success) {
                    this.downloadLoading = false;
                } else {
                    console.error(resp.response);
                    this.downloadLoading = false;
                }
            },
            formatDate(date) {
                return formatStringDate(date);
            },
            // This method is used only to stop the user from leaving Definition before they confirm they want to
            async confirmEdit() {
                this.editing = false;

                // These route values map specifically to the route values set by editSignal
                switch (this.route) {
                    case wizardRouteNames.Goals:
                        this.selectedSignal.navigationState = NavStateEnum.Definition
                        break;
                    case wizardRouteNames.Filtering:
                        this.selectedSignal.navigationState = NavStateEnum.Segmentation
                        break;
                    case wizardRouteNames.Delivery:
                        this.selectedSignal.navigationState = NavStateEnum.Review
                        break;
                }

                let resp = await this.upsertSignal(this.selectedSignal);
                if (!resp.success) {
                    if (resp.message == ErrorMessages.unauthorizedAction) {
                        this.unauthorizedAlertMessage = ErrorMessages.unauthorizedAction;
                    } else {
                        console.log(resp.response)
                    }
                }

                // Navigate to the next section
                this.$router.push({ name: this.route, params: this.routeParams })
            },
            closeSubmissionDialog() {
                this.editing = false;
            },
            closeModal() {
                this.downloadLoading = false;
            },

        }
    }
</script>


<style lang="css" scoped>
    div.custom-margin:not(:last-child) {
        @apply mb-5;
    }
</style>
