<template>
    <AppHeader/>
    <main class="max-w-screen-xl container mx-auto">
        <slot></slot>
    </main>
</template>

<script>
    import AppHeader from "@/components/layout/AppHeader";

    export default {
        name: "Layout",
        components: {
            AppHeader
        },
    }
</script>

<style scoped>
</style>
