<template>
  <li :class="[cursorClass, 'my-8 flex items-center']">
    <button @click="triggerClick">
      <span :class="[computedClass, 'rounded-full text-md p-2 font-semibold inline-block w-fit mr-1 absolute inline-block border border-solid']"></span>
      <span class="ml-11 -mt-1 inline-block text-left font-bold text-blue-gray-dark">{{title}}</span>
    </button>
  </li>
</template>

<script>
  export default {
    name: "WizardSideNavItem",
    components: {
    },
    emits: ['on-click'],
    props: {
      title: {
        type: String,
        default: ''
      },
      isComplete: {
        type: Boolean,
        default: false
      },
      isActive: {
        type: Boolean,
        default: false
      },
    },
    computed: {
      computedClass() {
        if (this.isComplete) return 'text-white bg-blue-darker border-blue-darker';
        if (this.isActive) return 'text-white bg-green border-green-dark';
        else return 'border-gray-light';
      },
      cursorClass() {
        return this.isComplete || this.isActive ? 'cursor-pointer' : '';
      }
    },
    methods: {
      triggerClick() {
        this.$emit('on-click')
      }
    }
  }
</script>

<style scoped>
</style>
