<template>
    <div>
        <div v-if="uploading" class="text-center">
            <div class="inline-block">
                <loading-spinner />
            </div>
        </div>
        <template v-else>
            <page-header title="Upload relevant assets that represent this Signal" class="mb-8">
                <template v-slot>
                    You can enter a URL or upload a PDF that represents your go-to-market target strategy and our Topic Classification Engine will return Intent topics that represent that content.
                    Example PDFs might include: ebooks written for this target audience, product flyers, etc.
                    Example URLs might include: product webpages or landing pages for campaigns for this target audience.
                </template>
            </page-header>

            <titled-section class="pb-10">
                <template v-slot:title>
                    Upload PDF Documents
                </template>
                <template v-slot:subtitle>
                    Only .PDF files are accepted. Select all desired PDF documents to upload.
                </template>

                <file-drop-zone data-id="pdf-upload-action" @updated="onFileUploaded" :accept="'.pdf'">
                    <div class="w-full h-full grid place-content-center">
                        <div class="w-8 h-8 place-self-center">
                            <img height="27" width="24" src="@/assets/arrow-up.svg" alt="Upload" />
                        </div>
                        <p class="text-dark-gray">Click to upload or drag and drop your PDF files</p>
                    </div>
                </file-drop-zone>

                <div v-for="(pdf, idx) in pdfs" :key="pdf.file" class="pb-2 w-full">
                    <div class="flex items-center">
                        <div class="flex items-center w-full">
                            <text-field :name="pdf.name" v-model="pdf.file" :is-disabled="true" disabled class="mr-2 w-11/12 float-left" />
                            <div class="flex w-1/12 h-100 items-center">
                                <div class="w-4 h-4 ml-1 mr-6">
                                    <spinner-icon v-if="pdf.loaded === false" width="16" height="16" />
                                    <img v-else class="d-flex" src="@/assets/check.png" alt="check">
                                </div>
                                <div class="d-flex mb-1 cursor-pointer">
                                    <spinner-icon v-if="pdf.deleting" width="16" height="16" />
                                    <button v-if="!pdf.deleting" data-id="delete-pdf-action" @click="deletePdfFile(pdf)">
                                        <icon class="w-4 h-4" icon-type="trash" icon-color="#044B65" alt="Delete PDF"></icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </titled-section>

            <titled-section>
                <template v-slot:title>
                    Enter URLs
                </template>
                <template v-slot:subtitle>
                    Provide all relevant URLs below.
                </template>

                <div v-for="(url, idx) in selectedSignal.urls" :key="url.id" class="pb-2 w-full">
                    <div class="flex items-center">
                        <text-field placeholder="example.com"
                                    @blur="validatePage"
                                    class="mr-2 w-full"
                                    :name="`urls[${idx}].domain`"
                                    v-model="url.domain" />

                        <button data-id="delete-url-action" @click="deleteUrl(url.domain)" class="flex items-center justify-around w-4 h-4 cursor-pointer">
                            <icon icon-type="trash" icon-color="#044B65" alt="Delete URL"></icon>
                        </button>
                    </div>
                </div>

                <div class="pb-3">
                    <FieldErrorMessage :field="v$.selectedSignal.urls" collection-field-name="domain"></FieldErrorMessage>
                </div>

                <button data-id="add-url-action" @click="addUrl" class="flex w-24 items-center hover:underline cursor-pointer">
                    <div class="flex items-center justify-around bg-blue-darkest w-3 h-3 rounded-full text-center">
                        <icon icon-type="plus" icon-color="white" class="w-2 h-2" />
                    </div>
                    <div class="pl-2 pt-0.5 text-blue-darkest font-semibold">Add URL</div>
                </button>

            </titled-section>
            <alert-box v-model:message="pdfUploadErrorMessage"></alert-box>

        </template>
    </div>
</template>

<script>
    import { ColumnGroup, Dropdown, TextField, Btn, Icon, LoadingSpinner, SpinnerIcon } from "@bombora/component-library";
    import TitledSection from "@/components/shared/TitledSection.vue";
    import FileDropZone from '@/components/shared/FileDropZone.vue';
    import FieldErrorMessage from '@/components/shared/FieldErrorMessage.vue';
    import PageHeader from "@/components/shared/PageHeader.vue";

    import { mapWritableState, mapActions } from 'pinia'
    import { useSignalsStore } from '@/stores/signalStore'

    import { useVuelidate } from '@vuelidate/core'
    import { helpers } from '@vuelidate/validators'
    import { validDomainRegex, cleanDomain } from "@/utils/domain";
    import AlertBox from "@/components/shared/AlertBox.vue";

    export default {
        name: "UploadUrls",
        components: {
            AlertBox,
            ColumnGroup,
            Dropdown,
            FileDropZone,
            FieldErrorMessage,
            TextField,
            Btn,
            Icon,
            TitledSection,
            LoadingSpinner,
            SpinnerIcon,
            PageHeader
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                dropdownValue: '',
                parent: {},
                dropdownItems: [
                    { label: 'Product / Service', value: 'Product / Service' },
                    { label: 'Competitor Product / Service', value: 'Competitor Product / Service' },
                    { label: '3rd Party Publications', value: '3rd Party Publications' },
                    { label: 'Campaign Documents', value: 'Campaign Documents' },
                    { label: 'Site Homepage', value: 'Site Homepage' },
                ],
                files: [],
                // {file: '', loaded: t/f, deleting: t/f}
                pdfs: [],
                uploading: false,
                pdfUploadErrorMessage: null,
            }
        },
        computed: {
            ...mapWritableState(useSignalsStore, ['selectedSignal', 'wizardCanNavigate']),
        },
        validations() {
            return {
                selectedSignal: {
                    urls: {
                        $each: helpers.forEach({
                            domain: {
                                required: helpers.withMessage('You must enter a valid URL', value => {
                                    // clean and validate it
                                    let cleaned = cleanDomain(value)
                                    return validDomainRegex.test(cleaned);
                                })
                            }
                        }),
                    }
                }
            }
        },
        async created() {
            if (this.selectedSignal.pdfs) {
                this.selectedSignal.pdfs.forEach(f => {
                    this.pdfs.push({
                        file: f.storageLocation.split("/").pop(),
                        loading: true,
                        deleting: false
                    })
                })
            }
        },
        async mounted() {
            this.wizardCanNavigate = true;
        },
        methods: {
            ...mapActions(useSignalsStore, ['uploadPdfs', 'deletePdf', 'upsertSignal']),
            async validatePage() {
                this.wizardCanNavigate = await this.v$.$validate()
            },
            async saveSignal() {
                let status = await this.upsertSignal(this.selectedSignal);
            },
            async onFileUploaded(files) {
                this.wizardCanNavigate = false;
                this.files = files;
                let newFiles = []
                for (var i = 0; i < this.files.length; i++) {
                    // Find file in list by name
                    let currentFile = this.pdfs.filter(x => x.file === this.files[i].name)
                    if (currentFile.length === 0) {
                        // new file
                        this.pdfs.push({
                            file: this.files[i].name,
                            type: '',
                            loaded: false,
                            deleting: false
                        })
                        newFiles.push(this.files[i])
                    }
                }
                const formData = new FormData();
                //only upload new files that haven't already been uploaded
                newFiles.forEach(f => {
                    formData.append("pdfs", f, f.name);
                    formData.append("fileTypes", '');
                })

                let response = await this.uploadPdfs(this.selectedSignal.id, formData);
                if (response.success) {
                    this.pdfs.forEach(file => {
                        file.loaded = true
                    })
                } else if (response.response.status === 400) {
                    this.pdfUploadErrorMessage = 'Unable to upload file. Only .PDF files are accepted'
                    this.files.forEach(file => {
                        if (newFiles.includes(file)) {
                            let pdfIndex = this.pdfs.indexOf(file);
                            this.pdfs.splice(pdfIndex, 1)
                        }
                    })
                } else {
                    this.pdfUploadErrorMessage = 'Unable to upload file.'
                    this.files.forEach(file => {
                        if (newFiles.includes(file)) {
                            let pdfIndex = this.pdfs.indexOf(file);
                            this.pdfs.splice(pdfIndex, 1)
                        }
                    })
                }
                await this.saveSignal()
                this.wizardCanNavigate = true
            },
            async deletePdfFile(pdf) {
                this.wizardCanNavigate = false

                let pdfIndex = this.pdfs.indexOf(pdf)
                this.pdfs[pdfIndex].deleting = true

                let pdfName = pdf.file
                let pdfId = this.selectedSignal.pdfs.find(f => f.storageLocation.split("/").pop() === pdfName).id;
                let deleteResp = await this.deletePdf(this.selectedSignal.id, pdfId);
                if (deleteResp.success) {
                    this.selectedSignal.pdfs = this.selectedSignal.pdfs.filter(f => f.storageLocation.split("/").pop() !== pdfName);
                    this.pdfs.splice(pdfIndex, 1);
                } else {
                    this.pdfs[pdfIndex].deleting = false;
                }
                await this.saveSignal()
                this.wizardCanNavigate = true
            },
            async addUrl() {
                if (this.selectedSignal.urls == null) {
                    this.selectedSignal.urls = [];
                }
                // Generate a random string as ID in order to support the Vue Key property that keeps the URL list from rerendering on every action.
                this.selectedSignal.urls.push({ domain: '', id: Math.random().toString(36).substring(7) })
            },
            async deleteUrl(domain) {
                this.selectedSignal.urls = this.selectedSignal.urls.filter(u => u.domain !== domain)
            },
        }
    }
</script>

<style scoped>
</style>
